

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';




export default function MapLegend({}) {

    const [legendOpen,setLegendOpen] = useState(false)


    return(

        <div className='mapLegend'>
            {!legendOpen && <div className='mapLegendClosed'>
                <ChevronLeft className="discoverFilterArrow"onClick={()=>setLegendOpen(true)}/>
                <span>LEGEND</span>

            </div>}

            {legendOpen && <div className='mapLegendOpen'>
                <div className='mapLegendArrowDiv'><ChevronRight className="discoverFilterArrow"onClick={()=>setLegendOpen(false)}/></div>

                <div className='mapLegendColumn'>
                    <img className='mapLegendImg' src='/assets/accommodationMarker.png'/>
                    <PrimaryText variant='caption'>Accommodation</PrimaryText>
                </div>
                <div className='mapLegendColumn'>
                    <img className='mapLegendImg' src='/assets/mapMarkerDot.png'/>
                    <PrimaryText variant='caption'>Gym</PrimaryText>
                </div>                

                <div className='mapLegendColumn'>
                    <img className='mapLegendImg' src='/assets/customMapMarker2.png'/>
                    <PrimaryText variant='caption'>Gym (Online Passes)</PrimaryText>
                </div>

{/* 
                <div className='mapLegendColumn'>
                    <img src='/assets/mapMarkerDot.png'/>
                    <PrimaryText variant='caption'>Sports Facility</PrimaryText>
                </div> */}


                <div className='mapLegendColumn'>
                    <img className='mapLegendImg' src='/assets/mapMarkerSelected.png'/>
                    <PrimaryText variant='caption'>Selected Gym</PrimaryText>
                </div>




            </div>}
            
                
        </div>
    )
}