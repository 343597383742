import React, { useEffect, useState } from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import DeleteAccount from '../components/accountScreen/DeleteAccount';
import EditAccountDetails from '../components/accountScreen/EditAccountDetails';
import FavouriteGyms from '../components/accountScreen/FavouriteGyms';
import MyBookings from '../components/accountScreen/MyBookings';
import UpdateLoginDetails from '../components/accountScreen/UpdateLoginDetails';

export default function Account({li}) {

    console.log("LI LO",li)

    const navigate = useNavigate();

    

    const [showMainMenu, setShowMainMenu] = useState(true)
    
    const [showEditDetails, setShowEditDetails] = useState(false)
    const [showUpdateLogin, setShowUpdateLogin] = useState(false)
    const [showMyBookings, setShowMyBookings] = useState(false)
    const [showFavourites, setShowFavourites] = useState(false)
    const [showDelete, setShowDelete] = useState(false)



    const userVar = {
        email:"jsuggz@gmail.com",
        personalDetails:{
            firstName:"Jason",
            surname:"Druggans",
            dob:"09/09/2009",
            gender:"Male Man",
            country:"West Pacific"
        },
        bookings:['399f17d44ce4c','399f17d44ce4c']
    }

    const [user, setUser] = useState(userVar)

    const backClicked = () => {
        setShowMainMenu(true)
        setShowEditDetails(false)
        setShowUpdateLogin(false)
        setShowMyBookings(false)
        setShowFavourites(false)
        setShowDelete(false)
    }
    
    const editDetailsClicked = () => {
        setShowMainMenu(false)
        setShowEditDetails(true)
    }

    const updateLoginClicked = () => {
        setShowMainMenu(false)
        setShowUpdateLogin(true)
    }

    const myBookingsClicked = () => {
        setShowMainMenu(false)
        setShowMyBookings(true)
    }

    const favouritesClicked = () => {
        setShowMainMenu(false)
        setShowFavourites(true)
    }
    
    const deleteClicked = () => {
        setShowMainMenu(false)
        setShowDelete(true)
    }


    return(
        <div>
            <h1>Account</h1>
            <div>{li}</div>

            {showMainMenu && <div>
                <button onClick={editDetailsClicked}>Edit Details</button><br/>
                <button onClick={updateLoginClicked}>Update Login Details</button><br/>
                <button onClick={myBookingsClicked}>My Bookings</button><br/>
                <button onClick={favouritesClicked}>Favourite Gyms</button><br/>
                <button onClick={deleteClicked}>Delete Account</button><br/>    
            </div>}
            {!showMainMenu && <button onClick={backClicked}>Back</button>}

            {showEditDetails && <EditAccountDetails user={user}/>}
            {showUpdateLogin && <UpdateLoginDetails />}
            {showMyBookings && <MyBookings bookingsP={user.bookings}/>}
            {showFavourites && <FavouriteGyms/>}
            {showDelete && <DeleteAccount/>}
            
        </div>
    )
}