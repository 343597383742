import {useNavigate,useLocation} from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import '../../styling/constants.css'
import PaymentComponent from '../../components/PaymentComponent';
import {loadStripe} from '@stripe/stripe-js';
import { getAuth  } from "firebase/auth";
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { PurpleButton } from '../mui/Buttons';


export default function BookingPaymentDetails({booking,stepComplete,loadingStatus,cancelClicked,activatingPass}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    const stripePromise = loadStripe('pk_test_51L80HSF8EmGgjBYkIROg4pkgsJEs5LgznxCE0Iie3eEUMpvP1Yt9lz4t66DpLAKM4ByfxWELD8v0GfnzK32T6zI100FbrfjVdt');

    const [customerID,setCustomerID] = useState("cus_4589")
    const [paymentID,setPaymentID] = useState("pm_1234")
    const [payMethod,setPayMethod] = useState("visa")
    const [options, setOptions] = useState(null)
    const [setupIntentID, setIntentID] = useState("")
    const [useSavedCard,setUseSaveCard] = useState(false)
    const [nameText,setNameText] = useState('')
    const [checkingPayment,setCheckingPayment] = useState(false)
    const [loadingText,setLoadingText] = useState(null)

    const navigate = useNavigate();


    const checkboxClicked = () =>{
        setUseSaveCard(!useSavedCard)
    }

    const appearance = {
        // labels: 'floating',
        variables:{
            // colorPrimary: '#E4D0A6',
            // colorBackground: '#242424',
            // colorTextPlaceholder: '#A0A0A0',
            // colorText: '#fff',
            // spacingUnit: '20px',
            // spacingGridRow: '5px',
            // spacingGridColumn: '5px',
            // borderRadius: '25px',
            // fontLineHeight:'15px',
            // fontSizeBase:'16px'
        }
        
      };


    useEffect(() => {
        getCustomerStripeID()
        
    },[])


    const submitClicked = () => {
        
        

        addDetailsToDB()
    }

    const getCustomerStripeID = async() => 
    {
        loadingStatus(true)
        const auth =  await getAuth();
        console.log("AUTH",auth)
        const uid = auth.currentUser.uid;

        return new Promise(async (resolve, reject) => {
            axios.get(baseURL+'getCustomersStripeID',{
            
                params:{
                    authID:uid,
                }

            }).then((response) => {
                let resVar = response.data
                
                let cusID = resVar.customerID
                setCustomerID(cusID)
                createPaymentIntent(cusID)
            })
        })
    }

    const createPaymentIntent = (cusID) => {


        axios.get(baseURL+'createPaymentIntent',{
            params:{
                customer: cusID,
                amount: +booking.bookingOptionDetails.price,
                // stripeID: booking.gym.stripeID
                gymID:booking.gym.id
            }
        }).then( (response) => {
        
          const data = response.data;
          
          let setUpID = data.paymentIntent.client_secret
          console.log("Setup ID",setUpID)

          loadingStatus(false)

          setOptions({clientSecret:setUpID,appearance:appearance})
            
          setIntentID("")
          setIntentID(setUpID)
          setPaymentID(data.paymentIntent.id)
        
        });

    }

    const stripeResponse = (res)=>
    {
        if(res !== "Success"){setCheckingPayment(false)
                setLoadingText("Checking card")
        }
        else{
            onSuccessfulPayment()
        }
    }


    const onSuccessfulPayment = async () => {

        addDetailsToDB()

    }


    const addDetailsToDB = () => {


        let hasFurtherRequirements = booking.gym.activationRequirements !== null && booking.gym.activationRequirements.length>0

        if(!hasFurtherRequirements){
            activatingPass(true)
        }

        axios.post(baseURL+'bookingPaid',{
        
            bookingID:booking.id,

            days:booking.bookingOptionDetails.days,
            optionName:booking.bookingOptionDetails.name,
            cost:booking.bookingOptionDetails.price,
            booking:booking,

            paymentMethod:payMethod,
            stripeCustomerID:customerID,
            stripePaymentID:paymentID,

            hasFurtherRequirements:hasFurtherRequirements
        
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
            activatingPass(false)

            if(resVar.furtherActionRequired)
            {
                stepComplete(booking)
            }
            else{
                booking.pass = {
                    pdfLink:resVar.walletFiles.pdf,
                    googleWalletLink:resVar.walletFiles.google,
                    appleWalletLink:resVar.walletFiles.apple
                }
                navigate("../activatedBooking/"+booking.id, {   state:{booking:booking}});
            }
            
        })
    }
    
    return(
        <div className='bookingPassOverview'>
            
            <div className="passOverviewHeaders">
                <PrimaryText variant="h3">PAYMENT DETAILS</PrimaryText>
                <SecondaryText variant="subtitle1">Please choose your preferred payment method</SecondaryText>
            </div>

            {/* <PurpleButton onClick={addDetailsToDB}> Test button</PurpleButton> */}

            <div className="bookingPayment flexColumn">
        
            
            {(setupIntentID) && 
                <Elements stripe={stripePromise} options={options} >
                    <PaymentComponent stripeResponse={stripeResponse} cancelClicked={cancelClicked} submitClicked={checkingPayment} isSetup={false} savedCard ={{using:useSavedCard,secret:options.clientSecret}}/>
                </Elements>
                }
            
            {/* <div className='flexRow marginTop50'>
                <input type="checkbox" className='checkbox' onChange={checkboxClicked} value={useSavedCard}/>
                <span>Use savedCard</span>
            </div> */}

            </div>
            {/* <button onClick={submitClicked}>Make Payment</button> */}
        </div>
    )
}