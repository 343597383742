

import { Autocomplete, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PurpleCheckBox, TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import '../../styling/discover.css'




export default function GymFilter({title,filters,openP,clicked,checked,gyms,appliedFilters}) {

    const [mouseDown, setMouseDown] = useState(false)

    const [open, setOpen] = useState(false)
    const [hoverState, setHoverState] = useState(false)

    const [amountSelected, setAmountSelected] = useState(0)
    const [amountFilters, setAmountFilters] = useState(0)

    const [filtersJSON, setFiltersJSON] = useState(filters)

    
    useEffect(() => {

        setFiltersJSON(filters)

    },[filters])


    useEffect(() => {

        setOpen(openP)

    },[openP])


    useEffect(() => {

        calculateAmountSelected()

    },[appliedFilters])

 
    const tileMouseEnter = () => {
        if(!open){setHoverState(true)}
    }

    const tileMouseLeave = () => {
        setMouseDown(false)
        setHoverState(false)
    }

    
    const titleMouseEnter = () => {
        if(open){setHoverState(true)}
    }

    const titleMouseLeave = () => {
        if(open){setHoverState(false)}
    }



    const tileClicked = () => {
        if(!open)
        {
            setOpen(true)
            clicked(true)
        }
        
    }

    const titleClicked = () => {

        console.log("TITLE Clicked")

        if(open){
            setOpen(false)
            clicked(false)
        }
    }


    const calculateAmountSelected = () => {
        var amountChecked = 0
        filtersJSON.forEach(option => {
            var checked = (appliedFilters[title].includes(option.value))
            if(checked){amountChecked++}
        })
        if(amountSelected !== amountChecked){setAmountSelected(amountChecked)}
    }

    
    const checkBoxChanged = (event,value) => {
        let isChecked = event.target.checked 
        if(isChecked){
            
            setAmountSelected(amountSelected+1)
            let filterArray = appliedFilters[title]
            filterArray.push(value)
            let newFilters = JSON.parse(JSON.stringify(appliedFilters))
            newFilters[title] = filterArray
            let filteredGyms = getFilteredGyms(newFilters,"")
            checked(1,newFilters,filteredGyms)
        }
        else{
            
            setAmountSelected(amountSelected-1)
            
            let filterArray = appliedFilters[title]
            filterArray = filterArray.filter(function(item) {return item !== value})
            if(title==="Budget"){
                filterArray = filterArray.filter(function(item) {return (item.top !== value.top && item.bottom !== value.bottom) })
            }
            
            let newFilters = JSON.parse(JSON.stringify(appliedFilters))
            newFilters[title] = filterArray
            let filteredGyms = getFilteredGyms(newFilters,"")
            
            checked(-1,newFilters,filteredGyms)
        }

    }

    const getFilters = () => {

        let index = 0
        var amountChecked = 0

        let filterArray = []
        filtersJSON.forEach(option => {
            let thisValue = option.value


            var checked = (appliedFilters[title].includes(option.value))
            if(title === "Budget")
            {
                appliedFilters[title].forEach(element => {
                    if(element.top === option.value.top)
                    {
                        checked = true
                    }
                });
            }
            let gymsWithFilter = gymsWithThisFilter(thisValue)

            if(checked){amountChecked++}

            filterArray.push(
                <div className='gymFilterOption'>
                    <div className='multipleChoiceAnswer'>
                        <PurpleCheckBox onChange={event =>checkBoxChanged(event ,thisValue)} checked={checked}/>
                        <span>{option.name}</span>
                    </div>
                    <SecondaryText variant='body2'>{gymsWithFilter.length}</SecondaryText>
                </div>
            )


            index++
        });

        if(amountSelected !== amountChecked){setAmountSelected(amountChecked)}
        if(amountFilters === 0){setAmountFilters(filterArray.length)}

        return filterArray
    }


    const gymsWithThisFilter = (value) => {

        var gymArray = []
        let filteredGyms = getFilteredGyms(appliedFilters,title)

        if(title === "Pass availability")
        {
            gymArray = filteredGyms.filter(function(gym) {
                return applyAvailabilityFilter(value,gym)
            })
        }

        if(title === "Budget")
        {
            gymArray = filteredGyms.filter(function(gym) {
                return applyBudgetFilter(value,gym)
            })
        }

        
        if(title === "Type of gym")
        {
            gymArray = filteredGyms.filter(function(gym) {
                return applyTypeGymFilter(value,gym)
            })
        }

        
        if(title === "Amenities")
        {
            gymArray = filteredGyms.filter(function(gym) {
                return applyAmenitiesFilter(value,gym)
            })
        }

        
        if(title === "Equipment")
        {
            gymArray = filteredGyms.filter(function(gym) {
                return applyEquipmentFilter(value,gym)
            })
        }

        
        if(title === "Sell passes online")
        {
            

            gymArray = filteredGyms.filter(function(gym) {
                return applySellsPassesFilter(value,gym)
            })
        }

        return gymArray
    }

    const getFilteredGyms = (filters,ignore) => {
        
        var gymArray = []


        
        var thisSectionArray = []
        filters["Pass availability"].forEach(filter => {
            
            var thisFilterGyms = gyms.filter(function(gym) {
                return applyAvailabilityFilter(filter,gym)
            })
            thisSectionArray.push(...thisFilterGyms)
        });

        if(filters["Pass availability"].length === 0 || ignore==="Pass availability"){gymArray = gyms}
        else{gymArray = thisSectionArray}


        thisSectionArray = []
        filters["Budget"].forEach(filter => {
            
            var thisFilterGyms = gymArray.filter(function(gym) {
                return applyBudgetFilter(filter,gym)
            })
            thisSectionArray.push(...thisFilterGyms)
        });
        if(filters["Budget"].length !== 0 && ignore !== "Budget"){gymArray = thisSectionArray} 


        
        thisSectionArray = []
        filters["Type of gym"].forEach(filter => {
            
            var thisFilterGyms = gymArray.filter(function(gym) {
                return applyTypeGymFilter(filter,gym)
            })
            thisSectionArray.push(...thisFilterGyms)
        });
        if(filters["Type of gym"].length !== 0 && ignore !== "Type of gym"){gymArray = thisSectionArray} 


        
        thisSectionArray = []
        filters["Amenities"].forEach(filter => {
            
            var thisFilterGyms = gymArray.filter(function(gym) {
                return applyAmenitiesFilter(filter,gym)
            })
            thisSectionArray.push(...thisFilterGyms)
        });
        if(filters["Amenities"].length !== 0 && ignore !== "Amenities"){gymArray = thisSectionArray} 


        
        
        thisSectionArray = []
        filters["Equipment"].forEach(filter => {
            
            var thisFilterGyms = gymArray.filter(function(gym) {
                return applyEquipmentFilter(filter,gym)
            })
            thisSectionArray.push(...thisFilterGyms)
        });
        if(filters["Equipment"].length !== 0 && ignore !== "Equipment"){gymArray = thisSectionArray} 


        
        thisSectionArray = []
        filters["Sell passes online"].forEach(filter => {
            
            var thisFilterGyms = gymArray.filter(function(gym) {
                return applySellsPassesFilter(filter,gym)
            })
            thisSectionArray.push(...thisFilterGyms)
        });
        if(filters["Sell passes online"].length !== 0 && ignore !== "Sell passes online"){gymArray = thisSectionArray} 


        gymArray = gymArray.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
            )
        
        return gymArray
    }

    const applyAvailabilityFilter = (days,gym) => {

        var hasDays = false

        gym.passes.forEach(pass => {
            let passDays = pass.days
            if(passDays === days){hasDays = true}
        });

        return hasDays
    }


    
    const applyBudgetFilter = (value,gym) => {
        let bottom = value.bottom*100
        let top = value.top*100

        if(gym.passes === null || gym.passes.length===0){return false}
        else{
            let price = gym.passes[0].price
            return (price>=bottom && price<top)
        }

    }
    
    
    const applyTypeGymFilter = (type,gym) => {

        
        if(type === "Commercial") 
        {
            if(gym.facilityType===undefined)
            {
                return true
            }
            else{
                return gym.facilityType === "" || gym.facilityType.includes("Commercial")
            }
            
        }
        else{
            return gym.facilityType === type
        }

    }

    
    const applyAmenitiesFilter = (amenity,gym) => {

        return gym.amenities.includes(amenity)
    }

    
    const applyEquipmentFilter = (equipment,gym) => {

        return gym.equipment.includes(equipment)
    }

    const applySellsPassesFilter = (sellsPasses,gym) => {

        return gym.sellsPasses === sellsPasses
    }


    return(

        <div className={`gymFilter ${mouseDown?"pressed":""} ${hoverState?"hoverState":""}` } 
        onClick={tileClicked} 
        onMouseDown={()=>setMouseDown(true)} 
        onMouseUp={()=>setMouseDown(false)} 
        onMouseLeave={tileMouseLeave}
        onMouseEnter={tileMouseEnter}>
            
           <div className='gymFilterTitle' onClick={titleClicked} onMouseLeave={titleMouseLeave} onMouseEnter={titleMouseEnter}>
               <span>{title}</span>
               <div className='gymFilterCircle'>{amountSelected}</div>
           </div>


           {open && <div className={`gymFilterContents ${amountFilters>4?"overflowState":""}`}>
                

                {getFilters()}
                
           </div>}
            
        </div>
    )
}