import { getAuth,signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../styling/header.css'
import { PurpleButton } from "./mui/Buttons";
import { PrimaryText } from "./mui/Typography";
import { ConfirmationNumberOutlined, FingerprintRounded, LogoutRounded } from "@mui/icons-material";

export default function Header({loggedIn, user}) {


    const [optionsOpen,setOptionsOpen] = useState(false)

    const [initials,setInitials] = useState("")
    const [name,setName] = useState("")

    const navigate = useNavigate();


    useEffect(() => {

        if(user !== null)
        {
            let firstName = user.personalDetails.firstName
            let surname = user.personalDetails.surname

            setName(firstName+" "+surname)
            setInitials(firstName[0]+surname[0])
        }

    },[user])

    const routeSelected = (route) => {
        
        setOptionsOpen(false)
        navigate(`../${route}`, {   });
    }

    const optionsClicked = () =>{
        console.log("OPTION CLOKCED")
        setOptionsOpen(!optionsOpen)
        // logout()
    }

    const logout = () => {
        const auth = getAuth();
        
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
            navigate("../", {   });
          }).catch((error) => {
            // An error happened.
            console.log("ERROR", error.message)
          });
    }


    return(
        <div className="header">
            
            {/* <span className="headerTest">Test Mode</span> */}

            <div className="fullScreen">
                <div className="container">
                    <div className="headerRow">

                        <img className="logoHeader" src="/assets/gymExplorerLogoWhite.png" onClick={()=>routeSelected('')}/>



                        <div className="buttonsRowHeader">
                            
                            {!loggedIn && <PurpleButton variant="outlined" size="medium" onClick={()=>routeSelected('login')}>Sign In</PurpleButton>}

                            {loggedIn && <div className="headerProfile" >
                                <div className="headerProfileTitle" onClick={optionsClicked}>
                                    <div className="headerProfileCircle">{initials}</div>
                                    <span>{name}</span>
                                    {/* <img className="headerProfileDown" src="assets/arrowDown.png"/> */}
                                </div>
                                
                                {optionsOpen &&<div className="headerProfileOptions">
                                    <div className="headerProfileOption" onClick={()=>routeSelected('profile')}>
                                        <FingerprintRounded className="hpoImg"/>
                                        <PrimaryText variant="body1">Profile</PrimaryText>
                                    </div>

                                    <div className="headerProfileOption" onClick={()=>routeSelected('myPasses')}>
                                        <ConfirmationNumberOutlined className="hpoImg"/>
                                        <PrimaryText variant="body1">My passes</PrimaryText>
                                    </div>
                                    <div className="headerProfileOption signOut" onClick={logout}>
                                        <LogoutRounded className="hpoImg"/>
                                        <PrimaryText variant="body1">Sign out</PrimaryText>
                                    </div>
                                </div>}

                            </div>}

                            {/* <span>HELP</span> */}

                        </div>




                    </div>
                </div>
            </div>

            


        </div>
    )
}


