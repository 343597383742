import {useNavigate,useLocation} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import '../../styling/discover.css'
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { Clear } from '@mui/icons-material';


export default function GymTileHome({gym,clicked,accommodation,onList,optionsToggle,optionsOpened}) {

    const [mouseDown, setMouseDown] = useState(false)
    const [hoverState, setHoverState] = useState(false)

    const [showOptions, setShowOptions] = useState(false)

    let stringArray = ["Cats","Dogs","Geese"]
    let combined = stringArray.join(" • ")

    const mouseLeave = () => {
        setMouseDown(false)
        setHoverState(false)
    }

    const getPerDayString = (days) => {

        if(days === 1){return "day"}
        else if(days === 7){return "week"}
        else{return days + " days"}
    }

    useEffect( () => {
        if(optionsToggle!==gym.id)
        {
            setShowOptions(false)
        }
    }, [optionsToggle]);

    useEffect( () => {
        if(showOptions){optionsOpened()}
    }, [showOptions]);

    useEffect(() => {
   
    },[])

    

    return(
        <div className='gymTile' onClick={clicked} onMouseDown={()=>setMouseDown(true)} 
        onMouseLeave={mouseLeave} onMouseEnter={()=>setHoverState(true)}>
            
            <img className={`gymTileImg ${onList?"":"mapTile"}`} src={gym.images[0]}/>
            
          

            <div className={`gymTileContentHome ${mouseDown?"clicked":""} ${onList?"":"mapTile"} ${hoverState?"hover":""}`}>

                <div className='gymTileTitle'>
                    <PrimaryText variant='subtitle1'>{gym.name}</PrimaryText>
                    <SecondaryText variant='body2'>{gym.addressShort||gym.city+", "+gym.country}</SecondaryText>
                </div>

        

                <div className='gymTileOptionRow'>
                    <span className='gymTileOptionsText' onClick={()=>setShowOptions(true)}>View passes</span>
                    {gym.sellsPasses && <div className='gymTilePriceRow'>
                        <span className='gymTilePriceHome'>€{(gym.passes[0].price/100).toFixed(2)}</span>
                        <span className='gymTilePerDay'>/{getPerDayString(gym.passes[0].days)}</span>
                    </div>}
                </div>
            </div>

            
            {showOptions && <div className="gymTileOptionsPopUpHome">
                <div className='gymTileOptionsTitle'>
                    <PrimaryText variant='caption' className='gymTileOptionsComp'>Pass options</PrimaryText>
                    <Clear className='gymTileOptionsComp' onClick={()=>setShowOptions(false)}/>
                </div>
                <div className='gymTileOptionsLine' />
               <div className='gymTileOptionsContent'>
                    {gym.passes.map((pass) => (
                            <div className='gymTileOptionsRow'>
                                <SecondaryText className='gymTileOptionsComp'>{pass.name}</SecondaryText>
                                <span className='gymTileOptionPrice'>€{(pass.price/100).toFixed(2)}</span>
                            </div>
                        ))}
                </div>

            </div>}
        
            
        </div>
    )
}