import React, { useEffect, useState } from 'react';
import axios from "axios";
import { getAuth} from "firebase/auth";

export default function MyBookings({bookingsP}) {

    const [loading,setLoading] = useState(true)
    const [bookings,setBookings] = useState([])
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    useEffect(() => {

        loadBookingsFromDB(bookingsP)    

    },[])

    const loadBookingsFromDB = (bookings) => {
        const auth = getAuth();
        const uid = auth.currentUser.uid;

        axios.post(baseURL+'getBookings',{
            
            uid:uid,
            bookingIds: bookings
                

        
        }).then((response) => {
            let resVar = response.data
            console.log("Bookings",resVar)
            setLoading(false)
            setBookings(resVar.bookings)
        })

    }

    const showBookings = () => {

        let bookingArray = []

        for(const booking of bookings)
        {
            let name = booking.bookingOptionDetails.name
            bookingArray.push(<div>{name}</div>)
        }

        return bookingArray
    }

    return(
        <div>
            <h1>My Bookings</h1>
            
            {loading && <div>Loading...</div>}
            {!loading && showBookings()}
        </div>
    )


}