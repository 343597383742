

import React, { useEffect, useState } from 'react';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';




export default function TextQuestion({question,number,returnAnswer}) {

    const [answer,setAnswer] = useState(question.answer || "")

    const textUpdated = (value) => {
        setAnswer(value)
        returnAnswer(question.id,value)
    }

    return(

        <div className="questionComponent">
            
            <div className='questionTitles'>
                <PrimaryText variant='body1'>Question {number} : {question.question}</PrimaryText>
                <SecondaryText variant='body2'>Type in your answer</SecondaryText>
            </div>


            <TextInput type="email" variant="outlined" label="Add your answer" placeholder='Type here'
                value={answer} onChange={(e) => textUpdated(e.target.value)}/>
            

            {/* <div className='questionAnswers'>

            </div> */}
            
        </div>
    )
}