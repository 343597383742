import { Card, Chip, Step, StepLabel, Stepper } from "@mui/material";
import { styled } from "@mui/system";



export const ProgressBar = styled(Stepper)({

    marginTop:'32px',
    // width:'800px',

    ".MuiStepConnector-line":{
        borderColor:'#151329',
    },
    

    ".Mui-active":{  
        
        "&.MuiStepLabel-label":{
            color:'#766CE3',
        },
        
        "&.MuiStepIcon-root": { color: "#766CE3" },    
    },


    ".Mui-completed":{  
        
        "&.MuiStepLabel-label":{
            color:'#000',
        },
        "&.MuiStepIcon-root": { color: "#62BA9A" },
        
    },
})


export const POCard = styled(Card)({

    minHeight:'179px',

    
})


export const FormStatusChip = styled(Chip)({

    color:'#72717C',
    borderColor:"#72717C",
    height:'24px',
    
})




export const DiscoverSortSelected = styled(Chip)({

    cursor:'pointer',
    color:'white',
    borderColor:"#151329",
    height:'26px',
    backgroundColor:"#151329"
})



export const DiscoverSortChip = styled(Chip)({

    cursor:'pointer',
    color:'#151329',
    borderColor:"#151329",
    height:'26px',
})
