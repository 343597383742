import {useNavigate,useLocation} from 'react-router-dom';
import { getAuth  } from "firebase/auth";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import html2canvas from 'html2canvas'
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { Card, Chip } from '@mui/material';
import FormStatusBar from './FormStatusBar';
import { PurpleButton, PurpleOutlineButton } from '../mui/Buttons';
import TextQuestion from '../questions/TextQuestion';
import DropdownQuestion from '../questions/DropdownQuestion';
import MultipleChoice from '../questions/MultipleChoice';
import { PurpleCheckBox } from '../mui/Inputs';

export default function BookingForms({booking,questionsP,formsP, confirmed, activatingPass}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    var signPad = null;

    const [questionsFilled,setQuestionsFilled] = useState(false)
    const [hasSigned,setHasSigned] = useState(false)
    const [step,setStep] = useState(1)

    const [questions,setQuestions] = useState([])
    const [forms,setForms] = useState([])

    const navigate = useNavigate();

    useEffect(() => {

        setQuestions(questionsP)
        setForms(formsP)

    },[questionsP,formsP])


    useEffect(() => {

        console.log("ASNWERS",questions)

    },[step])


    const backClicked = () => {
        // returnPage("Complete")
    }

    const addToDB = (signatureLink) => {

        let user = JSON.parse(localStorage.getItem("user"))

        let answers = []
        questions.forEach(question => {
            answers.push({questionID:question.id,answer:question.answer})
        });
    

        activatingPass(true)
        axios.post(baseURL+'addBookingFormDetails',{
        
            bookingID:booking.id,

            uid:user.uid,
            signature:signatureLink,
            answers:answers,

            booking:booking


        
        }).then((response) => {
            let resVar = response.data
            console.log("Response booking forms", resVar)

            booking.pass = {
                pdfLink:resVar.walletFiles.pdf,
                googleWalletLink:resVar.walletFiles.google,
                appleWalletLink:resVar.walletFiles.apple
            }

            activatingPass(false)

            // booking.pass.pdfLink = resVar.walletFiles.pdf
            // booking.pass.googleWalletLink = resVar.walletFiles.google
            // booking.pass.appleWalletLink = resVar.walletFiles.apple
            navigate("../activatedBooking/"+booking.id, {   state:{booking:booking}});

            // returnPage("Activated")
        })

    }



    const checkSigningPadEmpty = () => {
        
        if(signPad === null)
        {
            return false
        }else{
            return signPad.isEmpty()
        }
        
    }

    const checkSigningPad = async() => {
        

        if(signPad.isEmpty())
        {
            console.log("No signature")
        }
        else{
            let signature = signPad.getTrimmedCanvas().toDataURL('image/png')
            console.log("SIG 1",signature)

            // window.open('', document.getElementById('signingTest').toDataURL());
            
            let div = document.getElementById('signingTest')
            
            const canvas = await html2canvas(div);
            const signature2 = canvas.toDataURL("image/png", 1.0);

            // let signature2 = div.toDataURL('image/png')
            // window.open('', canvas.toDataURL("image/png", 1.0));

            console.log("SIG 2",signature2)

            addToDB(signature2)
        }
        
    }

    const submit = async() => {

        console.log("SUBMTU")

        if(forms.length === 0)
        {
            addToDB("")
        }
        else{
            let div = document.getElementById('termsSection')
            
            const canvas = await html2canvas(div);
            const signature2 = canvas.toDataURL("image/png", 1.0);
            addToDB(signature2)
        }

        confirmed()
    }

    
    const clearSigningPad = () => {
        signPad.clear()
        setHasSigned(false)
    }

    const signingStarted = () => {
        setHasSigned(true)

    }

    const showForms = () => {
        let formArray = []

        forms.forEach(form => {
            formArray.push(

                <Card sx={{width:'100%'}}>
                    <div className='formScrollable terms'>
                        <div className='formContent'>
                            <PrimaryText variant='subtitle1'>Gym Rules</PrimaryText>
                            <PrimaryText variant='body1'>{form.text}</PrimaryText>
                        </div>
                        
                    </div>
                </Card>

            )
            
        });

        return formArray
    }

    const showQuestions = () => {

        let questionArray = []

        var index = 1
        questions.forEach(question => {
            
            let type = question.type

            if(type === "text"){questionArray.push(<TextQuestion question={question} number={index} returnAnswer={questionAnswered}/>)}
            else if(type === "dropdown"){questionArray.push(<DropdownQuestion question={question} number={index} returnAnswer={questionAnswered}/>)}
            else if(type === "multiple"){questionArray.push(<MultipleChoice question={question} number={index} returnAnswer={questionAnswered}/>)}

            if(index !== questions.length){questionArray.push(<div className='questionDivider' />)}

            index++
        });

        return questionArray

    }

    const questionAnswered = (id,value) => {

        console.log("QUESTONIS ASNSWERED",id,value)

        let allFilled = true

        questions.forEach(question => {
            if(question.id === id)
            {
                question["answer"] = value
                if(value==="" || value.length === 0){allFilled = false}
            }
            else{
                if(question.answer === undefined){allFilled = false}
                else if(question.answer === ""){allFilled = false}
                else if(question.answer.length === 0){allFilled = false}
            }
        });

        setQuestionsFilled(allFilled)
        setQuestions(questions)
    }

    return(
        <div className='formsScreen'>

            <div className="passOverviewHeaders">
                <PrimaryText variant="h4">Gym Requirements</PrimaryText>
                <SecondaryText variant="body2">To activate your pass, you will need to fill out all required forms and accept the gym's rules</SecondaryText>
            </div>

            <FormStatusBar hasQ={questions.length>0} hasSig={forms.length>0} step={step}/>

            {(step==1 && questions.length>0)&& <div className='questionnaireSection'>
                <Card sx={{width:'100%'}}>
                    <div className='formScrollable'>
                        <div className='formContent'>
                            {showQuestions()}

                        </div>
                        
                        
                    </div>
                </Card>
            </div>}

            {(step === 2 || (step === 1 && questions.length === 0))&& <div className='termsSection' id='termsSection'>
                {showForms()}
                <Card className='signingCard'>
                    <div className='signingArea'>
                        <SignatureCanvas penColor='black'
                        onBegin={signingStarted}
                        ref={(ref) => { signPad = ref }}
                        canvasProps={{className: 'signCanvas'}} />
                        <div className='signingAreaBase'>
                            <span>Add your signature</span>
                            <span className="signatureClearBtn" onClick={clearSigningPad}>Clear</span>
                        </div>
                        
                    </div>
                </Card>
                
                
                {/* <button className="signatureSave" onClick={checkSigningPad}>Save</button> */}

            </div>}


            {step === 1 && <div className='multipleChoiceAnswer'>
                    <PurpleCheckBox  />
                    <SecondaryText variant='caption'>Autosave details for future forms.</SecondaryText>
            </div>}
            {step === 2 && <div className='multipleChoiceAnswer'>
                    <PurpleCheckBox  />
                    <SecondaryText variant='caption'>I hereby agree to all the terms.</SecondaryText>
            </div>}

            <div className='poButtons'>
                <PurpleOutlineButton variant='outlined' size='medium' onClick={()=>setStep(1)} >BACK</PurpleOutlineButton>
                {(step === 1 && questions.length>0 && forms.length>0) && <PurpleButton variant="contained" size="medium" disabled={!questionsFilled} onClick={()=>setStep(2)}>NEXT</PurpleButton>}
                {step === 2 && <PurpleButton variant="contained" size="medium" disabled={!hasSigned} onClick={submit}>ACTIVATE PASS</PurpleButton>}
                {(step === 1 && questions.length==0) && <PurpleButton variant="contained" size="medium" disabled={!hasSigned} onClick={submit}>ACTIVATE PASS</PurpleButton>}
                {(step === 1 && forms.length==0) && <PurpleButton variant="contained" size="medium" onClick={submit} disabled={!questionsFilled}>ACTIVATE PASS</PurpleButton>}
            </div>


        </div>
    )
}