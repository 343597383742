import { getAuth,createUserWithEmailAndPassword,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut,reauthenticateWithCredential,EmailAuthProvider,sendPasswordResetEmail  } from "firebase/auth";
// import {app} from '../index'
import React, { useEffect, useState } from 'react';


export default function AuthTest() {

    
    const email = "caolangm@gmail.com"
    const password = 1234566789
    const phoneNumber = +353851862439
    // const appVerifier = window.recaptchaVerifier;
    const [codeText,setCodeText] = useState('')

    const facebook = async() => {
        const provider = new FacebookAuthProvider();

        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;

            console.log("Success", accessToken)
            // ...
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = FacebookAuthProvider.credentialFromError(error);

            console.log(" Error", errorMessage, credential)
            // ...
        });

    }


    const google = async() => {

        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            console.log("Success GOOGLE", user)

            // ...
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.log("Google Error", error, credential)

          });
        
    }

    const captcha = async() => {

        console.log("Captcha")


        const auth = getAuth();
        // window.recaptchaVerifier = new RecaptchaVerifier('captcha', {}, auth);
        
        window.recaptchaVerifier  = new RecaptchaVerifier('captcha', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
              console.log("Captcha call", response)
            },
            'expired-callback': () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
              console.log("Expired call")
            }
          }, auth);


        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
            // const recaptchaResponse = grecaptcha.getResponse(recaptchaWidgetId);
            // console.log("RRES",recaptchaResponse)
          });

          return



    }

    const phoneNumberVerification = async () => {

        await captcha()

        const phoneNumber = "+353851862439"
        const appVerifier = window.recaptchaVerifier;
        
        const auth = getAuth();
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              console.log("Result",confirmationResult)
              // ...
            }).catch((error) => {
                console.log("Error",error)
              // Error; SMS not sent
              // ...
            });

    }

    const confirmCode = () => {
        const code = codeText
        window.confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
            console.log("Success")
        // ...
        }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
            console.log("Error",error)
        });
    }


    useEffect(() => {

                
     
      
        },[])




    const createAccount = () => {
        const auth = getAuth();
        console.log("REGISTER CLICKED")

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("USER", user)
        })
        .catch((error) => {
            // An error happened.
            const errorMessage = error.message;
            console.log("ERROR", errorMessage)
        });
    }


    const signIn = () => {
        const auth = getAuth();
        console.log("SIGN IN CLICKED")
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("USER", user)
        })
        .catch((error) => {
            // An error happened.
            const errorMessage = error.message;
            console.log("ERROR", errorMessage)
            
        });
    }

    const logOut = () => {
        const auth = getAuth();
        console.log("SIGN OUT CLICKED")
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("SIGN OUT SUCCESS")
          }).catch((error) => {
            // An error happened.
            console.log("ERROR", error.message)
          });
    }

    const checkUser = () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            //The user is signed in
            console.log("Current User", user)
          } else {
            // No user is signed in.
            console.log("Not Signed In")
          }
    }


    const reauth = () => {
    
        const auth = getAuth();
        const user = auth.currentUser;

        const credential = EmailAuthProvider.credential(
            email,
            password
        )

        console.log("CREDENTILA",credential)
        reauthenticateWithCredential(user, credential).then(() => {
        // User re-authenticated.
        console.log("REAUTH SUCCESS")
        }).catch((error) => {
            console.log("RAUTH ERROR", error)
        // An error ocurred
        // ...
        });
    }

    const forgotPassword = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log("FORGOT SCUSS")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("FORGOT ERR",errorCode,errorMessage)
        });
    }

    return(

        <div className="flexColumn">
            
            

            <button onClick={createAccount}>Register</button>
            <button onClick={signIn}>Sign In</button>
            <button onClick={logOut}>Log Out</button>
            <button onClick={checkUser}>Check User</button>
            <button onClick={phoneNumberVerification}>Phone Number</button>
            <button onClick={confirmCode}>Confirm</button>

            <button onClick={facebook}>Facebook</button>
            <button onClick={google}>Google</button>

            <button onClick={reauth}>Re-Authenticate</button>
            <button onClick={forgotPassword}>Forgot Password</button>

            <input className='smallText formInput' type="text" placeholder={"Code"} value={codeText} onChange={(e) => setCodeText(e.target.value)}/>

            <div  className="captcha">
                <span>f</span>
                
                <div id='captcha'></div>
            </div>
        </div>

    )

}