import {useNavigate,useLocation} from 'react-router-dom';
import axios from "axios";
const FileDownload = require('js-file-download');

export default function Pass() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const location = useLocation();
    const navigate = useNavigate();

    
    var gym = location.state.gym
    var pass = location.state.pass

    const getAppleWallet = () => {
        axios.get(baseURL+'generateAppleWalletPass',{
            
            params:{
                gym: gym.name,
                pass:pass.name
            },

            responseType:'blob'

        
        }).then( (response) => {
        
            console.log("Response APPLE",response)
            // const data = response.data;
            // let pass = data.pass
            
            let blob = new Blob([response.data], {
                type: "application/vnd.apple.pkpass",
              });

              FileDownload(response.data, 'gymPass.pkpass');
            
            //   const url = window.URL.createObjectURL(
            //     new Blob([response.data], {
            //       type: "application/vnd.apple.pkpass",
            //     })
            //   );

            // console.log("URL",url)

            // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            // if (newWindow) newWindow.opener = null
        })
    }

    const getGoogleWallet = () => {
        axios.get(baseURL+'generateGoogleWalletPass',{
            
            params:{
                gym: gym.name,
                pass:pass.name
            }

        
        }).then( (response) => {
        
            const data = response.data;
            let pass = data.pass
            
            const newWindow = window.open(pass, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        })
    }


    return(
        <div>
            <h1>Pass</h1>
            <div>{pass.name + " for " + gym.name}</div>
            <br /><br />
            <button onClick={getAppleWallet}>Apple Wallet</button>
            <button onClick={getGoogleWallet}>Google Wallet</button>
        </div>
    )
}