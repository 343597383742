

import { Autocomplete, InputAdornment } from '@mui/material';
import React, { useEffect, useState,useRef } from 'react';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import {useLoadScript,GoogleMap, Marker } from "@react-google-maps/api"
import { assassinStyle, purpleStyle,silverStyle } from '../../styling/mapStyle';
import '../../styling/discover.css'
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { Add, Cancel, ChevronLeft, ChevronRight, ContactPageSharp, EmailOutlined, Remove } from '@mui/icons-material';
import AutoComplete from "react-google-autocomplete"
import axios from "axios";
import GymFilter from './GymFilter';
import MapLegend from './MapLegend';
import GymTile from './GymTile';
import * as ReactDOMServer from 'react-dom/server';
import { useNavigate } from "react-router-dom";


const fetch = require('node-fetch');


export default function MapPopUp({close,accommodation, accommodationChanged,enteredLocation,gyms,filteredGyms,filterChange,appliedFilters,gymSearch,setBounds,selectedGym,fromProfile,budgetOptions}) {

    const [accomodationText,setAccommodationText] = useState("")
    const [enteredAccommodation,setEnteredAccommodation] = useState(null)
    const [filtersOpen,setFiltersOpen] = useState(false)
    const [filtersChecked,setFiltersChecked] = useState(0)
    const [currentFilter,setCurrentFilter] = useState(false)

    

    



    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyAH_HmdzvswIPwiY2XpM_xHxvdHQW1Qcgs"
    })

    const filterRef = React.createRef()

    useEffect( () => {
        if(accommodation!== null)
        {
            setAccommodationText(accommodation.formatted_address)
            setEnteredAccommodation(accommodation)
        }   
    }, [accommodation]);
    
    useEffect( () => {   
        
        
        console.log("AACCO",accommodation)

        const input2 = document.getElementById("autocomplete");

        const options2 = {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            types: ['lodging','street_address'],
          };

        const autocomplete2 = new window.google.maps.places.Autocomplete(input2, options2);
        window.google.maps.event.addListener(autocomplete2, 'place_changed', function () {
            var place = autocomplete2.getPlace();
            console.log("PLACE",place)
            setAccommodationText(place.formatted_address)
            setEnteredAccommodation(place)
            accommodationChanged(place)
        })
    }, []);
    

    
    const filterClicked = (open) => {
        setFiltersOpen(open)
    }


    
    const arrowClickedLeft = () =>{

        if(currentFilter >0){
            filterRef.current.scrollLeft -= 252
            setCurrentFilter(currentFilter-1)
        }

        
    }
    
    const arrowClickedRight = () =>{

        if(currentFilter <4){
            filterRef.current.scrollLeft += 252
            setCurrentFilter(currentFilter+1)
        }
        
    }

    const closeClickery = () => {
        console.log("COME DOWN")
    }

    return(

        <div className="mapPopUpScreen">
            <div className='mapPopUp'>
                <Cancel sx={{height:30, width:30}}className='mapPopUpClose' onClick={close} />
                <div className='mapPopUpTitles'>
                    
                    <TextInput id='autocomplete' className="popUpAccInput" variant="outlined" size='small' label="Accommodation location"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img className='accommodationInputImg'  src='/assets/accommodationMarker.png'/>
                                            </InputAdornment>
                                            ),
                                    }} 
                                    value={accomodationText} onChange={(e) => setAccommodationText(e.target.value)} />

                    <div ref={filterRef} className="discoverFilterRow map">
                            <GymFilter title={'Sell passes online'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Yes',value:true},{name:'No' ,value:false}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Pass availability'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Day pass',value:1},{name:'3 days pass',value:3},{name:'Week pass' ,value:7}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Budget'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={budgetOptions} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Type of gym'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Commercial',value:'Commercial'},{name:'CrossFit',value:'CrossFit'},{name:'Sports Centre' ,value:'Sports Centre'},{name:'Boxing/Martial Arts' ,value:'Boxing/Martial Arts'},{name:'Powerlifting' ,value:'Powerlifting'},{name:'Climbing Centre' ,value:'Climbing Centre'},{name:'Yoga/Pilates Studio',value:'Yoga/Pilates Studio'}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Amenities'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Swimming pool',value:'Swimming Pool'},{name:'Showers',value:'Showers'},{name:'Lockers' ,value:'Lockers'},{name:'Boxing studio' ,value:'Boxing studio'},{name:'Yoga studio' ,value:'Yoga Studio'},{name:'Massages' ,value:'Massages'}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Equipment'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Free weights',value:'Free weights'},{name:'Spin bikes',value:'Spin bikes'},{name:'Treadmills' ,value:'Treadmills'},{name:'Dumbbells' ,value:'Dumbbells'},{name:'Rowing Machines' ,value:'Rowing Machines'},{name:'Kettle Bells' ,value:'Kettle Bells'}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                    </div>

                    <div className="discoverFilterArrowsMap">
                        <ChevronLeft className={`discoverFilterArrow ${currentFilter >0?"":"disabled"}`} onClick={arrowClickedLeft}/>
                        <ChevronRight className={`discoverFilterArrow ${currentFilter <4?"":"disabled"}`} onClick={arrowClickedRight}/>
                    </div>            
                </div>
                {isLoaded && 
                    <Map 
                        accommodation={enteredAccommodation} 
                        gymsP={filteredGyms} 
                        enteredLocation = { enteredLocation}
                        allGyms={gyms} 
                        setBounds={setBounds} 
                        gymSearch={gymSearch} 
                        selectedGym={selectedGym}
                        fromProfile={fromProfile}
                        />}
                {!isLoaded && <div>Loading...</div>}

            </div>
        </div>
    )
}

























function Map({accommodation,gymsP,enteredLocation,allGyms,setBounds,gymSearch,selectedGym,fromProfile}){


    const mapRef = useRef();
    const navigate = useNavigate();


    var markers = []


    const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl:false,
    mapTypeControl:false,
    zoomControl: false,
    minZoom:4,
    styles:silverStyle,
    };

    const [mapCentre,setMapCentre] = useState({lat:enteredLocation.lat,lng: enteredLocation.lng})
    const [mapZoom,setMapZoom] = useState(13)

    const [boundsChanged,setBoundsChanged] = useState(false)
    const [selectedMarker,setSelectedMarker] = useState("")
    const [enteredAccommodation,setEnteredAccommodation] = useState(accommodation)

    const [accommodationMarker,setAccommodationMarker] = useState(null)
    const [clusterer,setClusterer] = useState(null)
    const [initialised,setInitialised] = useState(false)

    
    
    useEffect( () => {   
        
        if(selectedGym!==null)
        {
            setMapCentre({lat:selectedGym.lat,lng:selectedGym.lng})
        }

    }, []);
    
    useEffect( () => {   
        setEnteredAccommodation(accommodation)

        // if(accommodation!==null){setMapCentre({lat: accommodation.geometry.location.lat(), lng: accommodation.geometry.location.lng()})}

    }, [accommodation]);

    
    useEffect( () => {   

        if(accommodationMarker !== null)
        {
            accommodationMarker.setMap(null)
        }

        if(enteredAccommodation !== null)
        {
            if(clusterer!== null){clusterer.setMap(null)}
            initMap()
        }

        

    }, [enteredAccommodation]);
        

    useEffect( () => {   

        if(gymsP.length > 0)
        {
            initMap()
        }

    }, [gymsP]);
    
    const options = {
        imagePath:'/assets/cluster/m',
        // styles:[{
        //     url:'/assets/cluster/m1.png',
        //     textColor:"#fff"
        // },
        // ]
    // imagePath:'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    }

            
    const createKey = (gym) => {
        return gym.lat + gym.lng
        }


    const markerClicked = (marker,gym) => {
        console.log("Marker clicked",marker,gym)
        setSelectedMarker(createKey(gym))
        if(mapZoom === 16){setMapZoom(16.1)}
        else{setMapZoom(16)}
        
        setMapCentre({lat:gym.lat,lng:gym.lng})

    }


    const handleOnLoad = (map) => {
        console.log("MAP",map)
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(<button>Test 2</button>);
      };

     


    
    const newBounds = (e) => 
    {
        
        if(mapRef.current !== undefined)
        {
            if(mapRef.current.state.map !== null)
            {
                let newBounds = mapRef.current.state.map.getBounds()
                let boundsJSONStr = JSON.stringify(newBounds)
                let boundsJSON = JSON.parse(boundsJSONStr)

                console.log("NEW BOUNDS", boundsJSON)
                setBounds(boundsJSON)
                setBoundsChanged(true)
                

                // let center = {lat:mapRef.current.state.map.center.lat(),lng: mapRef.current.state.map.center.lng()}
                // setMapCentre(center)

            }
            
        }
        
        
    }

    const zoomIn = () => {
        if(mapRef.current !== undefined)
        {
            if(mapRef.current.state.map !== null)
            {
                let zoom = mapRef.current.state.map.zoom
                setMapZoom(zoom+1)
            }
        }
    }

    
    const zoomOut = () => {
        if(mapRef.current !== undefined)
        {
            if(mapRef.current.state.map !== null)
            {
                let zoom = mapRef.current.state.map.zoom
                setMapZoom(zoom-1)
            }
        }
    }

    const gymSelected = (gym) => {
        console.log("GYM CLICKED",gym)
        navigate("../gymprofile/"+gym.id, {   state:{gym:gym}});
    }
    
    const tileClicked = () => {
        console.log("TIle CLICKED")
    }


    const initMap = () => {
        
        
            // setInitialised(true)
            console.log("INITIALISING MAP")

        // const map2 = new window.google.maps.Map(document.getElementById("mapDiv"), {
        // })

        const map = mapRef.current.state.map
        console.log("Map",map)

       

        const infoWindow = new window.google.maps.InfoWindow({
            content: "",
            disableAutoPan: false,
          });





    const gymMarkers = [
        '/assets/greyMapPin.png',
        '/assets/mapMarkerDot.png',
        '/assets/mapMarkerSelected.png'
    ]

         


          // Add some markers to the map.
           const markers = gymsP.map((gym,i) => {


            const marker = new window.google.maps.Marker({
              position:{lat:gym.lat,lng:gym.lng},
            });

            var pinIcon

            if(selectedGym!==null&&gym.id===selectedGym.id)
            {
                pinIcon = new window.google.maps.MarkerImage(
                    '/assets/mapMarkerSelected.png',
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(43.94, 52.23)
                ); 
            }
            else if(gym.sellsPasses)
            {
                pinIcon = new window.google.maps.MarkerImage(
                    '/assets/greyMapPin.png',
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(40.88, 48.74)
                );
            }
            else{
                pinIcon = new window.google.maps.MarkerImage(
                    '/assets/mapMarkerDot.png',
                    null, /* size is determined at runtime */
                    null, /* origin is 0,0 */
                    null, /* anchor is bottom center of the scaled image */
                    new window.google.maps.Size(16, 16)
                );
            }

            marker.setIcon(pinIcon)
        
            // markers can only be keyboard focusable when they have click listeners
            // open info window when marker is clicked
            marker.addListener("click", () => {
                console.log("MARKER CLICKED", gym)
                let accoJSON = JSON.stringify(enteredAccommodation)
                navigate("../gymprofile/"+gym.id, {   state:{gym:gym,allGyms:allGyms,accommodation:accoJSON}});
            })
           

            marker.addListener("mouseover", () => {

                console.log("HOVER",marker.lat)
            
                let gymTile = <GymTile gym={gym} onList={false} accommodation={enteredAccommodation}/>
                let contentStr = ReactDOMServer.renderToString(gymTile)

              infoWindow.setContent(contentStr);
              infoWindow.setOptions({disableAutoPan:false})
              infoWindow.open(map, marker);
            //   infoWindow.setPosition({lat:gym.lat-0.01,lng:gym.lng-0.01})
            });

            marker.addListener("mouseout", () => {
                infoWindow.close()
            });

            return marker;
          });
        
          console.log("MARKERS",markers)
        //   Add a marker clusterer to manage the markers.
        

          setClusterer(new MarkerClusterer({ markers, map }))
        
          if(enteredAccommodation!== null)
          {
            console.log("E A", enteredAccommodation)

            let markerLat = enteredAccommodation.hasCoords?enteredAccommodation.geometry.location.lat:enteredAccommodation.geometry.location.lat()
            let markerLng = enteredAccommodation.hasCoords?enteredAccommodation.geometry.location.lng:enteredAccommodation.geometry.location.lng()

            const marker = new window.google.maps.Marker({
                position:{lat: markerLat, lng: markerLng},
                icon:"/assets/accommodationMarker.png",
                map:map
              });

              setAccommodationMarker(marker)
          }
          

    }

    

    const searchAreaClicked = () => {
        setBoundsChanged(false)
        gymSearch()
    }



    return(
        <div className='mapDiv' id='mapDiv'>
            <GoogleMap id='map1'
            className='map'
            zoom={mapZoom}
            center={mapCentre}
            mapContainerClassName="map"
            ref={mapRef}
            
            onDragEnd={newBounds}
            onMouseUp={newBounds}
            onZoomChanged={newBounds}
            onLoad={initMap}
            options={defaultMapOptions}>
                
            
            
            
            
            {/* <MarkerClusterer options={options}>
            {(clusterer) =>
                gymsP.map((gym) => (
                // <Marker cla key={createKey(gym.coords)} icon={gymMarkers[createKey(gym.coords)===selectedMarker?2:gym.sellsPasses?0:1]} position={gym.coords} clusterer={clusterer}  onClick={()=>markerClicked(gym)}/>
                    <Marker cla key={createKey(gym)} icon={gymMarkers[createKey(gym)===selectedMarker?2:gym.sellsPasses?0:1]} position={{lat:gym.lat,lng:gym.lng}} clusterer={clusterer}  onClick={()=>markerClicked(this,gym)}/>

                ))
                
            }
            
            </MarkerClusterer> */}

            <button>Test</button>
            
            
            </GoogleMap>


            {(boundsChanged && !fromProfile) && <div className='searchThisAreaContainer'>
                     <div className='searchThisArea' onClick={searchAreaClicked}>Search this area</div>
            </div>}

            <div className='mapZoomBtns'>
                <div className='mapZoomBtn' onClick={zoomIn}>
                    <Add className='mapZoomIcon'/>
                </div>
                <div className='mapZoomBtn' onClick={zoomOut}>
                    <Remove className='mapZoomIcon'/>
                </div>
            </div>
            
            <MapLegend />
        </div>
        
    )
}
    