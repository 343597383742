import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        businessFormTitle:"Your first step to joining our journey",
        businessFormSubTitle:"Enter your details below so we can start preparing a listing for you and contact you before our platform goes live",
        businessDetails: "Business Details",
        searchForBusiness: "Search for your Business :",
        searchExample:"(eg. Global Fitness, 1 Main Street, USA)",
        dontWorry: "Do not worry if you cannot find your business with the search, you can continue to enter details below.",
        businessName: "Business name *",
        fullName: "Full name",
        businessAddress: "Business address *",
        addressPlaceholder:"Street and number",
        website:"Website",
        http:"http://",
        country:"Country *",
        countryPlaceholder:"e.g. USA",
        personalDetails:"Personal Details",
        name:"Name *",
        namePlaceholder:"Name Surname",
        name:"Job position",
        positionPlaceholder:"e.g. Fitness trainer",
        email:"Email *",
        emailPlaceholder:"example@gmail.com",
        emailError:"Error: Invalid email",
        phone:"Phone",
        phonePlaceholder:"+353123456789",
        phoneError:"Name *",
        phoneError:"Error: Invalid phone number",
        name:"Name *",
        tootlip:"You are only able to submit, ",
        tootlip2:"if you have filled out all the ",
        tootlip3:"mandatory fields.",
        submitForm:"Submit form",
        awesome:"Awesome!",
        submittedText:"Thanks for submitting your info. We are looking \nforward to sending you more news soon"

    },
    
    de:{
        businessFormTitle:"Der erste Schritt auf unserer Reise",
        businessFormSubTitle:"Geben Sie Ihre Daten an damit wir ein Angebot für Sie vorbereiten können und um Sie zu kontaktieren, bevor die Plattform online geht",
        businessDetails: "Geschäftsdetails",
        searchForBusiness: "Geben Sie den Name Ihres Fitnessstudios für die Suche an :",
        searchExample:"(eg. Global Fitness, 1 Main Street, USA)",
        dontWorry: "Wenn Sie Ihr Unternehmen in der Suche nicht finden können, können Sie die Details weiter unten im Formular angeben ",
        businessName: "Name des Fitnessstudios *",
        fullName: "Name",
        businessAddress: "Adresse *",
        addressPlaceholder:"Ort, Strasse und Hausnummer",
        website:"Website",
        http:"http://",
        country:"Land *",
        countryPlaceholder:"e.g. USA",
        personalDetails:"Persönliche Details",
        name:"Name *",
        namePlaceholder:"Vorname Nachname",
        name:"Beruf",
        positionPlaceholder:"e.g. Fitnesstrainer",
        email:"E-mail *",
        emailPlaceholder:"example@gmail.com",
        emailError:"Error: Ungültige E-mail",
        phone:"Telefonnummer",
        phonePlaceholder:"+353123456789",
        phoneError:"Error: Ungültige Telefonnummer",
        tootlip:"Sie können das Formular nur abschicken, ",
        tootlip2:"wenn sie alle Pflichtfelder ",
        tootlip3:"ausgefüllt haben.",
        submitForm:"Absenden",
        awesome:"Großartig!",
        submittedText:"Vielen Dank für Ihre Informationen. Wir freuen uns darauf bald neue Updates mit Ihnen zu teilen."

    },
    
    it:{
        businessFormTitle:"Your first step to joining our journey",
        businessFormSubTitle:"Enter your details below so we can start preparing a listing for you and contact you before our platform goes live",
        businessDetails: "Business Details",
        searchForBusiness: "Search for your Business :",
        searchExample:"(eg. Global Fitness, 1 Main Street, USA)",
        dontWorry: "Do not worry if you cannot find your business with the search, you can continue to enter details below.",
        businessName: "Business name *",
        fullName: "Full name",
        businessAddress: "Business address *",
        addressPlaceholder:"Street and number",
        website:"Website",
        http:"http://",
        country:"Country *",
        countryPlaceholder:"e.g. USA",
        personalDetails:"Personal Details",
        name:"Name *",
        namePlaceholder:"Name Surname",
        name:"Job position",
        positionPlaceholder:"e.g. Fitness trainer",
        email:"Email *",
        emailPlaceholder:"example@gmail.com",
        emailError:"Error: Invalid email",
        phone:"Phone",
        phonePlaceholder:"+353123456789",
        phoneError:"Error: Invalid phone number",
        name:"Name *",
        tootlip:"You are only able to submit, ",
        tootlip2:"if you have filled out all the ",
        tootlip3:"mandatory fields.",
        submitForm:"Submit form",
        awesome:"Awesome!",
        submittedText:"Thanks for submitting your info. We are looking \nforward to sending you more news soon"

    },
    
   });