import {useNavigate,useLocation} from 'react-router-dom';
import { getAuth  } from "firebase/auth";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import html2canvas from 'html2canvas'

import { Card, Chip, Skeleton } from '@mui/material';


export default function BookingFormsSkeleton() {

   
    return(
        <div className='formsScreen'>

            <div className="passOverviewHeaders">
                <Skeleton variant='text' height={27} width={176}/>
                <Skeleton variant='text' height={20} width={614}/>
            </div>

            <div className="formStatusBar">
                <Skeleton variant='rounded' height={24} width={121}/>
                <Skeleton variant='circular' height={24} width={24}/>
            </div>

            <Skeleton variant='rounded' height={555}/>

            <div className='poButtons'>
                <Skeleton variant='rounded' height={36} width={96}/>
                <Skeleton variant='rounded' height={36} width={216}/>
            </div>


        </div>
    )
}