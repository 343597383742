

import { Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BackBar from '../../components/BackBar';
import axios from "axios";



import '../../styling/booking.css'
import { PrimaryText } from '../../components/mui/Typography';
import CompletedBooking from '../../components/bookingScreen/CompletedBooking';



export default function MyPasses({question,number,returnAnswer}) {

    const [passes,setPasses] = useState(JSON.parse(localStorage.getItem("passes")) || [])
    const [stateNum,setStateNum] = useState(0)

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user"))
        loadUsersPassesFromDB(user.uid)

    },[])


    const showInactivePasses = () => {
        let passArray = []
        passes.forEach(pass => {
            if(pass.state === "Complete")
            {
                passArray.push(<CompletedBooking booking={pass} activated={false}/>)
            }
        });

        return passArray
    }

    const showActivePasses = () => {
        let passArray = []
        passes.forEach(pass => {
            if(pass.state === "Activated")
            {
                passArray.push(<CompletedBooking booking={pass} activated={true}/>)
            }
        });

        return passArray
    }


    
  const loadUsersPassesFromDB = (uid) => {

    return new Promise(async (resolve, reject) => {

    axios.get(baseURL+'getUsersBookings',{
        params:{
            uid:uid
        }
    }).then((response) => {
        let bookings = response.data.bookings
        setPasses(bookings)
        setStateNum(stateNum+1)
        console.log("USER PASSES", bookings)
        localStorage.setItem("passes",JSON.stringify(bookings))
        resolve()
    })

  })

  }
    


    return(

        <div className="myPassesScreen">
            <BackBar />
            
            <div className='fullScreen'>
                <div className='container'>

                    <div className='myPassesTitles'>
                        <PrimaryText variant="h3">MY PASSES</PrimaryText>
                        <PrimaryText sx={{maxWidth:600}} variant='subtitle1'>View all you gym passes and activate the ones that still need additional steps to be valid</PrimaryText>
                    </div>

                    <div className='inactivePasses'>
                        
                        <PrimaryText variant='h4'>Inactive passes</PrimaryText>
                        <div className='myPassesColumn'>
                            {showInactivePasses()}
                        </div>

                    </div>

                    
                    <div className='activePasses'>
                        
                        <div className='activePassesTitles'>
                            <PrimaryText variant='h4'>Active passes</PrimaryText>
                            <PrimaryText variant='body2'>You are all set and can use these passes on your selected dates.</PrimaryText>
                        </div>
                        
                        <div className='myPassesColumn'>
                            {showActivePasses()}
                        </div>

                    </div>
                    

                </div>
            </div>
        </div>
    )
}