import { getAuth,multiFactor ,PhoneMultiFactorGenerator,createUserWithEmailAndPassword,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut,PhoneAuthProvider } from "firebase/auth";
import React, { useEffect, useState,useRef } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { PrimaryText, SecondaryText } from "../components/mui/Typography";
import '../styling/login.css'
import { PurpleOutlineButton } from "../components/mui/Buttons";
import { TextInput } from "../components/mui/Inputs";

export default function VerifyPhoneNumber({phoneNumber,referralCode,twoFactor}) {


    const input1 = React.createRef()
    const input2 = React.createRef()
    const input3 = React.createRef()
    const input4 = React.createRef()
    const input5 = React.createRef()
    const input6 = React.createRef()

    const focusBttonClicekd = () => {
        console.log("FOCUSINFG")
        input2.current.focus();
    }

    const skipClicked = () => {
        console.log("SKIPPING")
        successfulAuth()
    }


    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";


    const [showCodeInput,setShowCodeInput] = useState(false)
    const [showSuccess,setShowSuccess] = useState(false)

    const [text1,setText1] = useState("")
    const [text2,setText2] = useState("")
    const [text3,setText3] = useState("")
    const [text4,setText4] = useState("")
    const [text5,setText5] = useState("")
    const [text6,setText6] = useState("")

    const [verificationID,setVerificationID] = useState('')

    const location = useLocation();
    const navigate = useNavigate();

    // const phoneNumber = '+353851862439'//location.state.phone//'+353851862439'
    // const referralCode = 'refer2'//location.state.referral
    // const twoFactor = true//location.state.twoFactor

    const auth = getAuth();
    var uid
    // const uid = auth.currentUser.uid;

    useEffect( () => {
        if(auth.currentUser !==null){
            uid = auth.currentUser.uid;
            console.log("UID",uid)
            sendMessage()
        } else{
            console.log("AUTH",auth)
        };
    },[auth]);
    
    useEffect( () => {

        if(text1 !== "" && text2 !== "" && text3 !== "" && text4 !== "" && text5 !== "" && text6 !== "")
        {
            let verificationCode = text1+text2+text3+text4+text5+text6

            verifyCode(verificationCode)
        }

    },[text1,text2,text3,text4,text5,text6]);
    

    const successfulAuth = () => {
        if(location.state === null)
        {
            navigate("../", {   });
        }
        else{
            let gym = location.state.gym
            let pass = location.state.pass
            createBooking(gym,pass)
        }

    }

    const createBooking = (gym,pass) => {

        const auth = getAuth();
        const uid = auth.currentUser.uid;

        axios.get(baseURL+'createBooking',{
        
            params:{
                uid:uid,
                gym:gym.id,
                pass:pass.id
            }

        }).then((response) => {
            // setLoading(false)
            let resVar = response.data
            console.log("Booking Response", resVar)
            let bookingID = resVar.id
            navigate("../booking/"+bookingID, {   state:{gym:gym,pass:pass}});
        })
    }



    const sendMessage = () => {

        try{

        

        // const auth = getAuth();
        let cu = auth.currentUser

        console.log("Sendinf messgae",phoneNumber,cu)

        var recaptchaVerifier = new RecaptchaVerifier(
            'captcha',{},auth);
        multiFactor(cu).getSession()
            .then(function (multiFactorSession) {
        const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession
          };

          
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
                // verificationId will be needed to complete enrollment.
                console.log("VID", verificationId)
                setVerificationID(verificationId)
                setShowCodeInput(true)
                
            });
        })

        }catch(err){
            console.log("CAUGH ERROR",err)
            console.log("ERROR MESAGE",err.message)
        }
    }

    const verifyCode = (codeText) => {
        
            // const auth = getAuth();
        
            let verificationCode = codeText//"123456"
            const cred = PhoneAuthProvider.credential(verificationID, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
            
            let cu = auth.currentUser



            multiFactor(cu).enroll(multiFactorAssertion, "MFA name").then(function(assert){
                console.log("SUCCESSFULLy VERIFIED")
                successfullyVerified()
            })
            .catch((error) => {
                // An error happened.
                const errorMessage = error.message;
                console.log(" V ERROR",verificationCode, errorMessage)
            });
        
    }

    const successfullyVerified = () => {
        setShowSuccess(true)
        phoneNumberVerified()
        if(referralCode!=="" && referralCode!==undefined)
        {
            addReferralCodeToAccount()
        }

        successfulAuth()
    }

    const phoneNumberVerified = () => {
        axios.get(baseURL+'phoneNumberVerified',{
        
            params:{
                uid:auth.currentUser.uid,
            }

        }).then((response) => {
            let resVar = response.data
            console.log("Response phone", resVar)
        })
    }

    const addReferralCodeToAccount = () => {

        axios.get(baseURL+'addReferralCodeToAccount',{
        
            params:{
                uid:auth.currentUser.uid,
                referral:referralCode,
                twoFactor:twoFactor
            }

        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
        })
    }

    const updateText1 = (value) => {
        if(value!==""){
            if(value.length>1){
                if(text1 === value.charAt(1)){setText1(value.charAt(0))}
                else{setText1(value.charAt(1))}
            }
            else{setText1(value)}
            input2.current.focus()
        }else{setText1("")}
    }

    const updateText2 = (value) => {
        if(value!==""){
            if(value.length>1){
                if(text2 === value.charAt(1)){setText2(value.charAt(0))}
                else{setText2(value.charAt(1))}
            }
            else{setText2(value)}
            input3.current.focus()
        }else{
            setText2("")
            input1.current.focus()
         }
    }

    const updateText3 = (value) => {
        if(value!==""){
            if(value.length>1){
                if(text3 === value.charAt(1)){setText3(value.charAt(0))}
                else{setText3(value.charAt(1))}
            }
            else{setText3(value)}
            input4.current.focus()
        }else{
            setText3("")
            input2.current.focus()
         }
    }
    
    const updateText4 = (value) => {
        if(value!==""){
            if(value.length>1){
                if(text4 === value.charAt(1)){setText4(value.charAt(0))}
                else{setText4(value.charAt(1))}
            }
            else{setText4(value)}
            input5.current.focus()
        }else{
            setText4("")
            input3.current.focus()
         }
    }
    
    const updateText5 = (value) => {
        if(value!==""){
            if(value.length>1){
                if(text5 === value.charAt(1)){setText5(value.charAt(0))}
                else{setText5(value.charAt(1))}
            }
            else{setText5(value)}
            input6.current.focus()
        }else{
            setText5("")
            input4.current.focus()
         }
    }
    
    const updateText6 = (value) => {
        if(value!==""){
            if(value.length>1){
                if(text6 === value.charAt(1)){setText6(value.charAt(0))}
                else{setText6(value.charAt(1))}
            }
            else{setText6(value)}
        }else{
            setText6("")
            input5.current.focus()
         }
    }

    return(
        <div className="phoneVerifyPopUp">
            
            {!showSuccess && <div className="verifyPopUpContent">

                <div className="loginHeaders">
                    <PrimaryText variant="h4">Verify your phone number</PrimaryText>
                    <PrimaryText variant="body2">Enter the verification code that was sent to your phone</PrimaryText>
                </div>
                
                {!showCodeInput && <div id='captcha'></div>}
                {showCodeInput && <div className="verifyCodeInputRow">
                    <TextInput inputRef={input1} type="number" className="verifyCodeInput" variant="outlined"
                    value={text1} onChange={(e) => updateText1(e.target.value)}/>

                    <TextInput inputRef={input2} type="number" className="verifyCodeInput" variant="outlined"
                    value={text2} onChange={(e) => updateText2(e.target.value)}/>

                    <TextInput inputRef={input3} type="number" className="verifyCodeInput" variant="outlined"
                    value={text3} onChange={(e) => updateText3(e.target.value)}/>

                    <TextInput inputRef={input4} type="number" className="verifyCodeInput" variant="outlined"
                    value={text4} onChange={(e) => updateText4(e.target.value)}/>

                    <TextInput inputRef={input5} type="number" className="verifyCodeInput" variant="outlined"
                    value={text5} onChange={(e) => updateText5(e.target.value)}/>

                    <TextInput inputRef={input6} type="number" className="verifyCodeInput" variant="outlined"
                    value={text6} onChange={(e) => updateText6(e.target.value)}/>
                    
                    
                    </div>}

                <div className="loginSubmitSection">
                    <PurpleOutlineButton variant="outlined" onClick={skipClicked}>SKIP FOR NOW</PurpleOutlineButton>
                    <SecondaryText>If you skip, your referral code won't be activated</SecondaryText>
                </div>

            </div>}

            {showSuccess && <div className="verifyPopUpContent">

                <div className="loginHeaders">
                    <PrimaryText variant="h4">Your verification was successful</PrimaryText>
                    <PrimaryText variant="body2">Enter the verification code that was sent to your phone</PrimaryText>
                </div>
                <div className="verifySuccessContent">
                    <img className="verifySuccessImg" src="/assets/celebration.png"/>
                </div>
            </div>}

        </div>
    )
}