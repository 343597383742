
import AutoComplete from "react-google-autocomplete"
import axios from "axios";
import React, { useEffect, useState } from 'react';
import '../styling/businessForm.css'
import '../styling/constants.css'
// import "semantic-ui-css/semantic.min.css";
import ReactTooltip from "react-tooltip";
import strings from '../strings/businessForm'
import { useNavigate } from "react-router-dom";

// import { Button, Popup } from "semantic-ui-react";

export default function BusinessForm() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [nameText,setNameText] = useState('')
    const [personText,setPersonText] = useState('')
    const [emailText,setEmailText] = useState('')
    const [addressText,setAddressText] = useState('')
    const [countryText,setCountryText] = useState('')

    const [positionText,setPositionText] = useState('')
    const [phoneText,setPhoneText] = useState('')
    const [websiteText,setWebsiteText] = useState('')

    const [placeID,setPlaceID] = useState('')
    const [lat,setLat] = useState(0)
    const [lon,setLon] = useState(0)
    const [openHours,setOpenHours] = useState(null)
    
    
    const [submitEnabled,setSubmitEnabled] = useState(false)
    const [emailError,setEmailError] = useState(false)
    const [phoneError,setPhoneError] = useState(false)
    const [tooltip, showTooltip] = useState(true);
    const [showPopUp, setShowPopUp] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

            if(nameText == "" || addressText == "" || countryText == "" || personText == "" || emailText == "")
            {
                setSubmitEnabled(false)
            }else{
                setSubmitEnabled(true) 
            }


      
        },[nameText,addressText,countryText,personText,emailText,phoneText])
      

    const goHome = () => {
        navigate("../", {   });
    }
    

    const sendDataToDB = () =>{
        
        setShowPopUp(true)


        axios.post(baseURL+'addBusinessToWaitingList',{
            
            name:nameText,
            email:emailText,
            address:addressText,
            country:countryText,
            phone:phoneText,
            website:websiteText,
            contact:personText,
            position:positionText,
            placeID:placeID,
            lat:lat,
            lon:lon,
            openHours:openHours

        }).then( (response) => {
            setNameText('')
            setPersonText('')
            setPositionText('')

            setEmailText('')
            setPhoneText('')
            setEmailText('')
            setAddressText('')
            setCountryText('')

            setPlaceID('')
            setLat('')
            setLon('')
            setOpenHours('')

        })
    }


    const submitClicked = () => {

        var error = false;
        setEmailError(false)
        setPhoneError(false)

        if(!validateEmail(emailText))
        {
            error = true;
            setEmailError(true)
            console.log("Not email")
        }

        if(phoneText.length<8 && phoneText!="")
        {
            error = true;
            setPhoneError(true)
            console.log("Phone Error")
        }
    

        if(!error){
            sendDataToDB()    
        }
    }

    
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const findCountry = (addressComponents) => {

        console.log("ADDRESS",addressComponents)

        for(const item of addressComponents)
        {
            if(item.types)
            {
                for(const type of item.types)
                {
                    if(type === "country")
                    {
                        setCountryText(item.long_name)
                    }
                }
            }
            
        }

    }


    const getPlaceDetails = (place) => {

        console.log("PLACE ",place)

        findCountry(place.address_components)

        axios.get(baseURL+'getGooglePlaceDetails',{
            
            params:{
                placeID: place.place_id,
            }

        
        }).then( (response) => {
        
            const data = response.data;
            let result = data.result

            console.log("Response", data.result)

            setNameText(result.name)
            setAddressText(result.formatted_address)
            setPhoneText(result.formatted_phone_number)
            setWebsiteText(data.result.website)
           
            // setLatText(data.result.geometry.location.lat)
            // setLonText(data.result.geometry.location.lng)
            setPlaceID(place.place_id)
            setLat(data.result.geometry.location.lat)
            setLon(data.result.geometry.location.lng)
            setOpenHours(data.result.opening_hours)
            
            
        });

    }


    return(
            
        <div className="outerDuve">

            <div className="matchParent mainFormDiv flexColumn">

            
            <div className="formHeader">
            <div className="formHeader relative"> 

                <div className='matchParent absolute'>
                    <img className='headerBkgImg' src='assets/headerbkg.png' ></img>
                    {/* <div className='matchParent absolute tint'/> */}
                </div>

                <div className="matchParent absolute">

                    

                    <div className="headerText">
                        <span className="font48 fontBold">{strings.businessFormTitle}</span>
                        <span className="font18 fontRegular">{strings.businessFormSubTitle}</span>
                    </div>

                    <div className="logoDiv"><img className='logoImg' src='assets/clearlogo.png' onClick={goHome}></img></div>
                </div>

                
                
            </div></div>
            

            <div className="formInputs fontBlack fontRegular font15">

                <div className="font26 fontBold titleLeft">{strings.businessDetails}</div>


                <div className="formRowSearch"> 
                    <div className="formColumn">
                        <span className="alignLeft font18">{strings.searchForBusiness}</span>
                        <span className="alignLeft fontGrey">{strings.searchExample}</span>
                        
                    </div>

                    <AutoComplete
                        className="mapsInput"
                        placeholder="Start typing here"
                            apiKey={"AIzaSyAH_HmdzvswIPwiY2XpM_xHxvdHQW1Qcgs"}
                            onPlaceSelected={(place) => getPlaceDetails(place)}
                            options={{
                                types: ['gym']
                            }}
                    />
        

                </div>
                <span className="alignLeft marginTop20 ">{strings.dontWorry}</span>

                <div className="formRow marginTop50"> 
                <div className="formColumn"> 
                    <span className="alignLeft">{strings.businessName}</span>
                    <input className='smallText formInput' type="text" placeholder={strings.fullName} value={nameText} onChange={(e) => setNameText(e.target.value)}/>
                </div>

                <div className="formColumn"> 
                    <span className="alignLeft">{strings.businessAddress}</span>
                    <input className='smallText formInput' type="text" placeholder={strings.addressPlaceholder} value={addressText} onChange={(e) => setAddressText(e.target.value)}/>
                </div>

                
                </div>

                <div className="formRow"> 

                <div className="formColumn"> 
                    <span className="alignLeft">{strings.website}</span>
                    <input className='smallText formInput' type="text" placeholder={strings.http} value={websiteText} onChange={(e) => setWebsiteText(e.target.value)}/>
                </div>
                
                <div className="formColumn"> 
                    <span className="alignLeft">{strings.country}</span>
                    <input className='smallText formInput' type="text" placeholder={strings.countryPlaceholder} value={countryText} onChange={(e) => setCountryText(e.target.value)}/>
                </div>
                </div>



                <div className="font26 fontBold titleLeft marginTop50">{strings.personalDetails}</div>



                <div className="formRow"> 
                <div className="formColumn"> 

                <span className="alignLeft">{strings.name}</span>
                <input className='smallText formInput' type="text" placeholder={strings.namePlaceholder} value={personText} onChange={(e) => setPersonText(e.target.value)}/>
                </div>

                <div className="formColumn"> 
                    <span className="alignLeft">{strings.jobPosition}</span>
                    <input className='smallText formInput' type="text" placeholder={strings.positionPlaceholder} value={positionText} onChange={(e) => setPositionText(e.target.value)}/>
                </div>

                
                </div>


                <div className="formRow"> 
                <div className="formColumn"> 
                    <span className="alignLeft">{strings.email}</span>
                    <input className={`smallText formInput ${emailError?"errorInput":""}`} type="email" placeholder={strings.emailPlaceholder} value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                    <div className="formError alignLeft">{emailError && <span >{strings.emailError}</span>}</div>
                </div>

                <div className="formColumn"> 
                    <span className="alignLeft">{strings.phone}</span>
                    <input className={`smallText formInput ${phoneError?"errorInput":""}`} type="text" placeholder={strings.phonePlaceholder} value={phoneText} onChange={(e) => setPhoneText(e.target.value)}/>
                    <div className="formError alignLeft">{phoneError && <span >{strings.phoneError}</span>}</div>
                    
                </div>

                
                </div>

                <div className="formSubmitDiv matchParent">
                    
                <button 
                    className={`formSubmitBtn ${submitEnabled?"":"disabled"}`}
                    onClick={submitClicked} data-tip data-for="registerTip"
                    onMouseEnter={() => showTooltip(true)}
                    onMouseLeave={() => {
                        showTooltip(false);
                        setTimeout(() => showTooltip(true), 50);
                    }}
                    >{strings.submitForm}</button>

                
                {(tooltip && !submitEnabled) && <ReactTooltip id="registerTip" place="top" effect="solid" multiline="true" offset="{'right':50}" backgroundColor="white" borderColor="black" textColor="black" border="true">
                    <div>{strings.tootlip}</div>
                    <div>{strings.tootlip2}</div>
                    <div>{strings.tootlip3}</div>
                </ReactTooltip>}
                </div>
                
            </div>
                
                
                

            </div>

                
            {showPopUp && <div className="matchParent popUpDiv flexColumn centreJC centreAI autoOverflow">

                <div className="submittedPopUp flexColumn centreJC centreAI gap50">
                    <img className='celebrationImg' src='assets/celebration.png'></img>
                    <div className="flexColumn gap20">
                        <span className="font40 fontBold" >{strings.awesome}</span>
                        <span className="font26 fontRegular">{strings.submittedText}</span>
                    </div>
                </div>
            </div>}

            </div>
        
    )

}