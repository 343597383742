import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en:{
        getUpdates:"Get updates",
        form:"Sign up your gym",
        enterEmail:"Enter your email address",
        signUp: "Sign up to find out when our product is ready to launch",
        complete: "complete",
        smallDesc:"Making fitness facilites more accessible as you travel",
        weAre: "We are ",
        warmingUp: "warming up"
    },
    fr:{
        getUpdates:"Obtenir les mises à jour",
        enterEmail:"Entrez votre adresse email",
        signUp: "Inscrivez-vous pour savoir quand notre produit est prêt à être lancé",
        complete: "achevé",
        smallDesc:"Rendre les installations de remise en forme plus accessibles lors de vos déplacements",
        weAre: "On s'",
        warmingUp: "échauffe"
    },
    de:{
        getUpdates:"Obtenir les mises à jour",
        enterEmail:"Entrez votre adresse email",
        signUp: "Inscrivez-vous pour savoir quand notre produit est prêt à être lancé",
        complete: "achevé",
        smallDesc:"Rendre les installations de remise en forme plus accessibles lors de vos déplacements",
        weAre: "On s'",
        warmingUp: "échauffe"
    },
    it:{
        getUpdates:"Obtenir les mises à jour",
        enterEmail:"Entrez votre adresse email",
        signUp: "Inscrivez-vous pour savoir quand notre produit est prêt à être lancé",
        complete: "achevé",
        smallDesc:"Rendre les installations de remise en forme plus accessibles lors de vos déplacements",
        weAre: "On s'",
        warmingUp: "échauffe"
    },

    
   });