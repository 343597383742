
import AutoComplete from "react-google-autocomplete"
import axios from "axios";
import '../../styling/test.css'

import React from 'react'
import ReactDOM from 'react-dom'
import SignatureCanvas from 'react-signature-canvas'

import { getRemoteConfig,getValue,fetchAndActivate  } from "firebase/remote-config";
import html2canvas from 'html2canvas'
import { GreenButton, PurpleButton } from "../../components/mui/Buttons";


export default function ProtectedTest() {



    var signPad = null;

    // var c = document.getElementById('signingTest');
    // var t = c.getContext('2d');
    
    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    let rcDefaults = require('../../strings/RemoteConfig/remote_config_defaults.json');

    const remoteConfig = getRemoteConfig()
    remoteConfig.defaultConfig = rcDefaults;

    const val = getValue(remoteConfig, "test2");
    console.log("RVAL", val)


    fetchAndActivate(remoteConfig)
    .then(() => {
        console.log("FEtch sucees",remoteConfig)

        const val2 = getValue(remoteConfig, "test2");
        console.log("RVAL2", val2)
    })
    .catch((err) => {
        console.log("FEtch errero",err)
    });



    const getPlaceDetails = (placeID) => {


        axios.get(baseURL+'getPlaceDetails',{
            
            params:{
                placeID: placeID,
            }

        
        }).then( (response) => {
        
            const data = response.data;
            
            console.log("Response", data.result)
            
            
            
        });

    }

    const checkSigningPad = async() => {
        

        if(signPad.isEmpty())
        {
            console.log("No signature")
        }
        else{
            let signature = signPad.getTrimmedCanvas().toDataURL('image/png')
            console.log("SIG 1",signature)

            // window.open('', document.getElementById('signingTest').toDataURL());
            
            let div = document.getElementById('signingTest')
            
            const canvas = await html2canvas(div);
            const signature2 = canvas.toDataURL("image/png", 1.0);

            // let signature2 = div.toDataURL('image/png')
            window.open('', canvas.toDataURL("image/png", 1.0));

            console.log("SIG 2",signature2)
        }
        
    }

    const clearSigningPad = () => {
        signPad.clear()
    }

    
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    const success = (pos) => {
        var crd = pos.coords;
    
        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);
    }
    
    const errors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }


    const checkLocation = () => {
        if (navigator.geolocation) {
            navigator.permissions
              .query({ name: "geolocation" })
              .then(function (result) {
                if (result.state === "granted") {
                  console.log(result.state);
                  navigator.geolocation.getCurrentPosition(success, errors, options);
                  //If granted then you can directly call your function here
                } else if (result.state === "prompt") {
                  console.log(result.state);
                  navigator.geolocation.getCurrentPosition(success, errors, options);
                //   console.log("location",location)
                } else if (result.state === "denied") {
                  //If denied then you have to show instructions to enable location
                  console.log(result.state);
                }
                result.onchange = function () {
                  console.log(result.state);
                };
              });
          } else {
            alert("Sorry Not available!");
          }
    }

    return(
        <div>
            {/* <div className="test1" onClick={checkSigningPad}>You are protected my friend</div>
            <div className="test2">You are protected my friend</div>

           

            <div className="outerdiv" >
                <div className="test1">You are protected my friend</div>
                <div className="test2">You are protected my friend</div>
            </div> */}

            <div>--</div>

            <PurpleButton variant="contained" size="large" disabled={false}>Hello</PurpleButton>
            <GreenButton>World</GreenButton>


            <div  id="signingTest" className="signingTest">
                <div className="terms">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras accumsan sapien sed dolor ultrices, sit amet vehicula nisi lacinia. Curabitur accumsan mauris ut velit semper ultricies. Curabitur volutpat consequat ligula vel vulputate. Curabitur nibh neque, sodales at iaculis ac, tincidunt et tellus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec nisi risus, finibus in lorem quis, sollicitudin pellentesque augue. Aliquam erat volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                        Fusce pulvinar eros eu nisi pellentesque euismod. Nunc a tincidunt quam, nec mattis ligula. Quisque eleifend, est id tristique venenatis, elit urna pellentesque dolor, vestibulum viverra velit leo tristique sapien. Quisque id lacus eros. Nam et quam nulla. Nunc convallis nulla tempus nisi dapibus, dapibus hendrerit mi imperdiet. Nunc in massa quis justo volutpat consectetur. Sed efficitur eros nisi, vel blandit sapien laoreet vel.
                                        Nunc suscipit mollis sem, eget posuere tellus. Nunc ac elit ac nisl elementum dapibus eu ut risus. Vivamus tempus aliquam nulla vitae cursus. Curabitur mi enim, pharetra et accumsan a, ultricies eu turpis. Maecenas condimentum auctor lorem non egestas. Praesent lobortis molestie est, varius facilisis felis pulvinar a. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus aliquam, lacus ac tincidunt accumsan, purus tortor laoreet lectus, eget accumsan erat nibh sed dui.
                                        Aliquam finibus arcu vitae massa mattis molestie. Nullam ipsum nibh, fermentum in venenatis eget, mollis quis nunc. In sed bibendum eros. Fusce pharetra imperdiet augue eu placerat. Pellentesque sapien dui, euismod non mi at, cursus vestibulum lorem. Aliquam eleifend ornare leo ac convallis. Proin lacus libero, luctus quis pharetra vitae, porttitor semper tellus.
                                        Curabitur non erat a eros mattis interdum eu in turpis. Morbi eu pellentesque odio. Vivamus eget interdum nulla. Nunc in malesuada eros, a dictum lacus. Curabitur mollis, lectus in consequat ullamcorper, erat nibh scelerisque urna, et venenatis ex felis non ipsum. Donec in mi mauris. Ut egestas congue sagittis. Nunc tempus velit risus, feugiat dictum ligula blandit eget. Pellentesque aliquam arcu arcu, in efficitur massa euismod vel. Quisque vestibulum a tellus sed consequat. Integer venenatis vehicula sapien eget facilisis. Integer tincidunt sit amet nibh sagittis dictum. Pellentesque tempor nunc eu sodales fringilla.</div>
                
                <div className="signingSection">
                    <SignatureCanvas penColor='black'
                    ref={(ref) => { signPad = ref }}
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                </div>
                <button className="signatureClear" onClick={clearSigningPad}>Clear</button>
                <button className="signatureSave" onClick={checkSigningPad}>Save</button>
                
            </div >


        </div>
    )

}