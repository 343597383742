import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { getAuth, signOut  } from "firebase/auth";
import axios from "axios";
import { POCard } from '../mui/Displays';
import { GreenText, PrimaryText, SecondaryText } from '../mui/Typography';
import { PurpleButton, PurpleOutlineButton } from '../mui/Buttons';
import Moment from 'moment';
import { CalendarToday } from '@mui/icons-material';


export default function CompletedBooking({booking,activated, activatingScreen,readyScreen}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    // const navigate = useNavigate();
    // const auth = getAuth();
    // const uid = auth.currentUser.uid;
    const navigate = useNavigate();

    const [pdfUrl, setPdfUrl] = useState(null);


    useEffect( () => {

        if(activated)
        {
            axios.post(baseURL+'pdfTest',{
            
                booking:booking
    
            },{
                responseType: 'blob',
            }).then((response) => {
                if(response.data !== "IGNORE")
                {
                    const file = new Blob([response.data], {
                        type: "application/pdf",
                      });
                  
                    const fileURL = URL.createObjectURL(file);
                    setPdfUrl(fileURL);
                    

                    console.log("PDF RESPONSE",response.data)
                    console.log("PDF URL",fileURL)
                    // setPdfUrl(URL.createObjectURL(response.data));
                }
                
                
            })
    
        }
        
    }, []);

    const viewAllPasses = () => {
        navigate("../myPasses", {  });
    }

    const activateClicked = () => {

        navigate("../activateBooking/"+booking.id, {   state:{booking:booking}});
    }

    
    const openPDF = () => {
        
        window.open(pdfUrl);

    }

    const getDateString = () => {

        let date = new Date(booking.userDetails.date)
        
        
        let dateStr = Moment(date).format('MMM DD, YYYY')

        return dateStr
    }

    
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
    
    return(
        <div className='completedBooking'>
            
            <div className='poSelectedPassRow'>

                    <POCard className='poCompleted'>
                        <div className='poSelectedGym'>
                            <img className='poGymImg' src={booking.gym.images[0]}/>
                            <div className='poGymColumn'>
                                <div className='poGymSubColumn'>
                                    <PrimaryText variant='h4'>{booking.gym.name}</PrimaryText>
                                    <SecondaryText variant='body2'>{booking.gym.address}</SecondaryText>
                                </div>
                                <div className={`activatedBubble ${activated?"green":""}`}>
                                    <div className={`activatedCircle ${activated?"green":""}`} />
                                    <span>{activated?"ACTIVATED":"NOT ACTIVATED"}</span>
                                </div>
                            </div>
                        </div>                    
                    </POCard>

                    <div className='poSubCardRow'>
                        <POCard className='poSubCard'>
                            <div className='pcSelectedPass'>
                                {(activated) && <PrimaryText variant='body1'>1 x {booking.bookingOptionDetails.name}</PrimaryText>}
                                {(!activated) && <SecondaryText variant='body1'>1 x {booking.bookingOptionDetails.name}</SecondaryText>}
                                <div className='poPassSubColumn'>
                                    {(activated) && <PrimaryText variant='h4'>€{(booking.bookingOptionDetails.price/100).toFixed(2)}</PrimaryText>}
                                    {(!activated) && <SecondaryText variant='h4'>€{(booking.bookingOptionDetails.price/100).toFixed(2)}</SecondaryText>}
                                    <SecondaryText variant='body2'>Total cost</SecondaryText>
                                </div>
                            </div>                    
                        </POCard>

                        <POCard className='poSubCard'>
                            <div className='pcSelectedPass'>
                                <div className='pcPassSubColumn'>
                                    <CalendarToday className={`pcCalendarImg ${activated?"activatedCalendar":""}`} />
                                    {(activated)&&<PrimaryText variant='body1'>Chosen Date</PrimaryText>}
                                    {(!activated)&&<SecondaryText variant='body1'>Chosen Date</SecondaryText>}
                                </div>
                                {(activated)&&<GreenText variant='h4'>{getDateString()}</GreenText>}
                                {(!activated)&&<SecondaryText variant='h4'>{getDateString()}</SecondaryText>}
                            </div>                    
                        </POCard>
                    </div>
                </div>
                {(!activated && !activatingScreen) && <PurpleButton variant="contained" size="medium" onClick={activateClicked}>ACTIVATE PASS</PurpleButton>}
                {activated && <div className='passDownloadButtons'>
                    <PurpleOutlineButton className ="pdfBtn" variant='outlined' size='medium' onClick={openPDF} disabled={pdfUrl===null}>DOWNLOAD PDF</PurpleOutlineButton>
                    
                    {readyScreen && <PrimaryText variant='caption' className='viewAllPasses mobile' onClick={viewAllPasses}>View all passes</PrimaryText>}
                    <img className='walletPassBtn' src='/assets/addToGoogleWallet.png' onClick={()=>openInNewTab(booking.pass.googleWalletLink)}/>
                    <img className='walletPassBtn' src='/assets/addToAppleWallet.png' onClick={()=>openInNewTab(booking.pass.appleWalletLink)}/>
                    {readyScreen && <PrimaryText variant='caption' className='viewAllPasses' onClick={viewAllPasses}>View all passes</PrimaryText>}
                    </div>}
        </div>
    )


}