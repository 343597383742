import {useNavigate,useLocation,useParams} from 'react-router-dom';
import { getAuth} from "firebase/auth";
import axios from "axios";
import BackBar from '../components/BackBar';
import '../styling/gymProfile.css'
import { PrimaryText, PrimaryTextRight, SecondaryText } from '../components/mui/Typography';
import { PurpleButton, GreenButton2 } from '../components/mui/Buttons';
import React, { useEffect, useState } from 'react';
import MapPopUp from '../components/discover/Map';
import {Helmet} from "react-helmet";

export default function GymProfile({gymP,accommodation}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const location = useLocation();
    const navigate = useNavigate();
    let { gymID } = useParams();

    const [gym,setGym] = useState(null)
    const [openingHours,setOpeningHours] = useState(undefined)
    const [gyms,setGyms] = useState([])
    const [showMap,setShowMap] = useState(false)
    const [enteredAccommodation,setEnteredAccommodation] = useState(null)
    const [appliedFilters,setAppliedFilters] = useState({
        "Sell passes online":[],
        "Pass availability":[],
        "Budget":[],
        "Type of gym":[],
        "Amenities":[],
        "Equipment":[],
    })

    let socialMedia =  ["https://www.facebook.com","https://:www.instagram.com","https//www.twitter.com","https//www.linkedin.com"]
    let equipment = ["Dumb bells","Spin bikes", "Yoga mats","Kettel bells"]

    const [budgetOptions,setBudgetOptions] = useState([{name:"€0 - €40",value:{bottom: 0, top: 30}}])
    const [updateIndex,setUpdateIndex] = useState(0)
    const [loadingGym,setLoading] = useState(false)


    useEffect( () => {
        
        if(gym!==null)
        {
            let options = getCurrencyFilters(gym.currency)
            console.log("OPTIONS",options)
            setBudgetOptions(options)
            setUpdateIndex(updateIndex+1)    
        }
        
        
    }, [gym]);

    
    const getCurrencyFilters = (currencyP) => {

        console.log("CURPS",currencyP)
        

        switch(currencyP){

            case 'eur':return [{name:'€0 - €30', value:{bottom:0,top:30}},{name:'€30 - €60',value:{bottom:30,top:60}},{name:'€60 - €100',value:{bottom:60,top:100}},{name:'+€100',value:{bottom:100,top:1000}}];
            case 'EUR': return [{name:'€0 - €30', value:{bottom:0,top:30}},{name:'€30 - €60',value:{bottom:30,top:60}},{name:'€60 - €100',value:{bottom:60,top:100}},{name:'+€100',value:{bottom:100,top:1000}}];
            case 'CHF': return [{name:'CHF0 - CHF30', value:{bottom:0,top:30}},{name:'CHF30 - CHF60',value:{bottom:30,top:60}},{name:'CHF60 - CHF100',value:{bottom:60,top:100}},{name:'+CHF100',value:{bottom:100,top:1000}}];
            case 'USD': return [{name:'$0 - $30', value:{bottom:0,top:30}},{name:'$30 - $60',value:{bottom:30,top:60}},{name:'$60 - $100',value:{bottom:60,top:100}},{name:'+$100',value:{bottom:100,top:1000}}];
            case 'CAD': return [{name:'$0 - $30', value:{bottom:0,top:30}},{name:'$30 - $60',value:{bottom:30,top:60}},{name:'$60 - $100',value:{bottom:60,top:100}},{name:'+$100',value:{bottom:100,top:1000}}];
            case 'GBP': return [{name:'£0 - £30', value:{bottom:0,top:30}},{name:'£30 - £60',value:{bottom:30,top:60}},{name:'£60 - £100',value:{bottom:60,top:100}},{name:'+£100',value:{bottom:100,top:1000}}];
            case 'SEK': return [{name:'0kr - 300kr', value:{bottom:0,top:300}},{name:'300kr - 600kr',value:{bottom:300,top:600}},{name:'600kr - 1000kr',value:{bottom:600,top:1000}},{name:'+1000kr',value:{bottom:1000,top:10000}}];
            case 'HUF': return [{name:'0Ft - 5000Ft', value:{bottom:0,top:5000}},{name:'5000Ft - 10000Ft',value:{bottom:5000,top:10000}},{name:'10000Ft - 15000Ft',value:{bottom:10000,top:15000}},{name:'+15000Ft',value:{bottom:15000,top:150000}}];
            default:
                return [{name:'€0 - €30', value:{bottom:0,top:30}},{name:'€30 - €60',value:{bottom:30,top:60}},{name:'€60 - €100',value:{bottom:60,top:100}},{name:'+€100',value:{bottom:100,top:1000}}];
        }
        

    }

    useEffect( () => {

        setShowMap(false)
        
        if(location.state!==null)
        {
            let gym = location.state.gym
            console.log("GYM 2",gym)

            setGym(gym)
        

        


            if(location.state.allGyms !== undefined)
            {
                console.log("ALL GYMS",location.state.allGyms)
                setGyms(location.state.allGyms)
            }

            if(location.state.accommodation !== undefined && location.state.accommodation !== null)
            {
                let acco = JSON.parse(location.state.accommodation)
                if(acco !== null)
                {
                    acco.hasCoords = true
                    console.log("PORFILE ACCO",acco)
                    setEnteredAccommodation(acco)
                }
            }
        }
        else{
            loadGymFromID(gymID)
        }

    }, [gymID]);


    const loadGymFromID = (gymID) => {

        setLoading(true)
        

        axios.get(baseURL+'loadGymByID',{
        
            params:{
                gymID:gymID,
            }

        }).then((response) => {
            setLoading(false)
            let resVar = response.data
            let gym = resVar.gym
            
            setGym(gym)
            setGyms([gym])
            setOpeningHours(gym.openingHours)
        })
    }

    

    const loadOpeningHoursFromDB = (gymID) => {

        

        axios.get(baseURL+'loadGymsOpeningHours',{
        
            params:{
                gymID:gymID,
            }

        }).then((response) => {
            // setLoading(false)
            let resVar = response.data
            let openHours = resVar.openHours
            setOpeningHours(openHours)
        })
    }


    const createBooking = (pass) => {

        const auth = getAuth();
        const uid = auth.currentUser.uid;

        axios.get(baseURL+'createBooking',{
        
            params:{
                uid:uid,
                gymID:gym.id,
                bookingOption:pass.id
            }

        }).then((response) => {
            // setLoading(false)
            let resVar = response.data
            console.log("Booking Response", resVar)
            let bookingID = resVar.id
            let booking = createBookingObj(bookingID,uid,pass)
            navigate("../booking/"+bookingID, {   state:{gym:gym,pass:pass,booking:booking}});
        })
    }


    const createBookingObj = (id,uid,pass) => {
        let booking = {
                id:id,
                uid:uid,
                gym:gym,
                state:'Incomplete',
                bookingOptionDetails:pass
        }

        return booking
    }

    const checkIfSignedIn = () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if(user){return true}
        else{return false}
    }



    const passSelected = (pass) => {

        let signedIn = checkIfSignedIn()
        
        if(signedIn){
            createBooking(pass)
        }
        else{
            navigate("../login", {  state:{gym:gym,pass:pass}});
        }
        
    }

    const showPasses = () =>{

        var passList = []
        var index = 0

        gym.passes.forEach(pass => {
            let thisIndex = index
            passList.push(<div onClick={()=>passSelected(thisIndex)}>{pass.name}</div>)
            index++
        });

        return passList

    }

    const getPasses = () =>{

        var passList = []
        gym.passes.forEach(pass => {
            passList.push(<div className="gymProfilePassOption"> 
                <div className="gymProfilePassOptionTitles">
                    <PrimaryText variant="body2">{pass.name}</PrimaryText>
                    <SecondaryText variant="caption">{pass.days} {pass.days>1?"Days":"Day"}</SecondaryText>
                </div>
                <PurpleButton className='gymPassBtn' variant="contained" size="medium" onClick={()=>passSelected(pass)} disabled={!gym.sellsPasses}>{getPassPriceStr(pass)}</PurpleButton>
            </div>)
            
        });

        return passList

    }

    const getPassPriceStr = (pass) => {

        let price = (pass.price/100).toFixed(2)
        let symbol = getCurrencySymbol(gym.currency)

        var priceStr = symbol+price
        if(gym.currency==="CHF"){priceStr = symbol+" "+price}

        return priceStr
    }

    const getCurrencySymbol = (currencyCode) => {
        switch(currencyCode)
        {
            case 'eur': return '€'
            case 'EUR': return '€'
            case 'USD':return '$'
            case 'CAD':return '$'
            case 'AUD':return '$'
            case 'GBP':return '£'

            case 'CZK':return 'Kč'
            case 'DKK':return 'kr.'
            case 'HUF':return 'Ft.'
            case 'NOK':return 'kr.'
            case 'SEK':return 'kr.'
            case 'CHF':return 'CHF'
            case 'TRY':return '₺'

        }

    }

    const getSocialMedia = () => {

        let socialMediaArray = []

        let socialMedia = gym.socialMedia
    
            
        if(socialMedia.facebook!==""){
            socialMediaArray.push(<img className="gymProfileSocialIcon" src="/assets/facebookProfile.png"  onClick={()=>openInNewTab(socialMedia.facebook)}/>)
        }
        if(socialMedia.twitter!==""){
            socialMediaArray.push(<img className="gymProfileSocialIcon" src="/assets/twitterProfile.png"  onClick={()=>openInNewTab(socialMedia.twitter)}/>)
        }
        if(socialMedia.instagram!==""){
            socialMediaArray.push(<img className="gymProfileSocialIcon" src="/assets/instagramProfile.png"  onClick={()=>openInNewTab(socialMedia.instagram)}/>)
        }
        if(socialMedia.linkedin!==""){
            socialMediaArray.push(<img className="gymProfileSocialIcon" src="/assets/linkedinProfile.png"  onClick={()=>openInNewTab(socialMedia.linkedin)}/>)
        }
        
        return socialMediaArray
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    const getDistantString = () => {

        let lat1 = gym.lat
        let lon1 = gym.lng

        let lat2 = enteredAccommodation.hasCoords?enteredAccommodation.geometry.location.lat:enteredAccommodation.geometry.location.lat()
        let lon2 = enteredAccommodation.hasCoords?enteredAccommodation.geometry.location.lng:enteredAccommodation.geometry.location.lng()

        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            
            return dist.toFixed(1) +"km";
        }

        return ""
    }

    const getGymRequirements = () => {
        
        if(gym.generalRequirements.length>0)
        {
            let reqArray = []
            gym.generalRequirements.forEach(requirement => {
                reqArray.push(<SecondaryText variant="body2">• {requirement}</SecondaryText>)
            });
            return reqArray
        }
        else{
            return <SecondaryText variant="body2">Nothing added</SecondaryText>
        }
        
     }

    const getAmenetiesString = () => {
        
            if(gym.amenities.length>0)
            {
                return gym.amenities.join(" • ")
            }
            else{
                return "Nothing added"
            }

            
    }

    const getEquipmentString = () => {
        
        if(gym.equipment.length>0)
        {
            return gym.equipment.join(" • ")
        }
        else{
            return "Nothing added"
        }
            
    }

    const getOpeningHours = () => {
        let hoursArray = []
        
        if(openingHours !== undefined)
        {
            var index = 0 
            openingHours.forEach(day => {
                hoursArray.push(getDaysOpenHours(day,index))
                index++
            });
        }
        else{
            loadOpeningHoursFromDB(gym.id)
        }
        
        

        return hoursArray
    }

    const getDaysOpenHours =(openHours,indexP) => {
        
        let timeStr = ""
        
        if(!openHours.open){timeStr = "Closed"}
        else{
            var index = 1
            openHours.times.forEach(timeSlot => {
                timeStr += timeSlot.open//convertDateStrToTime(timeSlot.open)
                timeStr += " - "
                timeStr += timeSlot.close//convertDateStrToTime(timeSlot.close)
                if(index !== openHours.times.length){timeStr += ", "}
                else{timeStr += "  "}
                index++
            });
        }
        const dayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

        return <div className="gymProfileDayHours"> 
            <SecondaryText variant="body1">{dayNames[indexP]}: </SecondaryText>
            <PrimaryTextRight className='gymProfileDayTimes' variant="body1">{timeStr}</PrimaryTextRight>
        </div>
        
    }

    const convertDateStrToTime = (dateStr) => {

        let date = new Date(dateStr)
        var hours = date.getHours()
        if(hours<10){hours = "0"+hours}
        var minutes = date.getMinutes()
        if(minutes<10){minutes = "0"+minutes}
        return hours+":"+minutes
    }






    const closeMap = () => {
        setShowMap(false)
        document.body.style.overflow = "auto"
    }


    const mapAccommodationUpdate = (place) => {
        setEnteredAccommodation(place)
    }

    
    const openMap = () => {
        setShowMap(true)
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
    }

    
    const backClicked = () => {
        // navigate(-1)  
    }

    const setBounds = (bounds) => {
        
    }

    return(
        <div className="discoverScreen">
            
            {gym!==null && <Helmet>
                <meta charSet="utf-8" />
                <title>{gym.name}</title>
                
                <meta name="title" content={gym.name + " Short Term Passes"} />
                <meta name="description" content="View profile for this gym and learn more about what passes they sell" />
            </Helmet>}

            <BackBar clicked={backClicked}/>
            <div className="fullScreen">
                {gym!==null &&<div className="container">

                

                    <div className="gymProfileImageSection">
                        <img className="gymProfileImage" src={gym.images[0]}/>
                        <div className="gymProfileImageRow">
                            
                            {gym.images.length>1 && <div className="gymProfileImageColumn">
                                <img className="gymProfileImage" src={gym.images[1]}/>
                                {gym.images.length>2 &&<img className="gymProfileImage" src={gym.images[2]}/>}
                            </div>}
                            <div className="gymProfileImageColumn">
                                {gym.images.length>3 &&<img className="gymProfileImage" src={gym.images[3]}/>}
                                <div className="gymProfileMapBtn">
                                    <GreenButton2 variant="contained" size="medium" onClick={openMap}>SHOW ON MAP</GreenButton2>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="gymProfileSocialMediaRow">
                        {getSocialMedia()}
                    </div>
                    <div className="gymProfileMainSection">
                        <div className="gymProfileInfoSection">

                            <div className='gymProfileTitleColumn'>
                                <div className="gymProfileTitleRow">
                                    <PrimaryText variant="h3" className='desktopGPTitle' translate="no">{gym.name}</PrimaryText>
                                    <span className='mobileGPTitle' translate="no">{gym.name}</span>

                                    <div className="gymProfileTitleRating">New</div>
                                </div>
                                <SecondaryText variant='subtitle2'>{gym.facilityType}</SecondaryText>
                            </div>

                            <div className='gymTileMapRow'>
                                <span className='gymTileShowOnMap' onClick={openMap}>Show on Map</span>
                                {(enteredAccommodation!== null && enteredAccommodation!== undefined) && <div className='gymTileDistanceRow'>
                                    <img className='gymTileMarkerImg' src='/assets/gymTileMarker.png'/>
                                    <SecondaryText variant='body2'>{getDistantString()} away</SecondaryText>
                                </div>}
                            </div>

                            <PrimaryText variant="body2">{gym.address}</PrimaryText>
                            
                            <SecondaryText sx={{marginTop:"20px"}} className="gymProfileBio" variant="body2">{gym.bio}</SecondaryText>
                        </div>
                        <div className="gymProfilePassesSection">
                            
                            <div className="gymProfilePassesTitles">
                                <PrimaryText variant="caption">Pass options you can purchase</PrimaryText>
                                <div className="gymProfilePassesLine" />
                            </div>

                            <div className="gymProfilePassesGrid">
                                
                                {getPasses()}
                                
                                

                            </div>
                            {!gym.sellsPasses && <PrimaryText variant="caption">Unfortunately this gym does not currently sell their passes on our platform. <br/>Prices may vary when purchasing pass at the gym.</PrimaryText>}
                        </div>
                    </div>

                    <div className="gymProfileRequirementsSection">
                        <PrimaryText variant="subtitle1">Gym Requirements</PrimaryText>
                        <div className="gymProfileRequirementsColumn">
                            {getGymRequirements()}
                        </div>
                    </div>

                    <div className="gymProfileFacilitySection">
                        <div className="gymProfileAmenitiesSection">
                            <PrimaryText variant="subtitle1">Amenities</PrimaryText>
                            <PrimaryText variant="body2">{getAmenetiesString()}</PrimaryText>
                        </div>
                        <div className="gymProfileAmenitiesSection">
                            <PrimaryText variant="subtitle1">Equipment</PrimaryText>
                            <PrimaryText variant="body2">{getEquipmentString()}</PrimaryText>
                        </div>
                    </div>

                    <div className="gymProfileOpeningHoursSection">
                        <PrimaryText variant="subtitle1">Opening Hours</PrimaryText>
                        <div className="gymProfileOpeningHoursGrid">
                            {getOpeningHours()}
                        </div>
                    </div>
                </div>}
            </div>
            {showMap && 
                <MapPopUp close={closeMap} 
                    accommodation={enteredAccommodation} 
                    accommodationChanged={mapAccommodationUpdate} 
                    appliedFilters={appliedFilters} 
                    enteredLocation = { {lat:gym.lat,lng:gym.lng}}
                    setBounds={(bounds)=>setBounds(bounds)}
                    gyms={gyms} 
                    filteredGyms={gyms} 
                    selectedGym={gym}
                    fromProfile={true}
                    budgetOptions = {budgetOptions}
                />}
            
            {loadingGym &&<div className='bookingLoadingScreen'>
                <div className='paymentLoadingPopUp'>
                    <div className='paymentLoadingTitles'>
                        <PrimaryText variant='h4'>Loading Listing</PrimaryText>
                    </div>
                    <PrimaryText variant='subtitle1'>5...4...3...2...1...</PrimaryText>
                </div>
            </div>}
        </div>
    )
}