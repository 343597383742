import {useNavigate,useLocation} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import '../../styling/discover.css'
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { Clear } from '@mui/icons-material';


export default function GymTile({gym,clicked,accommodation,onList,optionsToggle,optionsOpened}) {

    const [mouseDown, setMouseDown] = useState(false)
    const [hoverState, setHoverState] = useState(false)

    const [showOptions, setShowOptions] = useState(false)

    let stringArray = ["Cats","Dogs","Geese"]
    let combined = stringArray.join(" • ")


    useEffect( () => {
        if(optionsToggle!==gym.id)
        {
            setShowOptions(false)
        }
    }, [optionsToggle]);

    useEffect( () => {
        if(showOptions){optionsOpened()}
    }, [showOptions]);


    const mouseLeave = () => {
        setMouseDown(false)
        setHoverState(false)
    }

    const getDistantString = () => {

        let lat1 = gym.lat
        let lon1 = gym.lng

        let lat2 = accommodation.geometry.location.lat()
        let lon2 = accommodation.geometry.location.lng()

        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            // if (unit=="K") { dist = dist * 1.609344 }
            // if (unit=="N") { dist = dist * 0.8684 }
            return dist.toFixed(1) +"km";
        }
    }

    const getAmenetiesString = () => {
        if(gym.amenities.length === 0)
        {
            return ""
        }
        else{
            return gym.amenities.join(" • ")
        }
    }

    const getPerDayString = (days) => {

        if(days === 1){return "day"}
        else if(days === 7){return "week"}
        else{return days + " days"}
    }


    useEffect(() => {
   
    },[])

    const getCurrencySymbol = (currencyCode) => {
        switch(currencyCode)
        {
            case 'eur': return '€'
            case 'EUR': return '€'
            case 'USD':return '$'
            case 'CAD':return '$'
            case 'GBP':return '£'

            case 'CZK':return 'Kč'
            case 'DKK':return 'kr.'
            case 'HUF':return 'Ft.'
            case 'NOK':return 'kr.'
            case 'SEK':return 'kr.'
            case 'CHF':return 'CHF'
            case 'TRY':return '₺'
        }

        // console.log("CC",currencyCode)
        
    }
    

    return(
        <div className='gymTile' onClick={clicked} onMouseDown={()=>setMouseDown(true)} 
        onMouseLeave={mouseLeave} onMouseEnter={()=>setHoverState(true)}>
            
            <img className={`gymTileImg ${onList?"":"mapTile"}`} src={gym.images[0]}/>
            
            <div className='gymTileRatingSection'>
                    <span className='gymTileRating'>New</span>
                    {/* <span className='gymTileAmountReviews'>{gym.ratingCount} reviews</span> */}
            </div>

            <div className={`gymTileContent ${mouseDown?"clicked":""} ${onList?"":"mapTile"} ${hoverState?"hover":""}`}>

                <div className='gymTileTitle'>
                    <PrimaryText variant='subtitle1'>{gym.name}</PrimaryText>

                    <div className='gymTileMapRow'>
                        {onList && <span className='gymTileShowOnMap'>Show on Map</span>}
                        {accommodation!== null && <div className='gymTileDistanceRow'>
                            <img className='gymTileMarkerImg' src='/assets/gymTileMarker.png'/>
                            <SecondaryText variant='body2'>{getDistantString()} away</SecondaryText>
                        </div>}
                    </div>

                </div>

                <PrimaryText variant='body2'>{getAmenetiesString()}</PrimaryText>

                <div className='gymTileOptionRow'>
                    {gym.passes.length>1 && <span className='gymTileOptionsText' onClick={()=>setShowOptions(true)}>{gym.passes.length-1} other options</span>}
                    {gym.passes.length>0 &&
                     <div className='gymTilePriceRow'>
                        <span className='gymTilePrice'>{getCurrencySymbol(gym.currency)}{(gym.passes[0].price/100).toFixed(2)}</span>
                        <span className='gymTilePerDay'>/{getPerDayString(gym.passes[0].days)}</span>
                    </div>
                    }
                </div>
            </div>

            
            {showOptions && <div className="gymTileOptionsPopUp">
                <div className='gymTileOptionsTitle'>
                    <PrimaryText variant='caption' className='gymTileOptionsComp'>Pass options</PrimaryText>
                    <Clear className='gymTileOptionsComp' onClick={()=>setShowOptions(false)}/>
                </div>
                <div className='gymTileOptionsLine' />
               <div className='gymTileOptionsContent'>
                    {gym.passes.map((pass) => (
                            <div className='gymTileOptionsRow'>
                                <SecondaryText className='gymTileOptionsComp'>{pass.name}</SecondaryText>
                                <span className='gymTileOptionPrice'>{getCurrencySymbol(gym.currency)}{(pass.price/100).toFixed(2)}</span>
                            </div>
                        ))}
                </div>

            </div>}
        
            
        </div>
    )
}