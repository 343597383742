import { getAuth,multiFactor ,PhoneMultiFactorGenerator,createUserWithEmailAndPassword,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut,PhoneAuthProvider } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { ErrorText, GreenText, PrimaryText, PurpleText, SecondaryText } from "../../components/mui/Typography";
import { PurpleButton } from "../../components/mui/Buttons";
import { AutoCompleteInput, PhoneInput, TextInput } from "../../components/mui/Inputs";
import '../../styling/profile.css'
import MuiPhoneNumber from 'material-ui-phone-number';
import { Autocomplete, Checkbox, FormControlLabel, IconButton, InputAdornment } from "@mui/material";
import { EmailOutlined, Error, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import VerifyPhoneNumber from "../verifyPhoneNumber";
import BackBar from "../../components/BackBar";

export default function UpdateLoginDetails() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    

    const [emailText,setEmailText] = useState("")

    const [passwordText,setPasswordText] = useState("")
    const [confirmPasswordText,setConfirmPasswordText] = useState("")
    
    const [successText,setSuccessText] = useState("")
    const [errorText,setErrorText] = useState("")

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    

    

    const location = useLocation();
    const navigate = useNavigate();


    const emailSubmitClicked = () => {

        setSuccessText("")
        setErrorText("")

        const auth = getAuth();
        const uid = auth.currentUser.uid;
        
        axios.post(baseURL+'updateLoginEmail',{
        
            uid:uid,

            email:emailText,

        
        }).then((response) => {

            setSuccessText("Email updated")

            let resVar = response.data
            console.log("Response", resVar)
        })

    }


    const passwordSubmitClicked = () => {

        setSuccessText("")
        setErrorText("")

        const auth = getAuth();
        const uid = auth.currentUser.uid;
        
        axios.post(baseURL+'updateLoginDetailsPassword',{
        
            uid:uid,
            password:passwordText

        
        }).then((response) => {
            setSuccessText("Password updated")
            let resVar = response.data
            console.log("Response", resVar)
        })
    }



    

    const backClicked = () => {
        navigate("../profile", {  });
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    return(
        <div className="registerScreen">
            <BackBar clicked={backClicked}/>

            
            <div className="fullScreen">
                <div className="container">
                    <div className="registerMainSection">

                        <div className="registerInputView">

                        <div className="loginHeaders">
                            <PrimaryText variant="h3">UPDATE LOGIN DETAILS</PrimaryText>
                            <SecondaryText variant="subtitle">Email and password</SecondaryText>
                        </div>


                        <div className="registerInputsSection">
                            <div className="registerInputColumn">


                            <div className="updateLoginTitle">
                                <EmailOutlined  className="updateLoginTitleIcon"/>
                                <PrimaryText variant="h4">Change email</PrimaryText>
                            </div>


                            <TextInput type="email" variant="outlined" title="Current Email"
                                value={JSON.parse(localStorage.getItem("user")).email} disabled="true"/>
                            
                            <TextInput 
                                type="email" 
                                error={emailText.length>0 && !validateEmail(emailText)}
                                helperText={(emailText.length>0 && !validateEmail(emailText))?"Invalid email":""}
                                variant="outlined" 
                                label="New Email"
                                value={emailText} onChange={(e) => setEmailText(e.target.value)}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            {(emailText.length>0 && !validateEmail(emailText)) && <Error className="inputErrorIcon"/>}
                                        </IconButton>
                                    </InputAdornment>
                                    )
                                }}
                                />

                            <div className="updateLoginSubmitSection">
                                <PurpleButton variant="contained" size="medium" onClick={emailSubmitClicked}
                                    disabled={emailText==="" || !validateEmail(emailText)}>
                                        SAVE CHANGES</PurpleButton>
                            </div>

                            {errorText!=="" &&<ErrorText variant="body">{errorText}</ErrorText>}
                            {successText!=="" &&<GreenText variant="body">{successText}</GreenText>}

                            </div>

                            <div className="updateLoginLine"/>

                            <div className="registerInputColumn">

                                <div className="updateLoginTitle">
                                    <Password  className="updateLoginTitleIcon"/>
                                    <PrimaryText variant="h4">Change password</PrimaryText>
                                </div>


                                <TextInput type={showPassword ? "text" : "password"}  variant="outlined" label="New password"
                                        error = {passwordText.length<6 && passwordText.length>0}
                                        helperText = {passwordText.length<6 && passwordText.length>0?"Password must be a minimum of 6 characters long":""}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                        value={passwordText} onChange={(e) => setPasswordText(e.target.value)}
                                    />

                                    <TextInput type={showPassword ? "text" : "password"} variant="outlined" label="Confirm password"
                                        error = {passwordText!==confirmPasswordText && confirmPasswordText.length>0}
                                        helperText = {passwordText!==confirmPasswordText  && confirmPasswordText.length>0?"Passwords do not match":""}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                        value={confirmPasswordText} onChange={(e) => setConfirmPasswordText(e.target.value)}
                                    />                            

                                        <div className="updateLoginSubmitSection">
                                        <PurpleButton variant="contained" size="medium" onClick={passwordSubmitClicked}
                                            disabled={passwordText.length<6 || confirmPasswordText.length<6 || passwordText !== confirmPasswordText}>
                                                SAVE CHANGES</PurpleButton>
                                        </div>

                                        {errorText!=="" &&<ErrorText variant="body">{errorText}</ErrorText>}
                                        {successText!=="" &&<GreenText variant="body">{successText}</GreenText>}

                                    </div>
                            
                        </div>

                        <div className="updateProfileSubmitSection">
                            
{/* 
                            <PurpleButton variant="contained" size="medium" onClick={submitClicked}
                            disabled={nameText==="" || surnameText===""  || genderText===""  || dobText===""}>
                                SAVE CHANGES</PurpleButton> */}

                        </div>

                        </div>



                    </div>
                </div>
            </div>

            

        </div>

        
    )
}