

import { ChevronLeftOutlined, ChevronRightOutlined, Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment } from '@mui/material';
import React, { useEffect, useState,useRef  } from 'react';
import { PurpleButton } from '../components/mui/Buttons';
import { TextInput } from '../components/mui/Inputs';
import axios from "axios";
import { useNavigate } from "react-router-dom";


import '../styling/home.css'
import { PrimaryText, SecondaryText } from '../components/mui/Typography';
import GymTileHome from '../components/discover/GymTileHome';
import GymTileHomeSkeleton from '../components/discover/GymTileHomeSkeleton';
import { useDraggable } from "react-use-draggable-scroll";

import ScrollContainer from 'react-indiana-drag-scroll'

// import * as pdfkit from "pdfkit"
// import blobStream from "blob-stream"


export default function HomeScreen({}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [continentText,setContinentText] = useState("Europe")
    const [countryList,setCountryList] = useState(["England","Spain","Netherlands","Germany"])
    const [selectedCountry,setSelectedCountry] = useState("Spain")


    const [locationText,setLocationText] = useState("")
    const [enteredLocation,setEnteredLocation] = useState(null)
    const [bounds,setBounds] = useState(null)

    const [place,setPlace] = useState(null)


    const [gyms,setGyms] = useState(JSON.parse(localStorage.getItem("gyms"))||[])

    const [popularGyms,setPopularGyms] = useState([])
    const [popularGymsShown,setPopularGymsShown] = useState([])
    const [mostVisitedGyms,setMostVisitedGyms] = useState([])

    const [loadingPopular,setLoadingPopular] = useState(false)
    const [loadingMostVisited,setLoadingMostVisited] = useState(false)

    const [otherOptionsToggle,setOOToggle] = useState(null)
    // const popularRef = React.createRef()
    // const mostVisitedRef = React.createRef()
    
    const navigate = useNavigate();

    const popularRef = useRef(); 
    const mostVisitedRef = useRef();

    const { events1 } = useDraggable(popularRef);
    const { events2 } = useDraggable(mostVisitedRef);
    // const PDFDocument = require('pdfkit');
    // const blobStream  = require('blob-stream');



    useEffect( () => {

        let lastLoad  = localStorage.getItem("homeFlagsLastLoad")
        let date = new Date()
        let time = date.getTime()

        // console.log("LAsT load", lastLoad)

        if(lastLoad === null || lastLoad === undefined || (+lastLoad+3600000)<time)
        {
            loadGymsFromDB(['popular'])
            loadGymsFromDB(['mostVisited'])
        }
        else{

            console.log("STILL HERE", (+lastLoad+3600000),time)

            let popular = JSON.parse(localStorage.getItem("homePopular"))
            var thisFilterGyms = popular.filter(function(gym) {
                return gym.country === selectedCountry
            })
            console.log("POPLIAR",selectedCountry,thisFilterGyms)

            setPopularGymsShown(thisFilterGyms)
            setPopularGyms(popular)
    
            let visited = JSON.parse(localStorage.getItem("homeVisited"))
            setMostVisitedGyms(visited)
        }

        
        

    }, []);

    const findGymsClicked = () => {

        console.log("LAOD GMS")

        if(enteredLocation!==null){
            
            // loadGymsFromDB(false)
            let date = new Date()
            let now = date.getTime()
            navigate("../discover", {state:{searchTime:now, place:place }});  
        }

    }
    

    
    const caolanTest = () => {
        axios.get(baseURL+'caolanTest',{
            responseType: 'blob',
            // responseType: 'text/pdf',

        }).then((response) => {
            // const file = new Blob([response.data], { type: "application/pdf" });
            // //Build a URL from the file
            // const fileURL = URL.createObjectURL(file);
            // //Open the URL on new Window
            // const pdfWindow = window.open();
            // pdfWindow.location.href = fileURL; 
            
            window.open(URL.createObjectURL(response.data));
            // let url = URL.createObjectURL(response.data)
            // console.log("URL",url)
        })


    }



    
    const loadGymsFromDB = (flags) => {

        let specificFlag = flags[0]

        if(specificFlag === "popular"){setLoadingPopular(true)}
        if(specificFlag === "mostVisited"){setLoadingMostVisited(true)}


        axios.post(baseURL+'loadGymsByFlag',{
        
            flags:flags

        }).then((response) => {
            let resVar = response.data
            console.log((resVar.gyms))
            
            
            let date = new Date()
            let time = date.getTime()
            localStorage.setItem("homeFlagsLastLoad",time)

            if(specificFlag === "popular"){
                

                var thisFilterGyms = resVar.gyms.filter(function(gym) {
                    return gym.country === selectedCountry
                })

                setPopularGymsShown(thisFilterGyms)

                setLoadingPopular(false)
                setPopularGyms(resVar.gyms)
                localStorage.setItem("homePopular",JSON.stringify(resVar.gyms))

            }
            if(specificFlag === "mostVisited"){
                setLoadingMostVisited(false)
                setMostVisitedGyms(resVar.gyms)
                localStorage.setItem("homeVisited",JSON.stringify(resVar.gyms))
            }
            
        })
    }





    
    useEffect( () => {

        const input = document.getElementById("discoverInput");

        const options = {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            types: ['(cities)'],
          };


          if(window.google !== undefined)
          {

            const autocomplete = new window.google.maps.places.Autocomplete(input, options);
            window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                console.log("PLACE",place)

                let viewport = place.geometry.viewport
                setBounds(viewport)
                setPlace(JSON.stringify(place))
                
                setLocationText(place.formatted_address)
                setEnteredLocation(place)

            })
          }

    }, [window.google]);


    useEffect( () => {

        if(popularGyms.length>0)
        {

            var country = selectedCountry
            if(selectedCountry === "United States"){country="USA"}
    
            var thisFilterGyms = popularGyms.filter(function(gym) {
                return gym.country === country
            })
            
            setPopularGymsShown(thisFilterGyms)
            setOOToggle(null)
        }


    }, [selectedCountry]);


    const continentOptions = ['Europe','North America','Asia','Oceania','South America','Africa']

    const contintentChanged = (value) => {
        
        setContinentText(value)

        switch(value){
            case 'Europe':
                setCountryList(["England","Spain","Netherlands","Germany"])
                setSelectedCountry("England")
                break;
            case 'North America':
                setCountryList(["Canada","United States","Mexico","Cuba","Jamaica"])
                setSelectedCountry("Canada")
                break;
            case 'Asia':
                setCountryList(["Thailand","Vietnam","Japan","Korea","China"])
                setSelectedCountry("Thailand")
                break;
            case 'Oceania':
                setCountryList(["Australia","New Zealand"])
                setSelectedCountry("Australia")
                break;
            case 'South America':
                setCountryList(["Brazil","Argentina","Colombia"])
                setSelectedCountry("Brazil")
                break;
            case 'Africa':
                setCountryList(["South Africa"])
                setSelectedCountry("South Africa")
                break;
        }

        setOOToggle(null)
    }

    const showLocationsList = () => {
        let locationArray = []

        var index = 0

        countryList.forEach(country => {

            if(selectedCountry === country){
                locationArray.push(<div className='popularGymsLocationRow'>
                    <span className='popularGymsLocation' >{country}</span>
                    <img className='popularGymsIcon'src='/assets/greenMarker.png'/>
                    </div>)
            }
            else{
                locationArray.push(<span className='popularGymsLocation' onClick={()=>setSelectedCountry(country)}>{country}</span>)
            } 
            index++

            if(index !== countryList.length){
                locationArray.push(<div className='popularGymsLocationListLine' />)
            }
        });

        return locationArray
    }




    const showGymTiles = (gyms) => {
        let gymArray = []

        gyms.forEach(gym => {
            gymArray.push(<GymTileHome gym={gym} clicked={(event)=>gymSelected(event,gym)}
            optionsToggle={otherOptionsToggle} optionsOpened={()=>setOOToggle(gym.id)}/>)
            
        });

        return gymArray
    }

    const showSkeletonTiles = () => {
        let gymArray = []
        
            gymArray.push(<GymTileHomeSkeleton/>)
            gymArray.push(<GymTileHomeSkeleton/>)
            gymArray.push(<GymTileHomeSkeleton/>)
            gymArray.push(<GymTileHomeSkeleton/>)        

        return gymArray
    }

    
    const gymSelected = (event,gym) => {

        let target = event.target.className
        let parent = event.target.parentElement.className
        
        
        if(target === "gymTileShowOnMap")
        {
            console.log("Show on map")
        }
        else if(target === "gymTileOptionsText")
        {
            console.log("Other options clicked")
        }
        else if(parent.includes("gymTileOption"))
        {
            console.log("Other options popup clicked")
        }
        else{
           
            navigate("../gymprofile/"+gym.id, { state:{gym:gym,allGyms:[gym]}});  
        }

    }


    
    const arrowClickedLeftPopular = () =>{
            popularRef.current.scrollLeft -= 262
    }
    
    const arrowClickedRightPopular = () =>{

        popularRef.current.scrollLeft += 262        
    }

    const arrowClickedLeftMostVisited = () =>{
        mostVisitedRef.current.scrollLeft -= 262
    }

    const arrowClickedRightMostVisited = () =>{

        mostVisitedRef.current.scrollLeft += 262        
    }




    return(

        <div className="homeScreen">
            
            <div className='topSectionHS'>
                <div className='fullScreen'>
                    <div className='container'>
                        <div className='topSectionHSContent'>
                            <img className='topSectionHSIcon' src="/assets/homeScreenIcon.png"/>
                            <span class="topSectionHSTitle">For travelers who want to keep continuing their <span class="topSectionHSTitleBlack">workout abroad</span>. </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='searchSectionHS'>
                <div className='fullScreen'>
                    <div className='container'>
                        <div className="discoverSearchRow">
                            <TextInput id="discoverInput" className="discoverInput" variant="outlined" size="medium" label={"In which city  do you want to find a gym?"} placeholder="e.g. Paris, France" 
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                            
                                        </InputAdornment>
                                        ),
                                }} 
                                value={locationText} onChange={(e) => setLocationText(e.target.value)} />
                            
                            <PurpleButton className='findGymsBtn' variant="contained" size="large" onClick={findGymsClicked} disabled={enteredLocation === null}>FIND GYMS</PurpleButton>
                        </div>
                        <div className="searchSectionHSText"><SecondaryText variant='body1'>Search thousands of gyms worldwide and find the right place for you. </SecondaryText></div>
                        {/* <div className="searchSectionHSText"><SecondaryText variant='body1'>Search + 2,000 gyms worldwide and book the right pass for you. </SecondaryText></div> */}
                    </div>
                </div>
            </div>






            <div className='mainSectionHS'>
                <div className='fullScreen'>
                    <div className='container'>
                        <div className='mainSectionHSContent'>

                            <div className='homeScreenSubSection'>
                                <div className='popularGymsHSTitle'>
                                    <PrimaryText variant='h4'>Popular gyms in</PrimaryText>
                                    <Autocomplete sx={{width:220}} disablePortal defaultValue='Europe' options={continentOptions} renderInput={(params)=><TextInput {...params} variant="outlined" value={continentText} />} onChange={(e) => contintentChanged(e.target.textContent)}/>
                                </div>
                                
                                <div className='popularGymsLocationList'>
                                    {showLocationsList()}
                                </div>
                                
                                <div className='gymSliderHome'>
                                    <div className='homeSliderArrow leftHSA' onClick={arrowClickedLeftPopular}><ChevronLeftOutlined/></div>
                                    
                                    <div className='gymTileRowHS' ref={popularRef} {...events1}>                                        
                                        {loadingPopular && showSkeletonTiles()}
                                        {!loadingPopular && showGymTiles(popularGymsShown)}
                                        {(!loadingPopular && popularGymsShown.length===0) &&
                                            <div className='noHomeTiles'>
                                              {"No gyms available in "+ selectedCountry}
                                        </div>}

                                    </div>
                                    
                                    
                                    <div className='homeSliderArrow rightHSA'onClick={arrowClickedRightPopular}><ChevronRightOutlined/></div>
                                </div>

                                
                            </div>

                            <div className='homeScreenSubSection'>
                                <PrimaryText variant='h4'>Most visited gyms</PrimaryText>
                                <div className='gymSliderHome'>
                                    <div className='homeSliderArrow leftHSA'onClick={arrowClickedLeftMostVisited}><ChevronLeftOutlined/></div>
                                    <div className='gymTileRowHS' ref={mostVisitedRef} {...events2}>                                        
                                        {loadingPopular && showSkeletonTiles()}
                                        {!loadingPopular && showGymTiles(mostVisitedGyms)}

                                    </div>
                                    <div className='homeSliderArrow rightHSA'onClick={arrowClickedRightMostVisited}><ChevronRightOutlined/></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
           
            
        </div>
    )
}