import '../styling/comingSoon.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import strings from '../strings/comingSoon'
import { useNavigate } from "react-router-dom";
import { PurpleButton } from '../components/mui/Buttons';

export default function CommingSoon() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    const [emailText,setEmailText] = useState('')
    const [submitted,setSubmitted] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {

        if(emailText!==""){setSubmitted(false)}

    },[emailText])

    const submitEmail = () => {

        if(emailText == ""){console.log("Invalid email")}
        else{
            saveEmailToDB()
            setEmailText("")
            setSubmitted(true)
        }


    }
    const saveEmailToDB = () => {

        axios.get(baseURL+'addUserEmailToWaitingList',{
            
            params:{
                email: emailText
            }

        
        }).then( (response) => {
        
            const data = response.data;
            
            console.log("Response", response,data)
            
            
        });
    }

    const goToForm = () => {
        navigate("../gym", {   });
    }

    const goHome = () => {
        navigate("../", {   });
    }

    return(
        <div className='matchParent relative'>

        <div className='matchParent relative'>
            <img className='bkgImg' src='assets/gym-bkg.jpg' ></img>
            <div className='matchParent absolute tint'/>
        </div>
        


        <div className='margin-60-120'>
            
           <div className='matchParent centreChild'>

                <div className='comingSoonCentre'>

                    
                    <div className='flexColumn gap48'>
                        <span className='titleText'>{strings.weAre} <strong className='fontPurple'>{strings.warmingUp}</strong></span>
                        <span className='subText'>{strings.smallDesc}</span>
                    </div>


                    <div className='flexColumn gap12'>
                        <progress className='progressBar' value="99" max="100"> 99% </progress>
                        <span className='mediumText'>99% {strings.complete}</span>
                        {/* <span>Je pense que tu es tres belle</span> */}
                    </div>
                    

                    {/* <div className='flexColumn gap48'>

                        <span className='subText'>{strings.signUp}</span>
                        <div className='inputRow gap12 matchParent'>
                            <div className='flexRow  emailInput'>
                                <img className='emailIV' src='assets/emailIcon.png'/>
                                <input className='matchParent emailTextEdit smallText' type="text" placeholder={strings.enterEmail} value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                            </div>
                            <button className={`getUpdatesBtn smallText ${submitted?"updatesBtnSubmitted":""}`} onClick={submitEmail}>{submitted?"Submitted":strings.getUpdates}</button>
                            <button className={`gotToFormBtn smallText`} onClick={goToForm}>{strings.form}</button>
                        </div>
                    </div> */}

                </div>
                

           </div>

           <PurpleButton className='demoBtn' variant='contained' size='large' onClick={goHome}>View Demo Site</PurpleButton>

           <div className='baseText smallText'> © 2022 Travel Fitness Pass. All rights reserved.</div>



        </div >

        </div>
        
    )

}