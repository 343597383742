import {useNavigate,useLocation,useParams} from 'react-router-dom';
import { PurpleButton } from '../mui/Buttons';
import { POCard } from '../mui/Displays';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import React, { useEffect, useState } from 'react';
import BookingForms from './BookingForms';

import axios from "axios";
import CompletedBooking from './CompletedBooking';
import BackBar from '../BackBar';
import BookingFormsSkeleton from './Skeletons/BookingFormsSkeleton';
const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";


export default function BookingActivate() {

    const [activated, setActivated] = useState(false);
    // const [showForms, setShowForms] = useState(trur);
    const [loadingForms, setLoadingForms] = useState(false);
    const [activatingPass, setActivatingPass] = useState(false)

    const [booking, setBooking] = useState(null);

    const [questions,setQuestions] = useState([])
    const [forms,setForms] = useState([])

    const location = useLocation();
    const navigate = useNavigate();
    let { bookingID } = useParams();
    

   

    const getBookingFromDB = async(uid, bookingID) => {
        
        // setLoading(true)

        axios.get(baseURL+'getBooking',{
        
            params:{
                uid:uid,
                bookingID:bookingID
            }

        }).then((response) => {
            // setLoading(false)
            let resVar = response.data
            console.log("Booking Response", resVar)

            if(resVar.booking.state === "Complete")
            {
                setBooking(resVar.booking)
            
                if(resVar.booking.gym.activationRequirements.length>0)
                {
                    getActivationRequirements(resVar.booking.gym.activationRequirements)
                }
            }
            else{
                console.log("Error State", resVar.booking.state)
            }

            
        })
    }



    useEffect(() => {

        if(booking === null)
        {
            if(location.state !== null)
            {
                setBooking(location.state.booking)
                
                console.log("BOOKING MP ACT",location.state.booking)

                if(location.state.booking.gym.activationRequirements.length>0)
                {
                    getActivationRequirements(location.state.booking.gym.activationRequirements)
                }
            }
            else{
                let user = JSON.parse(localStorage.getItem("user"))
                getBookingFromDB(user.uid,bookingID)
            }
            
        }
        
       
        
        

    },[])


    const activateBooking = () => {

        booking.state = "Activated"
        setActivated(true)
        // stepComplete(booking)
    }

    
    const getActivationRequirements = (list) => {

        setLoadingForms(true)
        axios.post(baseURL+'getActivationRequirements',{
        
            idList:list
        
        }).then((response) => {
            setLoadingForms(false)
            let resVar = response.data
            // console.log("Response", resVar)

            setQuestions(resVar.questions)
            setForms(resVar.forms)
            
        })
    }

    const activatePassFunc = (val) => {
        setActivatingPass(val)
    }


    return(
        <div className='activateBookingScreen'>
            <BackBar />
            <div className='fullScreen'>
                <div className='container'>

                    {booking !== null &&<div className='bookingPassOverview'>
                        
                        <div className="passOverviewHeaders">
                            <PrimaryText variant="h3">ACTIVATE PASS</PrimaryText>
                            <SecondaryText variant="subtitle1">Only a few steps and then you can use your pass</SecondaryText>
                        </div>


                        <div className='poSelected'>
                            <PrimaryText variant="h4">Your gym pass</PrimaryText>
                            <CompletedBooking booking={booking} activated={false} activatingScreen={true}/>
                            
                        </div>

                        
                        <div className='formsScreen'>
                            {(loadingForms) && <BookingFormsSkeleton />}
                            {(!loadingForms) && <BookingForms booking={booking} questionsP={questions} formsP={forms} confirmed={activateBooking} activatingPass={activatePassFunc}/>}
                        </div>
                        
                            
                        


                    </div>}
                    {booking === null && <span>Loading</span>}

                </div>
            </div>

            {activatingPass &&<div className='bookingLoadingScreen'>
                <div className='paymentLoadingPopUp'>
                    <div className='paymentLoadingTitles'>
                        <PrimaryText variant='h4'>One moment please</PrimaryText>
                        <PrimaryText variant='body2'>We are activating your pass.</PrimaryText>
                    </div>
                    <PrimaryText variant='subtitle1'>5...4...3...2...1...</PrimaryText>
                </div>
            </div>}
                
        </div>



    )
}