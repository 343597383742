import {useNavigate,useLocation,useHistory,useParams } from 'react-router-dom';
import GymTile from "../components/discover/GymTile";
import React, { useEffect, useState,useRef } from 'react';

import '../styling/discover.css'
import MapPopUp from "../components/discover/Map";
import GymFilter from "../components/discover/GymFilter";
import BackBar from "../components/BackBar";
import { ChevronLeft, ChevronRight, Close, Search } from "@mui/icons-material";
import { TextInput } from "../components/mui/Inputs";
import { FormControl, InputAdornment, InputLabel, Skeleton,Select,MenuItem, Box } from "@mui/material";
// import { Autocomplete } from "@react-google-maps/api";
import AutoComplete from "react-google-autocomplete"
import { GreenButton2, PurpleButton } from "../components/mui/Buttons";
import { PrimaryText, PurpleText } from "../components/mui/Typography";
import { DiscoverSortChip } from "../components/mui/Displays";
import DiscoverSort from "../components/discover/DiscoverSort";
import axios from "axios";
import GymTileSkeleton from '../components/discover/GymTileSkeleton';
import {Helmet} from "react-helmet";


import * as CITY_VALUES from '../constants/cityValues.js';
import { auth } from '..';


export default function Discover({isCity}) {

    



    const navigate = useNavigate();
    const location = useLocation();

    const [loadingGyms,setLoadingGyms] = useState(false)
    const [showMap,setShowMap] = useState(false)

    const [currentFilter,setCurrentFilter] = useState(false)

    const [appliedFilters,setAppliedFilters] = useState({
        "Sell passes online":[],
        "Pass availability":[],
        "Budget":[],
        "Type of gym":[],
        "Amenities":[],
        "Equipment":[],
    })
    const [filtersOpen,setFiltersOpen] = useState(false)
    const [filtersChecked,setFiltersChecked] = useState(0)

    const [enteredLocation,setEnteredLocation] = useState(null)
    const [locationText,setLocationText] = useState("")

    const [enteredAccommodation,setEnteredAccommodation] = useState(null)
    const [accommodationText,setAccommodationText] = useState("")

    const filterRef = React.createRef()

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [sortOption,setSortOption] = useState(0)
    const [updateIndex,setUpdateIndex] = useState(0)

    const [gyms,setGyms] = useState(null)

    
    const [showFilters,setShowFilters] = useState(false)
    const [filteredGyms,setFilteredGyms] = useState([])
    const [selectedGym,setSelectedGym] = useState([])

    const [bounds,setBounds] = useState(null)

    const [otherOptionsToggle,setOOToggle] = useState(null)

    const [currency,setCurrency] = useState('EUR')
    const [budgetOptions,setBudgetOptions] = useState([{name:"€0 - €40",value:{bottom: 0, top: 30}}])

    const sortRef = useRef(); 

    let { cityID } = useParams();
    var cityValues = null

    if(isCity)
    {
        
        let constants = CITY_VALUES.CITY_VALUES
        cityValues = constants[cityID]
    }


    
    const sortOptions = [
        { value: 0, label: 'Recommended' },
        { value: 1, label: 'Lowest price' },
        // { value: 2, label: 'Best rated' },
        { value: 3, label: 'Closest to accommodation' },
    ];
    
    useEffect( () => {
        if(selectedOption !== null)
        {
            sortGyms(selectedOption.value)
        }
        console.log("SLEECTED OPTION",selectedOption)
        
    }, [selectedOption]);

    useEffect( () => {
        
        let options = getCurrencyFilters(currency)
        console.log("OPTIONS",options)
        setBudgetOptions(options)
        setUpdateIndex(updateIndex+1)
        
    }, [currency]);



    const gymSelected = (event,gym,acco) => {

        let target = event.target.className
        let parent = event.target.parentElement.className
        
        
        if(target === "gymTileShowOnMap")
        {
            console.log("Show on map")
            setSelectedGym(gym)
            setShowMap(true)
            window.scrollTo(0, 0)
            document.body.style.overflow = "hidden"
        }
        else if(target === "gymTileOptionsText")
        {
            console.log("Other options clicked")
        }
        else if(parent.includes("gymTileOption"))
        {
            console.log("Other options popup clicked")
        }
        else{
           
            let accoJSON = JSON.stringify(enteredAccommodation)
            navigate("../gymprofile/"+gym.id, {   state:{gym:gym,allGyms:gyms,accommodation:accoJSON}});  
        }

    }

    


    const loadGymsFromDB = (fromMap,searchTime,boundsP) => {

        setLoadingGyms(true)

       
        

        var boundsVar = bounds
        if(boundsP !== undefined){
            console.log("BOUNDS P",boundsP)
            boundsVar = boundsP
        }
       
        if(fromMap)
        {
            setLocationText("Map Area")

            let enteredLocation = {
                geometry:{location:{
                    lat:((boundsVar.north+boundsVar.south)/2),
                    lng:((boundsVar.east+boundsVar.west)/2)
                }}
            }

            // enteredLocation.geometry.location.lat = ((boundsVar.north+boundsVar.south)/2)
            // enteredLocation.geometry.location.lng = ((boundsVar.east+boundsVar.west)/2)
            setEnteredLocation(enteredLocation)
        }
        
        let user = auth.currentUser
         var uid = ""
         if(user !== null && user !== undefined)
         {
             uid = user.uid
         }

        let params = {
            latLow:boundsVar.south,
            latHigh:boundsVar.north,
            lngLow:boundsVar.west,
            lngHigh:boundsVar.east,
            searchThisArea:fromMap,
            uid:uid
        }
        

        axios.get(baseURL+'loadGymsInCity',{
        
            params:params

        }).then((response) => {
            let resVar = response.data
            localStorage.setItem("gyms",JSON.stringify(resVar.gyms))
            localStorage.setItem("lastSearchTime",searchTime)
            if(enteredLocation !== null){
                localStorage.setItem("place",JSON.stringify(enteredLocation))
            }
            
            if(resVar.gyms.length>0)
            {
                let cur = resVar.gyms[0].currency
                setCurrency(cur)
            }

            setGyms(resVar.gyms)
            setFilteredGyms(resVar.gyms)
            setOOToggle(null)
            setLoadingGyms(false)
            console.log("Response Mapzz", resVar)
        })
    }


    

    const loadGymsFromCity = (city,country) => {

        setLoadingGyms(true)

       let date = new Date()
       let searchTime = date.getTime()

        
        let params = {
            city:city,
            country:country
        }
        

        axios.get(baseURL+'loadGymsBySpecificCity',{
        
            params:params

        }).then((response) => {
            let resVar = response.data
            localStorage.setItem("gyms",JSON.stringify(resVar.gyms))
            localStorage.setItem("lastSearchTime",searchTime)
            if(enteredLocation !== null){
                localStorage.setItem("place",JSON.stringify(enteredLocation))
            }
            
            if(resVar.gyms.length>0)
            {
                let cur = resVar.gyms[0].currency
                setCurrency(cur)
            }

            setGyms(resVar.gyms)
            setFilteredGyms(resVar.gyms)
            setOOToggle(null)
            setLoadingGyms(false)
            console.log("Response Mapzz", resVar)
        })
    }


    

    if(gyms===null && !loadingGyms)
    {


        console.log("STATE DIST",location.state)

        var parameterTime = 0
        var storageTime = 0

        if(localStorage.getItem("lastSearchTime") !== null){
            storageTime = +localStorage.getItem("lastSearchTime")
        }
        if(location.state !== null)
        {
            parameterTime = location.state.searchTime
        }

        console.log("TIMES",parameterTime,storageTime)

        if(parameterTime>storageTime || parameterTime===0)
        {
            
            
   
            if(isCity && locationText!=="Map Area")
            {
                loadGymsFromCity(cityValues.name,cityValues.country)   
            }
            else{

                let place = JSON.parse(location.state.place)
                console.log("PLACE DIS", place)
                localStorage.setItem("place",JSON.stringify(place))
                
                let viewport = place.geometry.viewport
                setBounds(viewport)
                setLocationText(place.formatted_address)
                setEnteredLocation(place)

                loadGymsFromDB(false,location.state.searchTime,viewport)   
            }

            
        }
        else
        {
            let gymsLS = localStorage.getItem("gyms")
            console.log("LS PLACE",localStorage.getItem("place"))
            let placeLS = JSON.parse(localStorage.getItem("place"))

            let viewport = placeLS.geometry.viewport
            setBounds(viewport)
            setLocationText(placeLS.formatted_address)
            setEnteredLocation(placeLS)

            setGyms(JSON.parse(gymsLS))
            setFilteredGyms(JSON.parse(gymsLS))
            setOOToggle(null)
        }
        
    }

    

    // if(gyms.length===0){loadGymsFromDB()}

    const showGyms = (acco) => {

        var gymList = []
        var index = 0

        
        filteredGyms.forEach(gym => {
            let thisIndex = index
            
            gymList.push(<GymTile clicked={(event)=>gymSelected(event,gym,acco)} gym={gym} 
                            onList={true} accommodation={enteredAccommodation} 
                            optionsToggle={otherOptionsToggle} optionsOpened={()=>setOOToggle(gym.id)}/>)
            // gymList.push(<GymTile clicked={()=>gymSelected(thisIndex)} gym={gym}/>)
            // gymList.push(<GymTile clicked={()=>gymSelected(thisIndex)} gym={gym}/>)
            index++
        });

        

        return gymList
    }

    
    const showGymSkeletons = () => {

        var gymList = []
        
    
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            
        gymList.push(<GymTileSkeleton />)            

        

        return gymList
    }


    const openMap = () => {
        console.log("ENTERELED LOCATION",enteredLocation)
        setShowMap(true)
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
    }
    
    const closeMap = () => {
        setSelectedGym(null)
        setShowMap(false)
        document.body.style.overflow = "auto"
    }

    const googleAutoComplete = (text) => 
    {
        setAccommodationText(text)
        
    }

    const filterClicked = (open) => {
        setFiltersOpen(open)
    }

    const filterChange = (num,filters,newFilteredGyms) => {
        setFiltersChecked(filtersChecked+num)
        setAppliedFilters(filters)
        console.log("NEW FILTERED GYMS",newFilteredGyms)
        setFilteredGyms(newFilteredGyms)
        setOOToggle(null)
        console.log("APPLIED FILTERS",filters)
    }

    const clearFilters = () => {
        setFiltersChecked(0)
        setAppliedFilters({
            "Sell passes online":[],
            "Pass availability":[],
            "Budget":[],
            "Type of gym":[],
            "Amenities":[],
            "Equipment":[],
        })
        setFilteredGyms(gyms)
        setOOToggle(null)
    }

    useEffect( () => {

        
        if(window.google !== undefined)
        {

            const input = document.getElementById("discoverInput");
            console.log("INPUT",input)

            const options = {
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false,
                types: ['(cities)'],
            };

            const autocomplete = new window.google.maps.places.Autocomplete(input, options);
            window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();

                let placeJSONStr = JSON.stringify(place)
                let placeJSON = JSON.parse(placeJSONStr)

                console.log("PLACE",placeJSON)


                let viewport = placeJSON.geometry.viewport
                setBounds(viewport)
                setLocationText(placeJSON.formatted_address)
                setEnteredLocation(placeJSON)

            })


            const input2 = document.getElementById("accommodationSearch");

            const options2 = {
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false,
                types: ['lodging','street_address'],
            };

            const autocomplete2 = new window.google.maps.places.Autocomplete(input2, options2);
            window.google.maps.event.addListener(autocomplete2, 'place_changed', function () {
                var place = autocomplete2.getPlace();
                console.log("ACCOMMODATION PLACE",place)
                setAccommodationText(place.formatted_address)
                setEnteredAccommodation(place)

            })
        }
    }, [window.google]);

    useEffect( () => {
        
        if(sortOption===3){sortGyms(sortOption)}
        
    }, [enteredAccommodation,sortOption]);




    const mapAccommodationUpdate = (place) => {
        setAccommodationText(place.formatted_address)
        setEnteredAccommodation(place)
    }


    const arrowClickedLeft = () =>{

        if(currentFilter >0){
            filterRef.current.scrollLeft -= 262
            setCurrentFilter(currentFilter-1)
        }

        
    }
    
    const arrowClickedRight = () =>{

        if(currentFilter <2){
            filterRef.current.scrollLeft += 262
            setCurrentFilter(currentFilter+1)
        }
        
    }

    const getDistanceFromAcc = (gym) =>{


        let lat1 = gym.lat
        let lon1 = gym.lng

        let lat2 = enteredAccommodation.geometry.location.lat()
        let lon2 = enteredAccommodation.geometry.location.lng()

        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
            // if (unit=="K") { dist = dist * 1.609344 }
            // if (unit=="N") { dist = dist * 0.8684 }
            return dist;
        }
    }
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }
    

    const sortGyms = (option) => {

        console.log("SORTING GYM",option)
        setSortOption(option)

        if(option === 0){

        }
        else if(option === 1){
            filteredGyms.sort((a, b) => parseFloat(a.passes[0].price) - parseFloat(b.passes[0].price));
        }
        else if(option === 2){
            filteredGyms.sort((a, b) => parseFloat(b.rating) - parseFloat(a.rating));
        }
        else{
            filteredGyms.sort((a, b) => parseFloat(getDistanceFromAcc(a)) - parseFloat(getDistanceFromAcc(b)));
        }

        setFilteredGyms(filteredGyms)
        setOOToggle(null)
        setUpdateIndex(updateIndex+1)


    }

    const findGymsClicked = () => {

        console.log("LAOD GMS")

        if(enteredLocation!==null){
            let date = new Date()
            let now = date.getTime()
            
            loadGymsFromDB(false,now)
        }
    }
    

    const mapGymSearch = () => {

        let date = new Date()
        let now = date.getTime()
        
        
        loadGymsFromDB(true,now)
    }

    const backClicked = () => {
        // navigate(-1)  
    }

    const sortClicked = () => {
        setShowOptions(true)
    }

    // useEffect( () => {
    //     if(showOptions){sortRef.current.focus()}
    // }, [showOptions]);

    const optionsFocused = () => {
        let dropdown = document.getElementById('sortOptions')
        dropdown.size = 4
    }

    const optionsUnfocused = () => {
        setShowOptions(false)
        let dropdown = document.getElementById('sortOptions')
        dropdown.size = 0
    }

    const getCurrencyFilters = (currencyP) => {

        console.log("CURPS",currencyP)
        

        switch(currencyP){

            case 'eur':return [{name:'€0 - €30', value:{bottom:0,top:30}},{name:'€30 - €60',value:{bottom:30,top:60}},{name:'€60 - €100',value:{bottom:60,top:100}},{name:'+€100',value:{bottom:100,top:1000}}];
            case 'EUR': return [{name:'€0 - €30', value:{bottom:0,top:30}},{name:'€30 - €60',value:{bottom:30,top:60}},{name:'€60 - €100',value:{bottom:60,top:100}},{name:'+€100',value:{bottom:100,top:1000}}];
            case 'CHF': return [{name:'CHF 0 - CHF 30', value:{bottom:0,top:30}},{name:'CHF 30 - CHF 60',value:{bottom:30,top:60}},{name:'CHF 60 - CHF 100',value:{bottom:60,top:100}},{name:'+CHF 100',value:{bottom:100,top:1000}}];
            case 'USD': return [{name:'$0 - $30', value:{bottom:0,top:30}},{name:'$30 - $60',value:{bottom:30,top:60}},{name:'$60 - $100',value:{bottom:60,top:100}},{name:'+$100',value:{bottom:100,top:1000}}];
            case 'GBP': return [{name:'£0 - £30', value:{bottom:0,top:30}},{name:'£30 - £60',value:{bottom:30,top:60}},{name:'£60 - £100',value:{bottom:60,top:100}},{name:'+£100',value:{bottom:100,top:1000}}];
            case 'SEK': return [{name:'0kr - 300kr', value:{bottom:0,top:300}},{name:'300kr - 600kr',value:{bottom:300,top:600}},{name:'600kr - 1000kr',value:{bottom:600,top:1000}},{name:'+1000kr',value:{bottom:1000,top:10000}}];
            case 'HUF': return [{name:'0Ft - 5000Ft', value:{bottom:0,top:5000}},{name:'5000Ft - 10000Ft',value:{bottom:5000,top:10000}},{name:'10000Ft - 15000Ft',value:{bottom:10000,top:15000}},{name:'+15000Ft',value:{bottom:15000,top:150000}}];
            default:
                return [{name:'€0 - €30', value:{bottom:0,top:30}},{name:'€30 - €60',value:{bottom:30,top:60}},{name:'€60 - €100',value:{bottom:60,top:100}},{name:'+€100',value:{bottom:100,top:1000}}];
        }
        

    }


    return(
        <div className="discoverScreen">
            
            {(isCity && locationText!=="Map Area") && <Helmet>
                <meta charSet="utf-8" />
                <title>Gyms in {cityValues.name}</title>
                
                <meta name="title" content={cityValues.name + " Short Term Gym Passes"} />
                <meta name="description" content={"Find out all the top gyms in "+cityValues.name+" who offer short term access to their facilities"} />
            </Helmet>}




            {!(isCity && locationText!=="Map Area") && <BackBar clicked={backClicked}/>}
            
            {(isCity && cityValues !== undefined && locationText!=="Map Area") && <div className='citySection'>
                <img className='cityImg' src={cityValues.img}/>
                <div className='cityTitles'>
                    <span>Top gyms in</span>
                    <span className='cityName'>{cityValues.name}</span>
                </div>
            </div>}

            {cityValues === undefined &&
                <div>Error</div>
            }

            {cityValues !== undefined &&
            <div className="fullScreen">
                <div className="container">
                    
                    
                    
                {!(isCity && locationText!=="Map Area")  && <div className="discoverSearchRow">
                        <TextInput id="discoverInput" className="discoverInput" variant="outlined" size="small" label={showMap?"":"Where do you want to find the gym?"} placeholder="e.g. Paris, France"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                        
                                    </InputAdornment>
                                    ),
                            }} 
                            value={locationText} onChange={(e) => setLocationText(e.target.value)} />

                        <PurpleButton className='findGymsBtn' variant="contained" size="medium" onClick={findGymsClicked} disabled={enteredLocation === null}>FIND GYMS</PurpleButton>
                    </div>}

                    


                    <div className={`discoverFilterSection ${showFilters?"showFilters":""}`}>
                        <div className="discoverFilterTop">
                            <div className="discoverFilterTitles">
                                <PrimaryText variant="body1">Filter by</PrimaryText>
                                <PrimaryText variant="caption">- {filtersChecked} total selected</PrimaryText>
                                {filtersChecked>0 && <PrimaryText variant="caption" className='clearFiltersBtn' onClick={clearFilters}>Clear Filters</PrimaryText>}
                            </div>
                            <div className="discoverFilterArrows">
                                <ChevronLeft className={`discoverFilterArrow ${currentFilter >0?"":"disabled"}`} onClick={arrowClickedLeft}/>
                                <ChevronRight className={`discoverFilterArrow ${currentFilter <2?"":"disabled"}`} onClick={arrowClickedRight}/>
                                
                            </div>
                            <div className='closeFilters' onClick={()=>setShowFilters(false)}><Close/></div>
                        </div>
                        
                        
                        

                        <div ref={filterRef} id="filterRow" className="discoverFilterRow">
                            <GymFilter title={'Sell passes online'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Yes',value:true},{name:'No' ,value:false}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Pass availability'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Day pass',value:1},{name:'3 days pass',value:3},{name:'Week pass' ,value:7}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Budget'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={budgetOptions} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Type of gym'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Commercial',value:'Commercial'},{name:'CrossFit',value:'CrossFit'},{name:'Sports Centre' ,value:'Sports Centre'},{name:'Boxing/Martial Arts' ,value:'Boxing/Martial Arts'},{name:'Powerlifting' ,value:'Powerlifting'},{name:'Climbing Centre' ,value:'Climbing Centre'},{name:'Yoga/Pilates Studio',value:'Yoga/Pilates Studio'}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Amenities'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Swimming pool',value:'Swimming Pool'},{name:'Showers',value:'Showers'},{name:'Lockers' ,value:'Lockers'},{name:'Boxing studio' ,value:'Boxing studio'},{name:'Yoga studio' ,value:'Yoga Studio'},{name:'Massages' ,value:'Massages'}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                            <GymFilter title={'Equipment'} checked={filterChange} openP={filtersOpen} clicked={filterClicked} filters={[{name:'Free weights',value:'Free weights'},{name:'Spin bikes',value:'Spin bikes'},{name:'Treadmills' ,value:'Treadmills'},{name:'Dumbbells' ,value:'Dumbbells'},{name:'Rowing Machines' ,value:'Rowing Machines'},{name:'Kettle Bells' ,value:'Kettle Bells'}]} gyms={gyms} filteredGymsP={filteredGyms} appliedFilters={appliedFilters}/>
                        </div>

                    </div>

                    

                    
                    <div className='mobileDiscoverRow'>
                            <span onClick={openMap}>Map</span>
                            <span onClick={()=>setShowFilters(true)}>Filter</span>
                            <span onClick={sortClicked}>Sort</span>
                    </div>
                    
                    {showOptions &&  
                    <div className='mobileSort'>
                    <Box className='mobileSortBox'>
                    <FormControl fullWidth>
                        <InputLabel id="sortLbal">Sort by</InputLabel>
                        <Select
                            labelId="sortLbal"
                            id="demo-simple-select"
                            value={sortOption}
                            label="Been Scraped"
                            onChange={(e) => setSortOption(e.target.value)}
                        >
                        <MenuItem value={0}>Recommended</MenuItem>
                        <MenuItem value={1}>Lowest price</MenuItem>
                        <MenuItem value={3} disabled={enteredAccommodation==null}>Closest to accommodation</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
                <Close onClick={()=>setShowOptions(false)}/>
                </div>
                    }


                    

                    <div className="discoverAccommodationAutoCompleteDiv">

                        <TextInput id="accommodationSearch" className="discoverAccommodationAutoComplete" variant="outlined" size="small" label="Accommodation location"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img className='accommodationInputImg'  src='/assets/accommodationMarker.png'/>
                                        </InputAdornment>
                                        ),
                                }} 
                                value={accommodationText} onChange={(e) => googleAutoComplete(e.target.value)} />

                    </div>

                    <div className="discoverSortSection">
                        <div className="discoverShowMap">
                            <GreenButton2 variant="contained" size="medium" onClick={openMap} disabled={filteredGyms.length===0}>SHOW ON MAP</GreenButton2>
                        </div>
                        
                        <DiscoverSort accommodation={enteredAccommodation===null} onChange={sortGyms}/>
                        
                    </div>
                    
                                

                    
                    

                    
                    {loadingGyms && <div className={`discoverList ${showFilters?"showFilters":""}`}>{showGymSkeletons()}</div>}
                    
                    {(!loadingGyms && filteredGyms.length>0) &&  <div className={`discoverList ${showFilters?"showFilters":""}`}>{showGyms(enteredAccommodation)}</div>}
                    
                    {(!loadingGyms && filteredGyms.length===0) &&  
                    <div className='noGymsDiscover'>
                        <img className='noGymsIcon' src='/assets/noGyms.png'/>
                        <div className='noGymsDiscoverText'>
                            <PrimaryText variant='body1'>Oh no!</PrimaryText>
                            <PrimaryText variant='body1'>There are no gyms that match your current search</PrimaryText>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
            
            {showMap && 
                <MapPopUp close={closeMap} 
                    accommodation={enteredAccommodation} 
                    accommodationChanged={mapAccommodationUpdate} 
                    gyms={gyms} 
                    enteredLocation = { (isCity && locationText!=="Map Area")?{lat:cityValues.lat,lng:cityValues.lng}:{lat:enteredLocation.geometry.location.lat,lng:enteredLocation.geometry.location.lng}}
                    filteredGyms={filteredGyms} 
                    filterChange={filterChange} 
                    appliedFilters={appliedFilters} 
                    gymSearch={mapGymSearch} 
                    setBounds={(bounds)=>setBounds(bounds)}
                    selectedGym={selectedGym}
                    budgetOptions = {budgetOptions}
                />}
        </div>
    )
}