import { Badge, Chip } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { height } from "@mui/system";
import { FormStatusChip } from "../mui/Displays";
import React, { useEffect, useState } from 'react';

export default function FormStatusBar({hasQ,hasSig,step}){

    const [currentStep, setCurrentStep] = useState(step)


    useEffect(() => {
      setCurrentStep(step)
    },[step])
      

    
    const getCorrectStatus = () => {

        var statusArray = []

        if(currentStep === 2)
        {
            statusArray.push(<CheckCircle  style={{ color: '#72717C'}}/>)
            statusArray.push(<FormStatusChip label="2. Gym Rules" variant="outlined"/>)
        }
        else{

            if(hasQ)
            {
                statusArray.push(<FormStatusChip label="1. Questionnaire" variant="outlined"/>)
                if(hasSig)
                {
                    statusArray.push(<FormStatusChip label="2." variant="outlined"/>)
                }
            }
            else{
                statusArray.push(<FormStatusChip label="1. Gym Rules" variant="outlined"/>)
            }

        }

        return statusArray

    }


    return(
        <div className="formStatusBar">
            
            {getCorrectStatus()}
        </div>
    )

}