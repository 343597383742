import {useNavigate,useLocation} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ProgressBar} from '../mui/Displays';
import { Step, StepLabel } from '@mui/material';
import '../../styling/booking.css'


export default function BookingHeaders({bookingState}) {

    const [stateNumber, setStateNumber] = useState(0)

    

    useEffect(() => {
   
   
   var stateNumber
    
    if(bookingState==="Incomplete"){stateNumber = 1}
    else if(bookingState==="Payment"){stateNumber = 2}
    else if(bookingState ==="Complete"){stateNumber = 3}
    else{stateNumber = 4}

    setStateNumber(stateNumber)
   
    },[bookingState])

    


    return(
        <div className='bookingProgressBar'>
            <ProgressBar className='bookingProgressBarComp' activeStep={stateNumber-1}> 
                <Step key={"label"}>
                    <StepLabel >{"Pass Overview"}</StepLabel>
                </Step>
                <Step key={"label2"}>
                    <StepLabel >{"Payment details"}</StepLabel>
                </Step>
                <Step key={"label3"}>
                    <StepLabel >{"Confirmation"}</StepLabel>
                </Step>
            </ProgressBar>
            
        </div>
    )
}