import '../styling/footer.css'
import { useNavigate } from "react-router-dom";


export default function Footer() {
    
    const navigate = useNavigate();

    const policyClicked = () => {
        navigate(`../privacy-policy`, {   });

    }


    return(
        <div className='footer'>
            <div className='fullScreen'>
                <div className='container'>
                    <div className='footerContent'>
                            <span> © Travel Fitness Pass Ltd</span>
                            <div className='footerBtns'>
                                {/* <span className='footerBtn'>About us</span> */}
                                <span className='footerBtn' onClick={policyClicked}>Privacy Policy</span>
                                <span className='footerBtn'>Contact</span>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
    

}