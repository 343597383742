import { getAuth,multiFactor ,PhoneMultiFactorGenerator,createUserWithEmailAndPassword,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut,PhoneAuthProvider } from "firebase/auth";
// import {app} from '../index'

import React, { useEffect, useState } from 'react';
import axios from "axios";

export default function TestPhone() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    const email = "caolangm@gmail.com"
    const password = 123456
    const phoneNumber = '00353851862439'
    // const appVerifier = window.recaptchaVerifier;
    const [codeText,setCodeText] = useState('')
    const [verificationID,setVerificationID] = useState('')

   
    const captcha1 = async() => {

        console.log("Captcha")


        const auth = getAuth();
        
        window.recaptchaVerifier  = new RecaptchaVerifier('captcha', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
              console.log("Captcha call", response)
            },
            'expired-callback': () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
              console.log("Expired call")
            }
          }, auth);


        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
          });

          return



    }

    const phoneNumberVerification1 = async () => {

        await captcha1()

        const phoneNumber = "00353851862439"
        const appVerifier = window.recaptchaVerifier;
        
        const auth = getAuth();
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              console.log("Result",confirmationResult)
              // ...
            }).catch((error) => {
                console.log("Error",error)
              // Error; SMS not sent
              // ...
            });

    }

    const confirmCode1 = () => {
        const code = codeText
        window.confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
            console.log("Success")
        // ...
        }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
            console.log("Error",error)
        });
    }


    const phoneNumberVerification2 = async () => {

        window.confirmationResult = "TRUE";

        // await captcha1()

        // const phoneNumber = "+353851862439"
        // const appVerifier = window.recaptchaVerifier;


        // axios.post(baseURL+'sendSMS',{
                
        //     phoneNumber:phoneNumber,
        //     recapchaToken:appVerifier
            

        // }).then( (response) => {
        //     console.log("SendSMS Response", response)
        // })
    }


    const captcha2 = () => {

        console.log("CATPCHAT 2")

        const auth = getAuth();

        window.recaptchaVerifier = new RecaptchaVerifier('captcha', {
            'size': 'normal',
            'callback': function (recapchaToken) {
              // reCAPTCHA solved, send recapchaToken and phone number to backend.
              
              // a REST call to your backend
              axios.post(baseURL+'sendSMS',{
                
                    phoneNumber:phoneNumber,
                    recapchaToken:recapchaToken
                    
        
                }).then( (response) => {
                    console.log("SendSMS Response", response)
                })
            }
          },auth);
          // render the rapchaVerifier. 
            window.recaptchaVerifier.render().then(function (widgetId) {
                window.recaptchaWidgetId = widgetId;
            });

            

    }




    const sendMessage = () => {

        const auth = getAuth();
        let cu = auth.currentUser

        var recaptchaVerifier = new RecaptchaVerifier(
            'captcha',{},auth);
        multiFactor(cu).getSession()
            .then(function (multiFactorSession) {
        const phoneInfoOptions = {
            phoneNumber: '+353851862439',
            session: multiFactorSession
          };

          
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
                // verificationId will be needed to complete enrollment.
                console.log("VID", verificationId)
                setVerificationID(verificationId)
                
            });
        })
    }

    const verify = () => {
        
            const auth = getAuth();
        
            let verificationCode = codeText//"123456"
            const cred = PhoneAuthProvider.credential(verificationID, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
            
            let cu = auth.currentUser



            multiFactor(cu).enroll(multiFactorAssertion, "MFA name").then(function(assert){
                console.log("SUCCESSFUL")
            })
            .catch((error) => {
                // An error happened.
                const errorMessage = error.message;
                console.log(" V ERROR",verificationCode, errorMessage)
            });
        
    }



    const option4 = () => {
        const auth = getAuth();
        var applicationVerifier = new RecaptchaVerifier(
            'captcha',{},auth);

        applicationVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        });


        var provider = new PhoneAuthProvider();
        provider.verifyPhoneNumber('+353851862439', applicationVerifier)
            .then(function(verificationId) {
            var verificationCode = window.prompt('Please enter the verification ' +
                'code that was sent to your mobile device.');
            return PhoneAuthProvider.credential(verificationId,
                verificationCode);
            })
            .then(function(phoneCredential) {
                console.log("PV",phoneCredential)
            });
    }
    

    const verifyCode = async() => {
        const auth = getAuth();
        let verificationId = "AOO2nWX8k8cIVOuHdvkJ2wYX9ehRwpStAUFTUGuvkhfzf_TLXjE7mIdHlVEaawXmvfnXClwiUkkXBLrbVeMxZQw39r0HHpg2qDZucVf_V7RuYN_zBLs710yr4QPoVVjhaPyo7qPcVSV94zueIYPzZ2MDJymSWhyk8A"
        let verificationCode = "123457"
        // const phoneAuthProvider = new PhoneAuthProvider(auth);

        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        console.log("CRED", cred)
        
        
        let cu = auth.currentUser
        console.log("CU",cu)
        // let userData = await cu.linkWithCredential(cred);
        // console.log("USER DATA", userData)
        // setUser(userData.user);

        multiFactor(cu).enroll(multiFactorAssertion, "MFA name");
    }



    const signIn = () => {
        const auth = getAuth();
        var recaptchaVerifier = new RecaptchaVerifier(
            'captcha',{},auth);

            recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
        console.log("SIGN IN CLICKED")
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("USER", user)
        })
        .catch((error) => {
            // An error happened.
            let selectedIndex = 0
            const errorMessage = error.message;
            console.log("ERROR", errorMessage)
            if (error.code == 'auth/multi-factor-auth-required') {
                const resolver = error.resolver;
                // Ask user which second factor to use.
                if (resolver.hints[selectedIndex].factorId ===
                    auth.PhoneMultiFactorGenerator.FACTOR_ID) {
                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[selectedIndex],
                        session: resolver.session
                    };
                    const phoneAuthProvider = new PhoneAuthProvider();
                    // Send SMS verification code
                    let verificationCode = "123456"
                    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                        .then(function (verificationId) {
                            // Ask user for the SMS verification code. Then:
                            const cred = PhoneAuthProvider.credential(
                                verificationId, verificationCode);
                            const multiFactorAssertion =
                                PhoneMultiFactorGenerator.assertion(cred);
                            // Complete sign-in.
                            return resolver.resolveSignIn(multiFactorAssertion)
                        })
                        .then(function (userCredential) {
                            // User successfully signed in with the second factor phone number.
                            console.log("Incredible success")
                        });
                } else {
                    // Unsupported second factor.
                }
            }
            
        });
    }

    

    return(

        <div className="flexColumn">
            
            
            <button onClick={sendMessage}>Send Message</button>
            <button onClick={verify}>Verify</button>
            <button onClick={signIn}>Confirm</button>

            <input className='smallText formInput' type="text" placeholder={"Code"} value={codeText} onChange={(e) => setCodeText(e.target.value)}/>

            <div  className="captcha">
                <span>f</span>
                
                <div id='captcha'></div>
            </div>
        </div>

    )

}