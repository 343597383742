import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { PurpleButton } from './mui/Buttons';
import { PrimaryText } from './mui/Typography';
import {useNavigate,useLocation,useParams} from 'react-router-dom';


export default function BackBar({clicked}) {

    const navigate = useNavigate();

    const backClicked = () => {
        navigate(-1)  
    }

    return(

        <div className="backBar">
            <div className='fullScreen'>
                <div className='container'>
                    <div className='backBarContent' onClick={backClicked}>
                        {/* <PurpleButton variant="contained" size="small" onClick={clicked}>Back</PurpleButton>
                             */}
                        <ArrowBack />
                        <PrimaryText variant='caption'>BACK</PrimaryText>
                    </div>
                </div>
            </div>
            
        </div>
    )
}