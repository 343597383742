import { getAuth,sendPasswordResetEmail ,PhoneMultiFactorGenerator,createUserWithEmailAndPassword,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut,PhoneAuthProvider,sendEmailVerification } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import '../../styling/login.css'
import { PurpleButton, PurpleOutlineButton } from "../../components/mui/Buttons";
import { TextInput } from "../../components/mui/Inputs";
import { AccountCircle, EmailOutlined, Password } from "@mui/icons-material";
import { InputAdornment, Typography } from "@mui/material";
import { PrimaryText, PurpleText, SecondaryText } from "../../components/mui/Typography";
import BackBar from "../../components/BackBar";

export default function DeleteAccount() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    



    const location = useLocation();
    const navigate = useNavigate();

    const backClicked = () => {
        navigate("../profile", {  });
    }

    const deleteConfirmed = () => {

        const auth = getAuth();
        const uid = auth.currentUser.uid;

        signOut(auth).then(() => {
            deleteUserFromDB(uid)
            navigate("../", {  });
          }).catch((error) => {
            console.log("Sign out error",error)
          });
    }

    const deleteAborted = () => {
        navigate("../profile", {  });
    }


    const deleteUserFromDB = (uid) => {

        axios.get(baseURL+'deleteAccount',{
        
            params:{
                uid:uid,
            }

        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
        })
    }

    return(
        <div className="loginScreen">
            <BackBar clicked={backClicked}/>

            <div className="fullScreen">
                <div className="container">
                    <div className="loginMainSection">

                        <div className="deleteAccountLeftSide">

                            <div className="deleteAccountTitles">
                                <PrimaryText variant="h3">ARE YOU SURE?</PrimaryText>
                                <SecondaryText variant="subtitle1">We are sad to see you go...</SecondaryText>
                            </div>
                            
                            <PrimaryText variant="body1">Deleting your account is permanent. Are you sure you want to continue and delete your account?</PrimaryText>

                            <div className='poButtons'>
                                <PurpleOutlineButton variant='outlined' size='medium' onClick={deleteConfirmed}>YES, DELETE</PurpleOutlineButton>
                                <PurpleButton variant="contained" size="medium" onClick={deleteAborted}>NO, I RECONSIDERED</PurpleButton>
                            </div>

                        </div>

                        <div className="loginRightSide">
                                <img className="loginScreenIcon" src="/assets/deleteAccountIcon.png"/>

                        </div>





                    </div>
                </div>
            </div>

            
            
    
            
        </div>
    )
}