import React, { useEffect, useState } from 'react';


export default function FavouriteGyms() {


    return(
        <div>
            <h1>Favourite Gyms</h1>
        </div>
    )


}