

import { Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PrimaryText, SecondaryText } from '../components/mui/Typography';
import {useNavigate,useLocation} from 'react-router-dom';



import '../styling/profile.css'



export default function Profile() {

    // const [answer,setAnswer] = useState(question.answer || "")

    const navigate = useNavigate();


    const navigateToScreen = (screen) =>
    {
        navigate("../"+screen, {   });
    }


    


    return(

        <div className="profileScreen">
            
            <div className='profileTopSection'>
                <div className='fullScreen'>
                    <div className='container'>
                        <div className='profileUserTitles'>
                            <PrimaryText variant='h4'>Hi, {JSON.parse(localStorage.getItem("user")).personalDetails.firstName}</PrimaryText>
                            <PrimaryText variant='body2'>This is your personal profile space</PrimaryText>
                        </div>
                        
                    </div>
                </div>
            </div>


            <div className='profileMainSection'>
                <div className='fullScreen'>
                    <div className='container'>
                        
                        <div className='profileContentRow'>

                            <div className='profileContentColumn'>

                                <div className='profileContentTitles'>
                                    <PrimaryText variant='h3'>ACCOUNT</PrimaryText>
                                    <SecondaryText variant='subtitle1'>{"Settings & details"}</SecondaryText>
                                </div>

                                <div className='profileTilesColumn'>

                                    <div className='profileTile' onClick={()=>navigateToScreen("updateProfile")}>
                                        <img className='profileTileImg' src='/assets/profileInfo.png'/>
                                        <div className='profileTileColumn'>
                                            <PrimaryText variant='h4'>Profile Details</PrimaryText>
                                            <SecondaryText variant='body2'>In this section you can edit your details</SecondaryText>
                                        </div>
                                    </div>

                                    <div className='profileTile' onClick={()=>navigateToScreen("updateLogin")}>
                                        <img className='profileTileImg' src='/assets/profileEnter.png'/>
                                        <div className='profileTileColumn'>
                                            <PrimaryText variant='h4'>Update login details</PrimaryText>
                                            <SecondaryText variant='body2'>Email and password</SecondaryText>
                                        </div>
                                    </div>

                                    <div className='profileTile underConstruction'>
                                        <img className='profileTileImg' src='/assets/profileCard.png'/>
                                        <div className='profileTileColumn'>
                                            <SecondaryText variant='h4'>Payment methods</SecondaryText>
                                            <SecondaryText variant='body2'>This section is in the making</SecondaryText>
                                        </div>
                                    </div>

                                    <div className='profileTile underConstruction'>
                                        <img className='profileTileImg' src='/assets/profileTools.png'/>
                                        <div className='profileTileColumn'>
                                            <SecondaryText variant='h4'>Preferences</SecondaryText>
                                            <SecondaryText variant='body2'>This section is in the making</SecondaryText>
                                        </div>
                                    </div>

                                    <div className='profileTile' onClick={()=>navigateToScreen("deleteAccount")}>
                                        <img className='profileTileImg' src='/assets/profileHeart.png'/>
                                        <div className='profileTileColumn'>
                                            <PrimaryText variant='h4'>Delete account</PrimaryText>
                                            <SecondaryText variant='body2'>Not using this account? What a pity...</SecondaryText>
                                        </div>
                                    </div>


                                </div>

                            </div>



                            <div className='profileContentColumn'>

                                <div className='profileContentTitles'>
                                    <PrimaryText variant='h3'>GYM PASSES</PrimaryText>
                                    <SecondaryText variant='subtitle1'>All passes</SecondaryText>
                                </div>

                                <div className='profileTilesColumn'>

                                    <div className='profileTile' onClick={()=>navigateToScreen("myPasses")}>
                                        <img className='profileTileImg' src='/assets/profilePass.png'/>
                                        <div className='profileTileColumn'>
                                            <PrimaryText variant='h4'>My passes</PrimaryText>
                                            <SecondaryText variant='body2'>View active and non active passes</SecondaryText>
                                        </div>
                                    </div>

                                    
                                    <div className='profileTile underConstruction'>
                                        <img className='profileTileImg' src='/assets/profileClock.png'/>
                                        <div className='profileTileColumn'>
                                            <SecondaryText variant='h4'>Pass history</SecondaryText>
                                            <SecondaryText variant='body2'>This section is in the making</SecondaryText>
                                        </div>
                                    </div>

                                    <div className='profileTile underConstruction'>
                                        <img className='profileTileImg' src='/assets/profileStar.png'/>
                                        <div className='profileTileColumn'>
                                            <SecondaryText variant='h4'>Favourite gyms</SecondaryText>
                                            <SecondaryText variant='body2'>This section is in the making</SecondaryText>
                                        </div>
                                    </div>


                                    


                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
            
            
        </div>
    )
}