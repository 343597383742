import React, { useEffect, useState } from 'react';
import logo from '../../logo.svg';
import {isMobile} from 'react-device-detect';

export default function ReactDefault() {

    return(
            <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Gym Explorer
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn to Code in React on {isMobile?"Mobile": "Desktop"}
        </a>
      </header>
        
    )

}