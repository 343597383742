import { getAuth,sendPasswordResetEmail ,PhoneMultiFactorGenerator,createUserWithEmailAndPassword,FacebookAuthProvider,GoogleAuthProvider ,signInWithPopup , RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword,signOut,PhoneAuthProvider,sendEmailVerification } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import '../styling/login.css'
import { PurpleButton } from "../components/mui/Buttons";
import { TextInput } from "../components/mui/Inputs";
import { AccountCircle, EmailOutlined, Password } from "@mui/icons-material";
import { InputAdornment, Typography } from "@mui/material";
import { ErrorText, GreenText, PrimaryText, PurpleText } from "../components/mui/Typography";
import BackBar from "../components/BackBar";

export default function Login() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    
    const [showSMS,setShowSMS] = useState(false)

    const [emailText,setEmailText] = useState("")
    const [passwordText,setPasswordText] = useState("")
    const [phoneText,setPhoneText] = useState("")
    const [codeText,setCodeText] = useState("")
    const [verificationID,setVerificationID] = useState('')

    const [forgotPassword,setForgotPassword] = useState(false)
    

    const [successText,setSuccessText] = useState("")
    const [errorText,setErrorText] = useState("")

    const location = useLocation();
    const navigate = useNavigate();

    const backClicked = () => {
        if(forgotPassword){
            setForgotPassword(false)
            setEmailText("")
        }
        
    }

    const forgotPasswordClicked = () => {
        setForgotPassword(true)
        setEmailText("")
        setPasswordText("")
        setErrorText("")
        setSuccessText("")
    }

    const showRegisterScreen = () => {
        navigate("../register", {   });
    }


    const loadUserFromDB = (uid) => {

        return new Promise(async (resolve, reject) => {

        axios.get(baseURL+'getAccountDetails',{
            params:{
                uid:uid
            }
        }).then((response) => {
            let userAccountData = response.data
            console.log("USER ACCOUNT", userAccountData)
            localStorage.setItem("user",JSON.stringify(userAccountData))
            resolve()
        })

    })

    }

    const signIn = () => {
        const auth = getAuth();

        setErrorText("")
        setSuccessText("")

        signInWithEmailAndPassword(auth,  emailText, passwordText)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("USER", user)
            successfulAuth(user.uid)
        })
        .catch((error) => {
            // An error happened.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("ERRORTT", errorCode)
            if(errorCode === "auth/user-not-found"){setErrorText("Error: No account exists with this email")}
            if(errorCode === "auth/wrong-password"){setErrorText("Error: This is the incorrect password for this account")}
        });
    }


    const successfulAuth = async(uid) => {

        // await loadUserFromDB(uid)

        if(location.state === null)
        {
            navigate("../home", {   });
            
        }
        else{
            let gym = location.state.gym
            let pass = location.state.pass
            createBooking(gym,pass)
        }

    }

    const resetPassword = () => {

        setErrorText("")
        setSuccessText("")
        console.log("RESERTIING PASSWORD")

        const auth = getAuth();
        sendPasswordResetEmail(auth, emailText)
        .then(() => {
            //setMessage("Email sent to reset password")
            setSuccessText("Email sent to reset password")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("ERROER",errorCode)
            if(errorCode === "auth/user-not-found"){setErrorText("Error: No account exists with this email")}
            
        });
    }


    
    const createBooking = (gym,pass) => {

        const auth = getAuth();
        const uid = auth.currentUser.uid;

        axios.get(baseURL+'createBooking',{
        
            params:{
                uid:uid,
                gym:gym.id,
                pass:pass.id
            }

        }).then((response) => {
            // setLoading(false)
            let resVar = response.data
            console.log("Booking Response", resVar)
            navigate("../booking", {   state:{gym:gym,pass:pass}});
        })
    }


    return(
        <div className="loginScreen">
            <BackBar clicked={backClicked}/>

            <div className="fullScreen">
                <div className="container">
                    <div className="loginMainSection">

                        <div className="loginLeftSide">

                            {!forgotPassword && <div className="loginLeftContainer">
                                <div className="loginHeaders">
                                    <PrimaryText variant="h3">LOG IN</PrimaryText>
                                    <PrimaryText variant="subtitle">Manage your passes in your account</PrimaryText>
                                </div>

                                <div className="loginInputs">
                                    <TextInput className="loginEmailInput" variant="outlined" placeholder="Email"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailOutlined />
                                        </InputAdornment>
                                        ),
                                    }} 
                                    value={emailText} onChange={(e) => setEmailText(e.target.value)}></TextInput>
                                
                                    <TextInput className="loginPasswordInput" type="password" variant="outlined" placeholder="Password"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <Password/>
                                        </InputAdornment>
                                        ),
                                    }}  
                                    value={passwordText} onChange={(e) => setPasswordText(e.target.value)}></TextInput>

                                </div>

                                <div className="loginSubmitSection">
                                    <PurpleButton variant="contained" size="medium" onClick={signIn}
                                    disabled={emailText==="" || passwordText===""}>
                                        SIGN IN</PurpleButton>
                                    <span className="loginForgotPassword" onClick={forgotPasswordClicked}>Forgot Password?</span>
                                </div>

                                <ErrorText variant="body">{errorText}</ErrorText>

                                <div className="loginRegisterSection">
                                    <PrimaryText variant="subtitle">Don't have an account yet?</PrimaryText>
                                    <PurpleText className="loginRegisterButton" variant="subtitle" onClick={showRegisterScreen}>Register here</PurpleText>
                                </div>
                            </div>
                            }








                            

                            {forgotPassword && 
                                <div className="loginLeftContainer">
                                <div className="loginHeaders">
                                    <PrimaryText variant="h3">RECOVER PASSWORD</PrimaryText>
                                    <PrimaryText variant="subtitle">Don't worry, this happens to the best of us.</PrimaryText>
                                </div>

                                <div className="loginInputs">
                                    <TextInput className="loginEmailInput" variant="outlined" placeholder="Enter email for recovery link"
                                    
                                    value={emailText} onChange={(e) => setEmailText(e.target.value)}></TextInput>
                                

                                </div>

                                <div className="loginSubmitSection">
                                    <PurpleButton variant="contained" size="medium" onClick={resetPassword}
                                    disabled={emailText===""}>
                                        SEND RECOVERY LINK</PurpleButton>
                                </div>

                                <ErrorText variant="body">{errorText}</ErrorText>
                                <GreenText variant="body">{successText}</GreenText>

                            </div>
                            }
                            

                        </div>

                        <div className="loginRightSide">
                                <img className="loginScreenIcon" src="/assets/loginScreenIcon.png"/>

                        </div>





                    </div>
                </div>
            </div>

            
            
    
            
        </div>
    )
}