import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { PurpleButton, PurpleOutlineButton } from './mui/Buttons';

const PaymentComponent = ({stripeResponse,cancelClicked, submitClicked, isSetup, savedCard}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    
        var stripeResVar

        if(isSetup)
        {

          stripeResVar = await stripe.confirmSetup({
              elements,
              confirmParams: {
                return_url: 'http://localhost:3000/pay',
            },
            redirect: "if_required"
            });
    
        }

        else{

          if(!savedCard.using){

            stripeResVar = await stripe.confirmPayment({
              
              elements,
              confirmParams: {
                return_url: 'http://localhost:3000/pay',
            },
            redirect: "if_required"
            });

          }
          else{

            stripeResVar = await stripe.confirmCardPayment(savedCard.secret);

          }
      }

      // console.log("IF ERROR", error)

      if (stripeResVar.hasOwnProperty("error")) {
    
        setErrorMessage(stripeResVar.error.message);
        stripeResponse(stripeResVar.error.message);
        console.log("ERROR")
  
      } else {
        stripeResponse("Success")
      }
    
  };


  useEffect( () => {

    if(submitClicked)
    {

      console.log("Attempting  setup")

      
      
      const {error} = stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/pay',
        },
        redirect: "if_required"
      });

      if (error) {
        
        console.log("Error stripe")
        stripeResponse(error.message);
      } else {
      
        stripeResponse("Success")
        
      }
      
    }

    }, [submitClicked]);


  return (
    <div className='paymentForm'>
      {!savedCard.using && <PaymentElement />}
      
      <div className='poButtons'>
          <PurpleOutlineButton variant='outlined' size='medium' onClick={cancelClicked}>CANCEL</PurpleOutlineButton>
          <PurpleButton variant="contained" size="medium" onClick={handleSubmit} disabled={!stripe}>COMPLETE PAYMENT</PurpleButton>
      </div>

      {/* {errorMessage && <div>{errorMessage}</div>} */}
    </div>
  )
};

export default PaymentComponent;