import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { render } from 'react-snapshot';
import { hydrate, render } from "react-dom";


import './fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf';
import './fonts/Be_Vietnam_Pro/BeVietnamPro-Bold.ttf';


import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyC49TrxlvYHYMmbx1AhhB3j-a0iUN9ebNU",
  authDomain: "travel-fitness-pass.firebaseapp.com",
  projectId: "travel-fitness-pass",
  storageBucket: "travel-fitness-pass.appspot.com",
  messagingSenderId: "359308616683",
  appId: "1:359308616683:web:74d2b499972358a1c22fae",
  measurementId: "G-NQQXZEF51V"
};

console.log("HERE£")


export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const remoteConfig = getRemoteConfig(app);


remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

console.log("Firebase init", app,auth)

export {auth}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
