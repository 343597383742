

import { FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { CheckBoxLabel1, PurpleCheckBox, TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';




export default function MultipleChoice({question,number,returnAnswer}) {

    const [answers,setAnswers] = useState(question.answer || [])
    const [stateIndex,setStateIndex] = useState(0)

    

    const checkBoxChanged = (event,value) => {
        let isChecked = event.target.checked 
        
        if(isChecked){

            answers.push(value)
            setAnswers(answers)
            
            returnAnswer(question.id,answers)

        }else{
            let removed = answers.filter(e => e !== value)
            setAnswers(removed)
            
            returnAnswer(question.id,removed)
        }

        setStateIndex(stateIndex+1)
        
    }

    const showOptions = () => 
    {
        let optionArray = []

        question.options.forEach(option => {
            optionArray.push(
                <div className='multipleChoiceAnswer'>
                    <PurpleCheckBox  onChange={event =>checkBoxChanged(event ,option)} checked={answers.includes(option)}/>
                    <PrimaryText variant='body1'>{option}</PrimaryText>
                </div>
            )
        });

        return optionArray
    }

    const checkAnswer = (option) => {
        // if(question.answer === undefined){return false}
        // else{
        //     console.log("CHECKING",question.answer,option)
        //     return question.answer.includes(option)
        // }
        

        return answers.includes(option)
    }

    return(

        <div className="questionComponent">
            
            <div className='questionTitles'>
                <PrimaryText variant='body1'>Question {number} : {question.question}</PrimaryText>
                <SecondaryText variant='body2'>Select the ones that apply</SecondaryText>
            </div>


            

            <div className='questionAnswers'>
                
                {showOptions()}

                
            </div>
            
        </div>
    )
}