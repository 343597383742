import React, { useEffect, useState } from 'react';
import { getAuth} from "firebase/auth";

import axios from "axios";

export default function UpdateLoginDetails() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [emailText,setEmailText] = useState("")
    const [passwordText,setPasswordText] = useState("")

    const submitClicked = () => {

        const auth = getAuth();
        const uid = auth.currentUser.uid;

        axios.post(baseURL+'updateLoginDetails',{
        
            uid:uid,

            email:emailText,
            password:passwordText

        
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
        })

    }

    return(
        <div>
            <h1>Update Login Details</h1>

            <div>
                <span>Email</span>
                <input type="text" placeholder="Enter email" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
            </div>

            <div>
                <span>Password</span>
                <input type="password" placeholder="Enter password" value={passwordText} onChange={(e) => setPasswordText(e.target.value)}></input>
            </div>

            <button onClick={submitClicked}>Update</button>
        </div>

        



    )


}