import {useNavigate,useLocation} from 'react-router-dom';
import { PurpleButton } from '../mui/Buttons';
import { POCard } from '../mui/Displays';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import React, { useEffect, useState } from 'react';
import BookingForms from './BookingForms';

import axios from "axios";
import CompletedBooking from './CompletedBooking';
const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";


export default function BookingConfirmation({booking,stepComplete}) {

    const [activated, setActivated] = useState(false);
    // const [showForms, setShowForms] = useState();
    const [loadingForms, setLoadingForms] = useState(false);


    const [questions,setQuestions] = useState([])
    const [forms,setForms] = useState([])

    const navigate = useNavigate();

    
    useEffect(() => {

        // if(booking.state === "Activated")
        // {
        //     setActivated(true)
        // }
        // else{
        //     if(booking.gym.activationRequirements.length>0)
        //     {
        //         getActivationRequirements(booking.gym.activationRequirements)
        //     }
        //     else{
        //         activateBooking()   
        //     }
        // }

        console.log("BOOKING BC",booking.userDetails.date)
        

    },[])


    const activateBooking = () => {

        booking.state = "Activated"
        setActivated(true)
        stepComplete(booking)
    }

    


    const activateClicked = () => {

        navigate("../activateBooking/"+booking.id, {   state:{booking:booking}});
    }



    return(
        <div className='bookingPassOverview'>
            
            <div className="passOverviewHeaders">
                <PrimaryText variant="h3">CONFIRMATION</PrimaryText>
                <SecondaryText variant="subtitle1">Your purchase was successful!</SecondaryText>
            </div>


            <div className='poSelected'>
                <PrimaryText variant="h4">Your gym pass</PrimaryText>
                <CompletedBooking booking={booking} activateClicked={activateClicked} />
                
            </div>

            <div className='statusExplanation'>
                <PrimaryText variant='subtitle1'>What does the status mean</PrimaryText>

                <div className='explanationSubColumn'>
                    <div className='activatedBubble green activatedNoBorder'>
                        <div className='activatedCircle green' />
                        <span>ACTIVATED</span>
                    </div>
                    <PrimaryText variant='caption'>This gym does not need any additional requirements and you can use the pass on your chosen date.</PrimaryText>
                </div>

                <div className='explanationSubColumn'>
                    <div className='activatedBubble activatedNoBorder'>
                        <div className='activatedCircle' />
                        <span>NOT ACTIVATED</span>
                    </div>
                    <PrimaryText variant='caption'>The gym still requires some additional information before your pass is activated. Without the activation your pass is not valid in the gym.</PrimaryText>
                </div>
            </div>

            {/* {(!activated && showForms) &&
                <div className='formsScreen'>
                    {(loadingForms) && <div>Loading</div>}
                    {(!loadingForms) && <BookingForms booking={booking} questionsP={questions} formsP={forms} confirmed={activateBooking}/>}
                </div>
                
                
            } */}


        </div>



    )
}