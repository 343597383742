

import { Card, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';




export default function GymTileHomeSkeleton() {

    // const [answer,setAnswer] = useState(question.answer || "")

    



    return(
        <div>

            <Skeleton variant="rounded" height={156} width={275}/>

            
            <div className="gymTileContentHome">

                <div className='gymTileTitle'>
                <Skeleton variant='text' height={28} width={103}/>

                    <div className='gymTileMapRow'>
                        <Skeleton variant='text' height={20} width={93}/>
                    </div>

                </div>


                <div className='gymTileOptionRow'>
                    
                    <div className='gymTilePriceRow'>
                        <Skeleton variant='text' height={38} width={150}/>
                    </div>
                </div>
            </div>
            
            
        </div>
        
    )
}