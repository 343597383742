import axios from "axios";
import Button from '@mui/material/Button';
import '../../styling/constants.css'
import { borderRadius, styled } from "@mui/system";
import { ArrowBack, Delete, Favorite, Settings } from "@mui/icons-material";
import { Card, TextField } from "@mui/material";
import { CardContent, CardHeader } from "semantic-ui-react";


export default function CollectEmail () {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";


    const logEmail = () => {

        axios.get(baseURL+'collectEmail',{
            
            params:{
                email: "caolangm@gmail.com"
            }

        
        }).then( (response) => {
        
            const data = response.data;
            
            console.log("Response", response,data)
            
            
        });
    }

    const buttonClicked = () => {
        console.log("CLICKED")
    }

    const CustomButton = styled(Button)({
        backgroundColor:'#766CE3',
        borderRadius:'20px',
        
    })

    
    const CustomCH = styled(CardHeader)({
        backgroundColor:'#766CE3',
        borderRadius:'20px',
        color:'black',
        titleColor:'red'
        
    })

    return(
        <div className="testRow3">
            {/* <button onClick={logEmail}>Collect Email</button> */}
            <button>Test</button>
            <CustomButton variant="contained"> Go back</CustomButton>
            {/* <Card sx={{width:3500}} variant="outlined">
                <CustomCH title="Test title">Test</CustomCH>
                <CardContent>Hello world</CardContent>
            </Card> */}
            <TextField sx={{marginTop:50}} variant="outlined"  label="Input" helperText="Help">Hello</TextField>
        </div>
    )

}