

import { Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DiscoverSortChip, DiscoverSortSelected } from '../mui/Displays';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';




export default function DiscoverSort({accommodation,onChange}) {

    const [selected,setSelected] = useState(0)

    const optionClicked = (option) => {
        setSelected(option)
        onChange(option)
    }

    // console.log("ACCA",accommodation)

    return(

        <div className="discoverSortRow">
            
            {selected !== 0 && <DiscoverSortChip label="Recommended" variant="outlined" onClick={()=>optionClicked(0)}/>}
            {selected === 0 && <DiscoverSortSelected label="Recommended" variant="outlined"/>}
            
            {selected !== 1 && <DiscoverSortChip label="Lowest price" variant="outlined" onClick={()=>optionClicked(1)}/>}
            {selected === 1 && <DiscoverSortSelected label="Lowest price" variant="outlined"/>}
            
            {/* {selected !== 2 && <DiscoverSortChip label="Best rated" variant="outlined" onClick={()=>optionClicked(2)}/>}
            {selected === 2 && <DiscoverSortSelected label="Best rated" variant="outlined"/>} */}
            
            {selected !== 3 && <DiscoverSortChip label="Closest to accommodation" variant="outlined" disabled={accommodation} onClick={()=>optionClicked(3)}/>}
            {selected === 3 && <DiscoverSortSelected label="Closest to accommodation" variant="outlined"/>}
        </div>
    )
}