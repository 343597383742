export const CITY_VALUES = {

    "barcelona":{
        name:"Barcelona",
        country:"Spain",
        img:"/assets/barcelona.png",
        lat:41.400763857275905, 
        lng:2.163887865489632
    },

    "dublin":{
        name:"Dublin",
        country:"Ireland",
        img:"/assets/dublinBkg.jpg",
        lat:53.34545449867754, 
        lng:-6.271994951397398
    },

    "paris":{
        name:"Paris",
        country:"France",
        img:"/assets/parisBkg.jpg",
        lat:48.85577554900844, 
        lng:2.3444306660388845
    },


}