import {useNavigate,useLocation,useParams} from 'react-router-dom';
import BookingHeaders from '../components/bookingScreen/BookingHeader';
import React, { useEffect, useState } from 'react';
import { getAuth,onAuthStateChanged} from "firebase/auth";
import axios from "axios";
import BookingPassOverview from '../components/bookingScreen/BookingPassOverview';
import BookingPaymentDetails from '../components/bookingScreen/BookingPaymentDetails';
import BookingConfirmation from '../components/bookingScreen/BookingConfirmation';
import BookingForms from '../components/bookingScreen/BookingForms';
import BackBar from '../components/BackBar';
import { PrimaryText } from '../components/mui/Typography';
import { PurpleButton, PurpleOutlineButton } from '../components/mui/Buttons';
import BookingReady from '../components/bookingScreen/BookingReady';
import BookingPassOverviewSkeleton from '../components/bookingScreen/Skeletons/BookingPassOverviewSkeleton';

export default function Booking({bookingP}) {

    const location = useLocation();
    const navigate = useNavigate();
    let { bookingID } = useParams();
    
    var gym = "gym"//location.state.gym
    var pass = "pass"//location.state.pass

    // const activatePass = () => {
    //     navigate("../pass", {  , state:{gym:gym,pass:pass}});
    // }

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    
   

    const [loading, setLoading] = useState(false)
    const [loadingPayment, setLoadingPayment] = useState(false)
    const [activatingPass, setActivatingPass] = useState(false)

    const [showCancel, setShowCancel] = useState(false)
    const [booking, setBooking] = useState(null)
    const [bookingState, setBookingState] = useState(null)

    useEffect(() => {
        const auth = getAuth();

                
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                
                if(location.state !== null)
                {
                    let booking = location.state.booking
                    setBooking(booking)
                    setBookingState(booking.state)
                    window.history.replaceState({}, document.title)
                }
                else{
                    getBookingFromDB(user.uid,bookingID)
                }
                


            } else {
            console.log("Auth change", false)
            
            }
        })
        return () => unsubscribe()

    },[])


    


    const getBookingFromDB = async(uid, bookingID) => {
        
        setLoading(true)

        axios.get(baseURL+'getBooking',{
        
            params:{
                uid:uid,
                bookingID:bookingID
            }

        }).then((response) => {
            setLoading(false)
            let resVar = response.data
            console.log("Booking Response", resVar)

            setBooking(resVar.booking)
            setBookingState(resVar.booking.state)
        })
    }

    


    const nextStepClicked = (bookingP) => {

        setBooking(bookingP)

        if(bookingState === "Incomplete"){setBookingState("Payment")}
        else if(bookingState === "Payment"){setBookingState("Complete")}
        else if(bookingState === "Complete"){setBookingState("Activated")}
    }

    const backToConfirmation = (stateP) => {
        setBookingState(stateP)
    }

    const paymentLoadingStatus = (loadingP) => {
        setLoadingPayment(loadingP)
    }

    const cancelClicked = () => {
        setShowCancel(true)
    }
    const cancelAborted = () => {
        setShowCancel(false)
    }
    const cancelConfirmed = () => {

    }
    
    const backClicked = () => {
        // navigate(-1)  
    }

    const activatePassFunc = (val) => {
        setActivatingPass(val)
    }


    return(
        <div className='bookingScreen'>
        <BackBar clicked={backClicked} />
        {loadingPayment &&<div className='bookingLoadingScreen'>
            <div className='paymentLoadingPopUp'>
                <div className='paymentLoadingTitles'>
                    <PrimaryText variant='h4'>One moment please</PrimaryText>
                    <PrimaryText variant='body2'>We are loading our payment service with Stripe.</PrimaryText>
                </div>
                <PrimaryText variant='subtitle1'>5...4...3...2...1...</PrimaryText>
            </div>
        </div>}

        {activatingPass &&<div className='bookingLoadingScreen'>
            <div className='paymentLoadingPopUp'>
                <div className='paymentLoadingTitles'>
                    <PrimaryText variant='h4'>One moment please</PrimaryText>
                    <PrimaryText variant='body2'>We are activating your pass.</PrimaryText>
                </div>
                <PrimaryText variant='subtitle1'>5...4...3...2...1...</PrimaryText>
            </div>
        </div>}

        {showCancel && <div className='bookingLoadingScreen'>
            <div className='cancelBookingPopUp'>
                <div className='paymentLoadingTitle'>
                    <PrimaryText variant='h4'>Are you sure you want to cancel?</PrimaryText>
                    <PrimaryText variant='body2'>You will lose your progress if you decide to cancel and will have to start again.</PrimaryText>
                </div>
                <div className='poButtons'>
                    <PurpleOutlineButton variant='outlined' size='medium' onClick={cancelConfirmed}>YES, CANCEL</PurpleOutlineButton>
                    <PurpleButton variant="contained" size="medium" onClick={cancelAborted}>NO, CONTINUE</PurpleButton>
                </div>
            </div>
        </div>}

        <div className='fullScreen'>
            <div className='container'>
                
                
                {!loading &&
                    <div>
                        {(bookingState !== "Activated") && <BookingHeaders bookingState={bookingState}/>}
                        {/* <h1>Booking</h1> */}

                        {(bookingState === "Incomplete") && <BookingPassOverview booking={booking} stepComplete={nextStepClicked} cancelClicked={cancelClicked}/>}
                        {(bookingState === "Payment") && <BookingPaymentDetails booking={booking} stepComplete={nextStepClicked} loadingStatus={paymentLoadingStatus} cancelClicked={cancelClicked} activatingPass={activatePassFunc}/> }
                        {(bookingState === "Complete") && <BookingConfirmation booking={booking} stepComplete={nextStepClicked}/>}
                        {(bookingState === "Activated") && <BookingReady booking={booking} />}


                    </div>
                }
                {loading && <div>

                    <BookingPassOverviewSkeleton/>
                    
                </div>}
                
            </div>
        </div>
        </div>
    )
}