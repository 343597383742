import {useNavigate,useLocation} from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import '../../../styling/booking.css'
import dayjs, { Dayjs } from 'dayjs';

import { Autocomplete, Card, Skeleton, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { POCard } from '../../mui/Displays';


export default function BookingPassOverviewSkeleton() {



    return(
        <div className='bookingPassOverview'>
            
            <div className="passOverviewHeaders">
                <Skeleton variant='text' height={40} width={248}/>
                <Skeleton variant='text' height={28} width={363}/>
            </div>

            <div className='poSelected'>
                <Skeleton variant='text' height={27} width={185}/>

                <div className='poSelectedPassRow'>

                    <POCard sx={{ width:836}}>
                        <div className='poSelectedGym'>
                            <Skeleton variant='rounded' height={171} width={250}/>
                            <div className='poGymColumn'>
                                <div className='poGymSubColumn'>
                                    <Skeleton variant='text' height={27} width={103}/>
                                    <Skeleton variant='text' height={20} width={340}/>
                                </div>
                                <div className='poGymSubColumn'>
                                    <Skeleton variant='text' height={20} width={55}/>
                                    <Skeleton variant='text' height={48} width={438}/>
                                </div>
                            </div>
                        </div>                    
                    </POCard>

                    <POCard sx={{ width:200}}>
                        <div className='poSelectedPass'>
                            <Skeleton variant='text' height={24} width={97}/>
                            <div className='poPassSubColumn'>
                                <Skeleton variant='text' height={56} width={116}/>
                                <Skeleton variant='text' height={20} width={70}/>
                            </div>
                        </div>                    
                    </POCard>
                </div>
            </div>

            <div className='poInputsSection'>

                <div className='poDateSection'>
                    <div className='poDateTitles'>
                        <Skeleton variant='text' height={27} width={134}/>
                        <Skeleton variant='text' height={50} width={469}/>
                    </div>

                    <POCard sx={{ width:'100%', height:'363px'}} />
                        
                        
                        

                </div>

                <div className='poDetailsSection'>
                     <div className='poDateTitles'>
                        <Skeleton variant='text' height={27} width={134}/>
                        <Skeleton variant='text' height={27} width={134}/>

                        <div className='poDetailsInputs'>
                            <Skeleton variant='rounded' height={56} width={446}/>
                            <Skeleton variant='rounded' height={56} width={446}/>
                            <Skeleton variant='rounded' height={56} width={446}/>
                            <Skeleton variant='rounded' height={56} width={446}/>   
                        </div>
                    </div>
                </div>

            </div>
            
            <div className='poButtons'>
                <Skeleton variant='rounded' height={36} width={96}/>
                <Skeleton variant='rounded' height={36} width={216}/>
            </div>

            
        </div>
    )
}