import React, { useEffect, useState } from 'react';
import { getAuth} from "firebase/auth";
import {useNavigate,useLocation} from 'react-router-dom';
import axios from "axios";

export default function EditAccountDetails({user}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const [nameText,setNameText] = useState(user.personalDetails.firstName)
    const [surnameText,setSurnameText] = useState(user.personalDetails.surname)

    const [emailText,setEmailText] = useState(user.email)
    const [genderText,setGenderText] = useState(user.personalDetails.gender)

    const [dobText,setDOBText] = useState(user.personalDetails.dob)
    const [countryText,setCountryText] = useState(user.personalDetails.country)

    const submitClicked = () => {

        const auth = getAuth();
        const user = auth.currentUser;
        console.log("SUER",user)
        const uid = user.uid
        
        updateAccountDB(uid)

    }


    const updateAccountDB = (uid) => {
        
        axios.post(baseURL+'updateUserAccount',{
        
            uid:uid,

            firstName:nameText,
            surname:surnameText,
            email:emailText,
            gender:genderText,
            dob:dobText,
            country:countryText,

        
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
        })
    }



    return(
        <div>
            <h1>Edit Details</h1>
            
            <div>
                <span>First Name</span>
                <input type="text" placeholder="Enter first name" value={nameText} onChange={(e) => setNameText(e.target.value)}></input>
            </div>

            <div>
                <span>Surname</span>
                <input type="text" placeholder="Enter surname" value={surnameText} onChange={(e) => setSurnameText(e.target.value)}></input>
            </div>


            <div>
                <span>Email</span>
                <input type="text" placeholder="Enter email" value={emailText} onChange={(e) => setEmailText(e.target.value)}></input>
            </div>

            <div>
                <span>Gender</span>
                <input type="text" placeholder="Enter email or user name" value={genderText} onChange={(e) => setGenderText(e.target.value)}></input>
            </div>


            <div>
                <span>Date of Birth</span>
                <input type="text" placeholder="DD/MM/YYY" value={dobText} onChange={(e) => setDOBText(e.target.value)}></input>
            </div>

            <div>
                <span>Country</span>
                <input type="text" placeholder="Country" value={countryText} onChange={(e) => setCountryText(e.target.value)}></input>
            </div>

            <button onClick={submitClicked}>Update</button>

        </div>
    )
}