import './App.css';
import {BrowserRouter as Router, Route,Routes,Navigate, Link} from 'react-router-dom'
import { getAuth,onAuthStateChanged} from "firebase/auth";
import React, { useEffect, useState } from 'react';


import ReactDefault from './screens/test/reactDefault';
import AuthTest from './screens/test/authTest';
import ProtectedTest from './screens/test/protectedTest';
import CollectEmail from './screens/test/collectEmail';
import CommingSoon from './screens/comingSoon';
import BusinessForm from './screens/businessForm';
import MapTest from './screens/test/mapTest';
import PaymentTest from './screens/test/paymentTest';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PrivacyPolicy from './screens/privacypolicy';
import TestPhone from './screens/test/phoneVerificationTest';
import Header from './components/Header';
import HomeScreen from './screens/home';
import Login from './screens/login';
import Discover from './screens/discover';
import GymProfile from './screens/gymProfile';
import Pass from './screens/pass';
import Booking from './screens/booking';
import Account from './screens/account';
import Register from './screens/register';
import VerifyPhoneNumber from './screens/verifyPhoneNumber';
import Footer from './components/Footer';
import Profile from './screens/profile';
import UpdateProfileDetails from './screens/profileScreens/UpdateProfileDetails';
import UpdateLoginDetails from './screens/profileScreens/UpdateLoginDetails';
import DeleteAccount from './screens/profileScreens/DeleteAccount';
import MyPasses from './screens/profileScreens/MyPasses';
import axios from "axios";
import BookingActivate from './components/bookingScreen/BookingActivate';
import BookingReady from './components/bookingScreen/BookingReady';
import PortalEdit from './screens/test/portalEdit';



function App() {

  const stripePromise = loadStripe('pk_test_51L80HSF8EmGgjBYkIROg4pkgsJEs5LgznxCE0Iie3eEUMpvP1Yt9lz4t66DpLAKM4ByfxWELD8v0GfnzK32T6zI100FbrfjVdt');

  const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

  const auth = getAuth();
  const [loggedIn, setLoggedIn] = useState(false)
  const [userData, setUserData] = useState(null)

  //Auth changed listener, this updates loggedIn boolean as the
  // authentication state changes
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Auth change", true,user,loggedIn)
  
        setLoggedIn(true)
        loadUserFromDB(user.uid)
        loadUsersPassesFromDB(user.uid)
      
      } else {
        
        setLoggedIn(false)
        
      }
    })
    return () => unsubscribe()

  },[])

  
  const loadUserFromDB = (uid) => {

    return new Promise(async (resolve, reject) => {

    axios.get(baseURL+'getAccountDetails',{
        params:{
            uid:uid
        }
    }).then((response) => {
        let userAccountData = response.data
        console.log("USER ACCOUNT", userAccountData)
        localStorage.setItem("user",JSON.stringify(userAccountData))
        setUserData(userAccountData)
        resolve()
    })

  })

  }

    
  const loadUsersPassesFromDB = (uid) => {

    return new Promise(async (resolve, reject) => {

    axios.get(baseURL+'getUsersBookings',{
        params:{
            uid:uid
        }
    }).then((response) => {
        let bookings = response.data.bookings
        console.log("USER PASSES", bookings)
        localStorage.setItem("passes",JSON.stringify(bookings))
        resolve()
    })

  })

  }
  
  const bookingTest = { state:"Incomplete"}


  return (
    <div className="App">
{/*       
      <div className="header">
                
            <img className="logoIV" src="assets/logo3.png"></img>
      </div> */}

      <Router>

        <Header loggedIn={loggedIn} user={userData}/>
        
        <Routes>
          <Route path='/'  element={<HomeScreen />} />
          <Route path='/gym'  element={<BusinessForm />} />

          <Route path='/login'  element={loggedIn?<Navigate to="/"/>:<Login />} />  
          <Route path='/register'  element={<Register />} />  
          <Route path='/verifyPhone'  element={<VerifyPhoneNumber />} />  
          <Route path='/home'  element={<HomeScreen />} />  
          <Route path='/discover'  element={<Discover />} />
          <Route path='/city/:cityID'  element={<Discover isCity={true}/>} />
          <Route path='/gymprofile/:gymID'  element={<GymProfile />} />  
          <Route path='/booking/:bookingID'  element={<Booking />} />
          <Route path='/activateBooking/:bookingID'  element={<BookingActivate />} />
          <Route path='/activatedBooking/:bookingID'  element={<BookingReady />} />
          <Route path='/profile'  element={<Profile />} />

          <Route path='/updateProfile'  element={<UpdateProfileDetails />} />
          <Route path='/updateLogin'  element={<UpdateLoginDetails />} />
          <Route path='/deleteAccount'  element={<DeleteAccount />} />
          <Route path='/myPasses'  element={<MyPasses />} />
          <Route path='/portal'  element={<PortalEdit />} />

          <Route path='/pass'  element={<Pass />} />
          <Route path='/account'  element={<Account li={loggedIn}/>} />

          <Route path='/react'  element={<ReactDefault />} />
          <Route path='/auth'  element={<AuthTest />} />  
          <Route path='/map'  element={<MapTest />} />
          <Route path='/comingSoon'  element={<CommingSoon />} />
          <Route path='/email'  element={<CollectEmail />} />
          <Route path='/privacy-policy'  element={<PrivacyPolicy />} />
          <Route path='/pay'  element={<PaymentTest refresh={false}/>} />
          <Route path='/protected'  element={loggedIn?<ProtectedTest />:<ProtectedTest/>} />
          <Route path='/phone'  element={<TestPhone />} />
          <Route path='/image'  element={<img src='/assets/gymTemp.png' />} />
        </Routes>

        <Footer />

        {/* <Link to="/image2">
        <img src={'/assets/gymTemp.png'} alt='Logo' />
      </Link> */}

      </Router>

      

    </div>
  );
}

export default App;
