import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { getAuth, signOut  } from "firebase/auth";
import axios from "axios";



export default function DeleteAccount() {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const navigate = useNavigate();
    const auth = getAuth();
    const uid = auth.currentUser.uid;

    const deleteUserFromDB = () => {
        
        axios.get(baseURL+'deleteAccount',{
        
            params:{
                uid:uid,
            }

        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)
        })
    }


    const deleteClicked = () => {
  
        signOut(auth).then(() => {
            deleteUserFromDB()
            navigate("../", {  });
          }).catch((error) => {
            console.log("Sign out error",error)
          });

        
                
    }

    return(
        <div>
            <h1>Delete Account</h1>
            <button onClick={deleteClicked}>Delete</button>
        </div>
    )


}