import {useNavigate,useLocation} from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import '../../styling/booking.css'
import dayjs, { Dayjs } from 'dayjs';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import { Autocomplete, Card, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { POCard } from '../mui/Displays';
import { TextInput } from '../mui/Inputs';
import { PurpleButton, PurpleOutlineButton } from '../mui/Buttons';

export default function BookingPassOverview({booking,stepComplete,cancelClicked}) {

    const [nameText,setNameText] = useState("")
    const [surnameText,setSurnameText] = useState("")
    const [emailText,setEmailText] = useState("")
    const [dateText,setDateText] = useState(new Date());
    const [genderText,setGenderText] = useState("")

    const genderOptions = ['Male','Female','Other']

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";

    const submitClicked = () => {
        if(nameText === ""){console.log("Enter Name")}
        else if(emailText === ""){console.log("Enter Email")}
        else if(dateText === ""){console.log("Enter Date")}
        else if(genderText === ""){console.log("Enter Gender")}
        else{
            addDetailsToDB()
        }
    }


    const addDetailsToDB = () => {

        axios.post(baseURL+'addBookingUserDetails',{
        
            bookingID:booking.id,

            name:nameText+" "+surnameText,
            email:emailText,
            gender:genderText,
            startDate:dateText

        
        }).then((response) => {
            let resVar = response.data
            console.log("Response", resVar)

            booking.userDetails = {
                name:nameText+" "+surnameText,
                email:emailText,
                gender:genderText,
                date:new Date(dateText)
            }

            stepComplete(booking)
        })
    }
    
    const isBlockedOut = (date) => {
        let dateCell = new Date(date)
        let now = new Date()
      
        return dateCell.getTime() < (now.getTime()- 86400000)
      };

    const areFieldsNotFilled = () => {

        if(nameText === ""){return true}
        else if(emailText === ""){return true}
        else if(surnameText === ""){return true}
        else if(genderText === ""){return true}
        else{
            return false
        }
    }

    const getAmenetiesString = () => {
        if(booking.gym.amenities.length === 0)
        {
            return ""
        }
        else{
            return booking.gym.amenities.join(" • ")
        }
    }

    return(
        <div className='bookingPassOverview'>
            
            <div className="passOverviewHeaders">
                <PrimaryText variant="h3">PASS OVERVIEW</PrimaryText>
                <SecondaryText variant="subtitle1">Here is a summary of your selected pass</SecondaryText>
            </div>

            <div className='poSelected'>
                <PrimaryText variant="h4">Your selected pass</PrimaryText>

                <div className='poSelectedPassRow'>

                    <POCard  className='poCard'>
                        <div className='poSelectedGym'>
                            <img className='poGymImg' src={booking.gym.images[0]}/>
                            <div className='poGymColumn'>
                                <div className='poGymSubColumn'>
                                    <PrimaryText variant='h4'>{booking.gym.name}</PrimaryText>
                                    <SecondaryText variant='body2'>{booking.gym.address}</SecondaryText>
                                </div>
                                <div className='poGymSubColumn'>
                                    <PrimaryText variant='caption'>Amenities</PrimaryText>
                                    <PrimaryText variant='body2'>{getAmenetiesString()}</PrimaryText>
                                </div>
                            </div>
                        </div>                    
                    </POCard>

                    <POCard className='poSubCard'>
                        <div className='poSelectedPass'>
                            <PrimaryText variant='body1'>1 x {booking.bookingOptionDetails.name}</PrimaryText>
                            <div className='poPassSubColumn'>
                                <PrimaryText variant='h2'>€{(booking.bookingOptionDetails.price/100).toFixed(2)}</PrimaryText>
                                <SecondaryText variant='body2'>Total cost</SecondaryText>
                            </div>
                        </div>                    
                    </POCard>
                </div>
            </div>

            <div className='poInputsSection'>

                <div className='poDateSection'>
                    <div className='poDateTitles'>
                        <PrimaryText variant='h4'>Select a date</PrimaryText>
                        <PrimaryText variant='body2'>Pick the date for which you want the pass to be valid. <br/>You cannot change the date after you have completed the booking</PrimaryText>
                    </div>

                    <POCard sx={{ width:'100%'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDatePicker
                                toolbarFormat="YYYY dddd, MMM DD"
                                orientation="landscape"
                                openTo="day"
                                value={dateText}
                                shouldDisableDate={isBlockedOut}
                                onChange={(newValue) => {
                                setDateText(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                componentsProps={{
                                    actionBar: {
                                      actions: [''],
                                    },
                                  }}
                            />
                            </LocalizationProvider>
                        </POCard>
                        

                </div>

                <div className='poDetailsSection'>
                     <div className='poDateTitles'>
                        <PrimaryText variant='h4'>Confirm your details</PrimaryText>
                        <PrimaryText variant='body2'>By confirming your details you agree to the terms that there is no cancellation possibile after purchase.</PrimaryText>

                        <div className='poDetailsInputs'>
                            <TextInput variant="outlined" label="Name*"
                            value={nameText} onChange={(e) => setNameText(e.target.value)}/>

                            <TextInput variant="outlined" label="Surname*"
                            value={surnameText} onChange={(e) => setSurnameText(e.target.value)}/>

                            <TextInput type="email" variant="outlined" label="Email*"
                            value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                            
                            <Autocomplete disablePortal options={genderOptions} renderInput={(params)=><TextInput {...params} variant="outlined" label="Gender" value={genderText} />} onChange={(e) => setGenderText(e.target.textContent)}/>

                        </div>
                    </div>
                </div>

            </div>
            
            <div className='poButtons'>
            <PurpleOutlineButton variant='outlined' size='medium' onClick={cancelClicked}>CANCEL</PurpleOutlineButton>
            <PurpleButton variant="contained" size="medium" onClick={submitClicked} disabled={areFieldsNotFilled()}>CONTINUE TO PAYMENT</PurpleButton>
            </div>

            
        </div>
    )
}