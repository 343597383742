import {useNavigate,useLocation,useParams} from 'react-router-dom';
import { PurpleButton } from '../mui/Buttons';
import { POCard } from '../mui/Displays';
import { PrimaryText, SecondaryText } from '../mui/Typography';
import React, { useEffect, useState } from 'react';
import BookingForms from './BookingForms';

import axios from "axios";
import CompletedBooking from './CompletedBooking';
import BackBar from '../BackBar';
const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";


export default function BookingReady() {

    const [activated, setActivated] = useState(false);
    // const [showForms, setShowForms] = useState(trur);
    const [loadingForms, setLoadingForms] = useState(false);


    const [booking, setBooking] = useState(null);

    const [questions,setQuestions] = useState([])
    const [forms,setForms] = useState([])

    const location = useLocation();
    const navigate = useNavigate();
    let { bookingID } = useParams();
    

   

    const getBookingFromDB = async(uid, bookingID) => {
        
        // setLoading(true)

        axios.get(baseURL+'getBooking',{
        
            params:{
                uid:uid,
                bookingID:bookingID
            }

        }).then((response) => {
            // setLoading(false)
            let resVar = response.data
            console.log("Booking Response", resVar)

            if(resVar.booking.state === "Activated")
            {
                setBooking(resVar.booking)
            }
            else{
                console.log("Error State", resVar.booking.state)
            }
            
          
        })
    }



    useEffect(() => {

        if(booking === null)
        {
            if(location.state !== null)
            {
                setBooking(location.state.booking)
                
               
            }
            else{
                let user = JSON.parse(localStorage.getItem("user"))
                getBookingFromDB(user.uid,bookingID)
            }
            
        }
        
       
        
        

    },[])





    return(
        <div className='activateBookingScreen'>
            <BackBar />
            <div className='fullScreen'>
                <div className='container'>

                    {booking !== null &&<div className='bookingPassOverview'>
                        
                        <div className="passOverviewHeaders">
                            <PrimaryText variant="h3">YAY! YOUR PASS IS READY</PrimaryText>
                            <SecondaryText variant="subtitle1">You successfully activated your pass and can use it on your selected date</SecondaryText>
                        </div>


                        <div className='poSelected'>
                            <PrimaryText variant="h4">Your gym pass</PrimaryText>
                            <CompletedBooking booking={booking} activated={true} readyScreen={true}/>
                            
                        </div>

                            
                        


                    </div>}
                    {booking === null && <span>Loading</span>}

                </div>
            </div>

                
        </div>



    )
}