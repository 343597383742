

import { Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TextInput } from '../mui/Inputs';
import { PrimaryText, SecondaryText } from '../mui/Typography';




export default function DropdownQuestion({question,number,returnAnswer}) {

    const [answer,setAnswer] = useState(question.answer || "")

    

    const optionChosen = (value) => {
        setAnswer(value)
        returnAnswer(question.id,value)
    }


    return(

        <div className="questionComponent">
            
            <div className='questionTitles'>
                <PrimaryText variant='body1'>Question {number} : {question.question}</PrimaryText>
                <SecondaryText variant='body2'>Select the one that applies</SecondaryText>
            </div>


            <Autocomplete disablePortal options={question.options} renderInput={(params)=><TextInput {...params} variant="outlined" label="Choose option" value={answer} />} onChange={(e) => optionChosen(e.target.textContent)}/>
            

            {/* <div className='questionAnswers'>

            </div> */}
            
        </div>
    )
}