

import { Card, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';




export default function GymTileSkeleton() {

    // const [answer,setAnswer] = useState(question.answer || "")

    



    return(
        <Card className='gymTile' sx={{width:328, height:398}}>

            <Skeleton variant="rounded" height={200} width={328}/>

            
            <div className="gymTileContent">

                <div className='gymTileTitle'>
                <Skeleton variant='text' height={28} width={103}/>

                    <div className='gymTileMapRow'>
                        <Skeleton variant='text' height={20} width={93}/>
                    </div>

                </div>

                <Skeleton variant='text' height={20} width={276}/>
                <Skeleton variant='text' height={20} width={226}/>

                <div className='gymTileOptionRow'>
                    
                    <div className='gymTilePriceRow'>
                        <Skeleton variant='text' height={38} width={150}/>
                    </div>
                </div>
            </div>
            
            
        </Card>
        
    )
}