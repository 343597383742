
import {loadStripe} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import PaymentComponent from '../../components/PaymentComponent';
import axios from "axios";
import {Elements} from '@stripe/react-stripe-js';
import '../../styling/constants.css'

export default function PaymentTest({refresh}) {

    const baseURL = "https://us-central1-travel-fitness-pass.cloudfunctions.net/";
    
    const stripePromise = loadStripe('pk_test_51L80HSF8EmGgjBYkIROg4pkgsJEs5LgznxCE0Iie3eEUMpvP1Yt9lz4t66DpLAKM4ByfxWELD8v0GfnzK32T6zI100FbrfjVdt');
    const [options, setOptions] = useState(null)
    const [setupIntentID, setIntentID] = useState("")
    const [loadingText,setLoadingText] = useState(null)
    const [checkingPayment,setCheckingPayment] = useState(false)
    const [nameText,setNameText] = useState('')


    const [useSavedCard,setUseSaveCard] = useState(false)

    const checkboxClicked = () =>{
        setUseSaveCard(!useSavedCard)
    }

    const appearance = {
        labels: 'floating',
        variables:{
            colorPrimary: '#E4D0A6',
            colorBackground: '#242424',
            colorTextPlaceholder: '#A0A0A0',
            colorText: '#fff',
            spacingUnit: '0px',
            spacingGridRow: '5px',
            spacingGridColumn: '5px',
            borderRadius: '25px',
            fontLineHeight:'15px',
            fontSizeBase:'16px'
        }
        
      };
    // const [refresh,setRefresh] = useState(true)

    useEffect(() => {
        console.log("USE EFFECT")
        createPaymentIntent()        
    }, []);    

    const createSetupIntent = () => {


        axios.get(baseURL+'createSetUpIntent',{
            params:{
                customer: "cus_LuvruuoXcQhrkB"
            }
        }).then( (response) => {
        
          const data = response.data;
          
          let setUpID = data.setupIntent.client_secret
          console.log("Setup ID",setUpID)

          setOptions({clientSecret:setUpID,appearance:appearance})
            
          setIntentID("")
          setIntentID(setUpID)
        
        });

    }


    
    const createPaymentIntent = () => {


        axios.get(baseURL+'createPaymentIntent',{
            params:{
                customer: "cus_LuvruuoXcQhrkB",
                amount: 700
            }
        }).then( (response) => {
        
          const data = response.data;
          
          let setUpID = data.paymentIntent.client_secret
          console.log("Setup ID",setUpID)

          setOptions({clientSecret:setUpID,appearance:appearance})
            
          setIntentID("")
          setIntentID(setUpID)
        
        });

    }


    
    const stripeResponse = (res)=>
    {
        if(res !== "Success"){setCheckingPayment(false)
                setLoadingText("Checking card")
        }
        else{
            onSuccessfulPayment()
        }
    }


    const onSuccessfulPayment = async () => {

        console.log("SUCCESSFUL")
        
    }



    return(
        <div className="paymentScreen flexColumn">
        
            
            <input type="text" placeholder="Name on card" value={nameText} onChange={(e) => setNameText(e.target.value)}/>
            {(setupIntentID) && 
                <Elements stripe={stripePromise} options={options} >
                    <PaymentComponent stripeResponse={stripeResponse} submitClicked={checkingPayment} isSetup={false} savedCard ={{using:useSavedCard,secret:options.clientSecret}}/>
                </Elements>
                }
            
            <div className='flexRow marginTop50'>
                <input type="checkbox" className='checkbox' onChange={checkboxClicked} value={useSavedCard}/>
                <span>Use savedCard</span>
            </div>

        </div>
    )

}